import cn from 'classnames'

import {SVGProps} from './props'

export const ArrowLeft = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-arrow-left', className)}
        fill={fill}
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path d="M10.403 13.597L6.506 9.7H16V6.3H6.506l3.897-3.897L8 0 0 8l8 8z" fillRule="evenodd" />
    </svg>
)
