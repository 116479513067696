import {ComponentsOverrides, Theme} from '@mui/material/styles'

export const MuiOutlinedInput = {
    styleOverrides: {
        root: ({theme}) => ({
            color: theme.palette.black,
            borderCollapse: 'collapse',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: 1,
            },
            '&.Mui-disabled': {
                color: theme.palette.grey60,
            },
            'fieldset legend': {
                fontSize: '10px',
            },
            '&.MuiInputBase-adornedStart .MuiOutlinedInput-input': {
                paddingLeft: 0,
            },
            '&.MuiInputBase-adornedEnd .MuiOutlinedInput-input': {
                paddingRight: 0,
            },
        }),
        input: ({theme}) => ({
            height: 'auto',
            fontSize: theme.typography.body1.fontSize,
            lineHeight: theme.typography.body1.lineHeight,
            padding: theme.spacing(1.25, 1.5),
        }),
        inputSizeSmall: ({theme}) => ({
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            padding: theme.spacing(0.875, 1.5),
        }),
        notchedOutline: {
            borderWidth: 1,
        },
        multiline: {
            padding: 0,
        },
    } as ComponentsOverrides<Theme>['MuiOutlinedInput'],
}

export default MuiOutlinedInput
