import {graphql} from 'react-relay/hooks'

export const getOrgs = graphql`
    query SwitchOrgDialogQuery {
        meAdmin {
            orgSwitchTargets {
                name
                pk
                ...OrgAvatar_org
            }
        }
    }
`
