import {styled} from '@waybridge/wui'
import Checkbox, {CheckboxProps} from '@waybridge/wui/Checkbox'
import MuiTableCell, {TableCellProps} from '@waybridge/wui/TableCell'

export const TableCell = styled(MuiTableCell, {
    shouldForwardProp: (prop) => prop !== 'mw',
})<{mw?: number}>(({theme, mw}) => ({
    borderBottom: 'none',
    padding: theme.spacing(1.5),
    borderRight: `1px solid ${theme.palette.grey30}`,

    '&:first-of-type': {
        paddingLeft: theme.spacing(3.5),
    },

    '&:last-of-type': {
        paddingRight: theme.spacing(3.5),
    },

    ...(mw ? {maxWidth: `${mw}px`} : {}),
}))

/**
 * When rendering a cell at the start or end of a row where we might not want horizontal
 * padding, we can use this component to override the default padding. For example when the Cell
 * only contains an Icon.
 */
export const PlainCell = styled(TableCell)(({theme}) => ({
    '&:first-of-type, &:last-of-type': {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
}))

export const SelectableCell = ({
    isSelected = false,
    indeterminate,
    onChange,
    disabled = false,
    ...props
}: {isSelected?: boolean; indeterminate?: boolean; onChange: CheckboxProps['onChange']; disabled?: boolean} & Omit<
    TableCellProps,
    'onChange'
>) => (
    <PlainCell {...props} style={{...props.style, width: 0}}>
        <Checkbox checked={isSelected} disabled={disabled} indeterminate={indeterminate} onChange={onChange} />
    </PlainCell>
)

export default TableCell
