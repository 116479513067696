/* log messages for developers */

export function error(...msgs: any[]) {
    if (process.env.NODE_ENV === 'development') {
        console.error(...msgs)
    }
}

export function warn(...msgs: any[]) {
    if (process.env.NODE_ENV === 'development') {
        console.warn(...msgs)
    }
}
