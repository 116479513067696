import {styled} from '@waybridge/wui'
import Divider from '@waybridge/wui/Divider'
import Stack from '@waybridge/wui/Stack'

import FooterLinks from '@/components/FooterLinks'

const Wrapper = styled('div')(({theme}) => ({
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    borderLeft: `1px solid ${theme.palette.grey40}`,
    borderRight: `1px solid ${theme.palette.grey40}`,
    padding: `${theme.spacing(6)} ${theme.spacing(12)}`,
}))

type FullPageProps = {
    header?: React.ReactNode
    children: React.ReactNode
}

const FullPage = ({children, header}: FullPageProps) => (
    <Wrapper>
        <div style={{flexShrink: '0', display: 'flex', justifyContent: 'center'}}>{header}</div>
        <div
            style={{
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                margin: '48px 0',
            }}>
            <div>
                <Divider color="grey40" style={{position: 'absolute', left: '0', right: '0'}} />
                <Stack alignItems="center" direction="column" sx={{py: 8}}>
                    {children}
                </Stack>
                <Divider color="grey40" style={{position: 'absolute', left: '0', right: '0'}} />
            </div>
        </div>
        <div style={{flexShrink: '0', display: 'flex', justifyContent: 'center'}}>
            <FooterLinks />
        </div>
    </Wrapper>
)

export default FullPage
