import {Outlet} from 'react-router'

import {PageLayout} from '@waybridge/wui/Layout'
import Suspense from '@waybridge/wui/Suspense'

export type BaseProps = {
    children?: React.ReactNode
}
// eslint-disable-next-line react/function-component-definition -- FIXME
export default function Base() {
    return (
        <PageLayout overflow>
            <Suspense>
                <Outlet />
            </Suspense>
        </PageLayout>
    )
}
