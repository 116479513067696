import {Components, Theme} from '@mui/material/styles'

export const MuiRadio: Components<Theme>['MuiRadio'] = {
    defaultProps: {
        color: 'secondary',
    },
    styleOverrides: {
        root: ({theme}) => ({
            color: theme.palette.grey70,
            '&.Mui-disabled': {
                color: theme.palette.grey40,
            },
            '& .MuiSvgIcon-fontSizeMedium': {
                fontSize: '1.25rem',
            },
            '& .MuiSvgIcon-fontSizeSmall': {
                fontSize: '1rem',
            },
        }),
    },
}
export default MuiRadio
