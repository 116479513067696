import {ComponentsOverrides} from '@mui/material/styles'

export const MuiDialog = {
    styleOverrides: {
        root: {
            borderRadius: 0,
        },
        paper: {
            borderRadius: 0,
        },
    } as ComponentsOverrides['MuiDialog'],
}

export default MuiDialog
