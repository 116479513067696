import {ColumnConstraints, ColumnState} from '@waybridge/wui/CustomizeTableColumnsDialog/types'

export enum Field {
    settings = 'settings',
    counterparty = 'counterparty',
    tradePk = 'tradePk',
    documentId = 'documentId',
    documentType = 'documentType',
    currency = 'currency',
    balance = 'balance',
    invoiceValue = 'invoiceValue',
    quotaMonth = 'quotaMonth',
    issueDate = 'issueDate',
    dueDate = 'dueDate',
    po = 'po',
    shipmentRef = 'shipmentRef',
}

export const sortingKeys: Partial<Record<Field, string[]>> = {
    [Field.counterparty]: ['BUYER_NAME', 'SELLER_NAME'],
    [Field.tradePk]: ['TRADE_PK'],
    [Field.documentId]: ['DOCUMENT_ID'],
    [Field.documentType]: ['DOC_TYPE'],
    [Field.currency]: ['CURRENCY_CODE'],
    [Field.balance]: ['BALANCE'],
    [Field.invoiceValue]: ['INVOICE_VALUE'],
    [Field.quotaMonth]: ['PERIOD_MONTH'],
    [Field.issueDate]: ['ISSUE_DATE'],
    [Field.dueDate]: ['DUE_DATE'],
    [Field.po]: ['PO_NUMBERS'],
    [Field.shipmentRef]: ['SHIPMENT_REF'],
}

export const defaultColumnOrder: ColumnState[] = [
    {field: Field.settings, enabled: true},
    {field: Field.counterparty, enabled: true},
    {field: Field.tradePk, enabled: true},
    {field: Field.documentId, enabled: true},
    {field: Field.documentType, enabled: true},
    {field: Field.currency, enabled: true},
    {field: Field.balance, enabled: true},
    {field: Field.invoiceValue, enabled: true},
    {field: Field.quotaMonth, enabled: true},
    {field: Field.issueDate, enabled: true},
    {field: Field.dueDate, enabled: true},
    {field: Field.po, enabled: true},
    {field: Field.shipmentRef, enabled: true},
]

export const columnContraints: Partial<Record<Field, ColumnConstraints>> = {
    [Field.counterparty]: {required: true, reorderable: false},
    [Field.tradePk]: {required: true, reorderable: false},
}
