import {SvgIcon} from '@mui/material'
import {SvgIconProps} from '@mui/material/SvgIcon'

const Leaf = (props: SvgIconProps) => (
    <SvgIcon {...props} fill="currentColor" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.69975 12C5.20717 12 4.74686 11.9531 4.31879 11.8593C3.89072 11.7654 3.49491 11.6247 3.13134 11.4371C3.50663 10.0414 4.01973 8.68981 4.67062 7.38215C5.32152 6.07449 6.19818 4.98087 7.3006 4.10128C5.98708 4.73458 4.84654 5.61417 3.87899 6.74005C2.91144 7.86593 2.25175 9.14426 1.89991 10.5751C1.853 10.5282 1.80902 10.4871 1.76798 10.4519C1.72693 10.4167 1.68295 10.3757 1.63604 10.3288C1.10828 9.80103 0.703672 9.19704 0.422203 8.51682C0.140734 7.83661 0 7.12121 0 6.37062C0 5.5614 0.152462 4.7815 0.457387 4.03091C0.762312 3.28033 1.20211 2.6177 1.77677 2.04304C2.67982 1.11654 3.85261 0.515483 5.29513 0.239878C6.73766 -0.0357266 8.89559 -0.0738421 11.7689 0.125532C11.9917 2.94022 11.9595 5.08642 11.6722 6.56413C11.3848 8.04184 10.772 9.24981 9.83382 10.188C9.27088 10.7744 8.63171 11.223 7.91631 11.5338C7.20091 11.8446 6.46206 12 5.69975 12Z" />
    </SvgIcon>
)

export default Leaf
