import {ComponentsOverrides} from '@mui/material/styles'

export const MuiFilledInput = {
    styleOverrides: {
        root: {
            fontSize: '14px',

            '&.Mui-disabled': {
                backgroundColor: '#0000000f',
            },
        },
        input: {
            fontSize: '14px',
        },

        sizeSmall: {
            fontSize: '12px',
        },
        inputSizeSmall: {
            fontSize: '12px',
        },
    } as ComponentsOverrides['MuiFilledInput'],
}

export default MuiFilledInput
