import {Components, Theme} from '@mui/material'
import {ComponentsOverrides} from '@mui/material/styles'

export const MuiListSubheader: Components<Theme>['MuiListSubheader'] = {
    styleOverrides: {
        root: ({theme}) => ({
            ...theme.typography.body2,
            fontStyle: 'italic',
            color: theme.palette.grey70,
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            '&:not(:first-of-type)': {
                borderTop: `1px solid ${theme.palette.grey40}`,
            },
        }),
    } as ComponentsOverrides<Theme>['MuiListSubheader'],
}

export default MuiListSubheader
