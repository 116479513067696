import {ComponentsOverrides, Theme} from '@mui/material/styles'

export const MuiIconButton = {
    styleOverrides: {
        root: ({theme}) => ({
            '&.IconButton-default': {
                color: theme.palette.grey50,
                '&.Mui-disabled': {
                    pointerEvents: 'unset',
                    cursor: 'not-allowed',
                    '&:hover': {
                        color: theme.palette.grey40,
                    },
                },
            },
            '&.IconButton-white': {
                color: theme.palette.white,
                '&:hover': {
                    backgroundColor: 'rgba(256, 256, 256, 0.3)',
                },
                '&.Mui-disabled': {
                    color: theme.palette.grey70,
                    pointerEvents: 'unset',
                    cursor: 'not-allowed',
                    '&:hover': {
                        backgroundColor: 'none',
                    },
                },
            },
        }),
    } as ComponentsOverrides<Theme>['MuiIconButton'],
}

export default MuiIconButton
