import {ForwardedRef, forwardRef} from 'react'
import MUICheckbox, {CheckboxProps as MUICheckboxProps} from '@mui/material/Checkbox'
import cn from 'classnames'

export type CheckboxProps = MUICheckboxProps & {
    //
    // copied from MUI for storybook
    //
    /**
     * If `true`, the component is checked.
     */
    checked?: boolean
    /**
     * The color of the component. It supports those theme colors that make sense for this component.
     */
    color?: 'primary' | 'secondary' | 'default'
    /**
     * If `true`, the component appears indeterminate.
     * This does not set the native input element to indeterminate due
     * to inconsistent behavior across browsers.
     * However, we set a `data-indeterminate` attribute on the input.
     */
    indeterminate?: boolean
    /**
     * If `true`, the `input` element will be required.
     */
    required?: boolean
    /**
     * The size of the checkbox.
     * `small` is equivalent to the dense checkbox styling.
     */
    size?: 'small' | 'medium'
}

/**
 * Checkboxes allow the user to select one or more items from a set.
 */
// eslint-disable-next-line react/display-name -- FIXME
export const Checkbox = forwardRef(({className, ...props}: CheckboxProps, ref: ForwardedRef<HTMLButtonElement>) => (
    <MUICheckbox className={cn('Checkbox', className)} ref={ref} {...props} />
))

export default Checkbox
