import MUIMenu, {MenuProps as MuiMenuProps} from '@mui/material/Menu'
import cn from 'classnames'

export type MenuProps = MuiMenuProps

/**
 * Menus display a list of choices on temporary surfaces.
 *
 * A basic menu opens over the anchor element by default (this option can be changed via props). When close to a screen edge, a basic menu vertically realigns to make sure that all menu items are completely visible.
 *
 * Choosing an option should immediately ideally commit the option and close the menu.
 *
 * **Disambiguation**: In contrast to simple menus, simple dialogs can present additional detail related to the options available for a list item or provide navigational or orthogonal actions related to the primary task. Although they can display the same content, simple menus are preferred over simple dialogs because simple menus are less disruptive to the user's current context.
 */
export const Menu = ({className, ...props}: MenuProps) => <MUIMenu className={cn('Menu', className)} {...props} />

export default Menu
