import {Components} from '@mui/material'
import {ComponentsOverrides, Theme} from '@mui/material/styles'

export const MuiInputBase: Components<Theme>['MuiInputBase'] = {
    styleOverrides: {
        root: ({theme}) => ({
            '&.MuiInput-underline, &.MuiFilledInput-underline': {
                '&::before, &.Mui-disabled::before': {
                    borderColor: theme.palette.secondary.main,
                    borderBottomStyle: 'solid',
                },
            },
            '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.green90,
            },
        }),
        colorPrimary: ({theme}: {theme: Theme}) => ({
            '&.MuiInputBase-root::after': {
                borderColor: theme.palette.green90,
            },
            '&.MuiInputBase-root.Mui-focused::after': {
                borderColor: theme.palette.green90,
            },
        }),
        hiddenLabel: {
            'fieldset legend span': {
                paddingRight: '0',
            },
        },
    } as ComponentsOverrides<Theme>['MuiInputBase'],
}

export default MuiInputBase
