/**
 * @generated SignedSource<<6b970c69048ddb4e60576fe99bbbdbf6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PermissionAndNavProvider_organizationAdmin$data = {
  readonly isPrincipal: boolean;
  readonly organization: {
    readonly erpName: string | null;
    readonly locations: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly pk: number;
    }>;
    readonly name: string;
    readonly pk: number;
    readonly regions: ReadonlyArray<{
      readonly pk: number;
    }>;
  };
  readonly " $fragmentType": "PermissionAndNavProvider_organizationAdmin";
};
export type PermissionAndNavProvider_organizationAdmin$key = {
  readonly " $data"?: PermissionAndNavProvider_organizationAdmin$data;
  readonly " $fragmentSpreads": FragmentRefs<"PermissionAndNavProvider_organizationAdmin">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PermissionAndNavProvider_organizationAdmin",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPrincipal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "erpName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Location",
          "kind": "LinkedField",
          "name": "locations",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Region",
          "kind": "LinkedField",
          "name": "regions",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrganizationAdmin",
  "abstractKey": null
};
})();

(node as any).hash = "b52f79b281368d9eaf220c12d2e19814";

export default node;
