import cn from 'classnames'

import {SVGProps} from './props'

export const FilterFunnel = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-filter-funnel', className)}
        fill={fill}
        height={height}
        viewBox="0 0 12 12"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.5,9 L7.5,11.25 L4.5,11.25 L4.5,9 L7.5,9 Z M9.75,5.25 L9.75,7.5 L2.25,7.5 L2.25,5.25 L9.75,5.25 Z M12,1.5 L12,3.75 L0,3.75 L0,1.5 L12,1.5 Z"
            fillRule="evenodd"
        />
    </svg>
)
