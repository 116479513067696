import * as React from 'react'

import UnauthorizedPage from '@/components/Error/UnauthorizedPage'
import {PermissionRoute, usePermissions} from '@/providers/PermissionAndNavProvider'

import {PermissionCode} from '@/__generated__/types'

type PermissionProps = {
    /** All the permissions necessary to access the content this component wraps */
    perms?: PermissionCode[]
    /** Content of this component */
    children: React.ReactElement
    /** If true, the content will only be shown if the user has all permissions in perms (and logic) */
    /** If false, the content will only be shown if the user has just one of the permissions in perms (or logic) */
    // eslint-disable-next-line react/no-unused-prop-types -- FIXME
    hasAll?: boolean
    /** The region to use if checking for region based permissions */
    regionPk?: number
    /** The location to use if checking for location based permissions */
    locationPk?: number
    /** What to display if the user does not have permission to view the content. Defaults to `UnauthorizedPage`. Pass `null` to show nothing. */
    noAccess?: React.ReactElement | null
}

export const Permission = ({
    perms = [],
    children,
    hasAll,
    regionPk,
    locationPk,
    noAccess = <UnauthorizedPage />,
}: PermissionProps) => {
    const {canRead} = usePermissions()
    // eslint-disable-next-line react/jsx-no-useless-fragment -- FIXME
    const noAccessDisplay = noAccess && <>{noAccess}</>
    const hasPermission = hasAll
        ? perms.every((perm) => canRead(perm, locationPk, regionPk, true))
        : perms.some((perm) => canRead(perm, locationPk, regionPk, true))
    // eslint-disable-next-line react/jsx-no-useless-fragment -- FIXME
    return <>{hasPermission ? <>{children}</> : <>{noAccessDisplay}</>}</>
}

// A permission component that allows a user with an orgwide permission to access content goverened by that permission
// even in locations not owned by the user's organization.
export const PermissionExternalLocations = ({
    perms = [],
    children,
    regionPk,
    locationPk,
    noAccess,
}: PermissionProps) => {
    const permissions = usePermissions()
    // eslint-disable-next-line react/jsx-no-useless-fragment -- FIXME
    const noAccessDisplay = noAccess && <>{noAccess}</>
    const hasPermission =
        perms.every((perm) => permissions.canRead(perm, locationPk, regionPk, true)) ||
        perms.every((perm) => permissions.canRead(perm, null, null, true)) // allow users with orgwide perms access to locations outside of their org.

    // eslint-disable-next-line react/jsx-no-useless-fragment -- FIXME
    return <>{hasPermission ? <>{children}</> : <>{noAccessDisplay}</>}</>
}

type PermissionAnyLocationProps = {
    /** The route to look at permissions for */
    route: PermissionRoute
    /** Content of this component */
    children: React.ReactElement
    /** What to display if the user does not have permission to view the content. */
    noAccess?: React.ReactElement | null
}

export const PermissionAnyLocation = ({route, children, noAccess}: PermissionAnyLocationProps) => {
    // eslint-disable-next-line react/jsx-no-useless-fragment -- FIXME
    const noAccessDisplay = noAccess ? <>{noAccess}</> : null
    const permissions = usePermissions()
    const access = permissions.hasAccess(route, true)

    // eslint-disable-next-line curly -- FIXME
    if (access) return children
    // eslint-disable-next-line curly, no-else-return -- FIXME
    else return noAccessDisplay
}
