import MUIDrawer, {DrawerProps as MUIDrawerProps} from '@mui/material/Drawer'
import cn from 'classnames'

export type DrawerProps = MUIDrawerProps

/**
 * Navigation drawers provide access to destinations in your app.
 * Side sheets are surfaces containing supplementary content that
 * are anchored to the left or right edge of the screen.
 */
export const Drawer = ({className, ...props}: DrawerProps) => (
    <MUIDrawer className={cn('Drawer', className)} {...props} />
)

export default Drawer
