import * as React from 'react'
import {createRoot} from 'react-dom/client'
import {RouterProvider} from 'react-router'

import {getLDApp} from './app/launchdarkly'
import Layout from './app/Layout'
import {startSentry} from './app/sentry'
import {router} from './pages/routes'

import '@/style/base.less'

startSentry()

async function bootstrapApp() {
    const LaunchDarklyApp = await getLDApp()

    const container = document.getElementById('root')

    if (!container) {
        console.error('Could not find root element in the DOM')
        return
    }

    const root = createRoot(container)

    root.render(
        <React.StrictMode>
            <Layout launchdarkly={LaunchDarklyApp}>
                <RouterProvider router={router} />
            </Layout>
        </React.StrictMode>,
    )
}

// eslint-disable-next-line @typescript-eslint/no-floating-promises -- FIXME
bootstrapApp()
