import cn from 'classnames'

import {SVGProps} from './props'

export const Calendar = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-calendar', className)}
        fill={fill}
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.364 0v1.455h2.181c.803 0 1.455.65 1.455 1.454v10.91c0 .802-.652 1.454-1.455 1.454H1.455A1.455 1.455 0 010 13.818V2.91c0-.804.652-1.454 1.455-1.454h1.454v1.454H1.455v10.91h13.09V2.908h-2.181v1.455h-1.455V0h1.455zM5.818 9.455v2.909H2.91v-2.91h2.91zm3.637 0v2.909h-2.91v-2.91h2.91zM5.818 5.818v2.91H2.91v-2.91h2.91zm3.637 0v2.91h-2.91v-2.91h2.91zm3.636 0v2.91h-2.91v-2.91h2.91zM5.09 0v1.455h5.09v1.454h-5.09v1.455H3.636V0h1.455z"
            fillRule="evenodd"
        />
    </svg>
)
