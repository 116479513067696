import cn from 'classnames'

import {SVGProps} from './props'

export const Undo = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-undo', className)}
        fill={fill}
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.963 13.232a5.77 5.77 0 002.64 1.094V16a7.39 7.39 0 01-3.821-1.588zM13.231 0l-2.05 2.157c2.213 1.296 3.707 3.692 3.707 6.435 0 3.831-2.906 6.994-6.629 7.408v-1.674c2.807-.404 4.972-2.817 4.972-5.734A5.801 5.801 0 0010 3.401l-2.57 2.706V0h5.8zM1.657 9.114a5.749 5.749 0 001.135 2.944L1.61 13.241A7.411 7.411 0 010 9.114zM1.84 3.66l1.24 1.096a5.8 5.8 0 00-1.333 2.701H.066A7.438 7.438 0 011.84 3.66z"
            fillRule="evenodd"
        />
    </svg>
)
