import cn from 'classnames'

import {SVGProps} from './props'

export const Pencil = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-pencil', className)}
        fill={fill}
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.296 11.106l3.003.595.594 3.003-3.564.891a.765.765 0 01-.924-.925l.891-3.564zm10.17-5.496l1.631 1.63-6.785 6.784-.538-2.72 5.693-5.694zM8.76 2.904l1.63 1.629-5.694 5.694-2.721-.54L8.76 2.905zm1.625-1.626a3.069 3.069 0 014.337 0 3.069 3.069 0 010 4.337l-.548.548-4.337-4.337z"
            fillRule="evenodd"
        />
    </svg>
)
