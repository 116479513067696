import {Components, Theme} from '@mui/material'

export const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
    styleOverrides: {
        root: ({theme}) => ({
            // the padding is specifically for `MuiSelect-option`s
            // if you change this, make sure to align `MuiAutocomplete-option`.
            // The goal of the padding is so a single-lined option is 40px
            padding: theme.spacing(1.25, 2, 1.25, 2),
            minWidth: 256,
            '&.Mui-selected': {
                backgroundColor: theme.palette.grey30,
                '&:hover': {
                    backgroundColor: theme.palette.grey50,
                },
            },
        }),
    },
}

export default MuiMenuItem
