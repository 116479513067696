import MUISnackbar, {SnackbarProps as MUISnackbarProps} from '@mui/material/Snackbar'
import cn from 'classnames'

export type SnackbarProps = MUISnackbarProps

/**
 * Snackbars provide brief notifications. The component is also known as a toast.
 *
 * Snackbars inform users of a process that an app has performed or will perform. They appear temporarily, towards the bottom of the screen. They shouldn't interrupt the user experience, and they don't require user input to disappear.
 *
 * Snackbars contain a single line of text directly related to the operation performed. They may contain a text action, but no icons. You can use them to display notifications.
 *
 * You can easily use snackbars via `useSnackbar` from `@/providers/Snackbar`.
 */
export const Snackbar = ({
    className,
    autoHideDuration = 30000,
    anchorOrigin = {horizontal: 'center', vertical: 'top'},
    ...props
}: SnackbarProps) => (
    <MUISnackbar
        anchorOrigin={anchorOrigin}
        autoHideDuration={autoHideDuration}
        className={cn('Snackbar', className)}
        {...props}
    />
)

export default Snackbar
