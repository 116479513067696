import {useWindowDimensions} from '@/providers/WindowDimensions'

type Props = {
    breakpoint: number
    renderMobile: () => JSX.Element | null
    renderDesktop: () => JSX.Element | null
}

export const ResponsiveLayout = ({breakpoint, renderMobile, renderDesktop}: Props) => {
    const dimensions = useWindowDimensions()

    // Assume desktop view if width is undefined
    const width = dimensions?.width || breakpoint + 1
    return width > breakpoint ? renderDesktop() : renderMobile()
}

export default ResponsiveLayout
