import cn from 'classnames'

import {SVGProps} from './props'

export const CheckX = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        aria-label="icon-check-x"
        className={cn('icon icon-check-x', className)}
        fill={fill}
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8 0a8 8 0 100 16A8 8 0 008 0zm3.766 10.634l-1.132 1.132L8 9.132l-2.634 2.634-1.132-1.132L6.868 8 4.234 5.366l1.13-1.13L8 6.87l2.635-2.635 1.13 1.13L9.132 8l2.635 2.634z"
            fillRule="evenodd"
        />
    </svg>
)
