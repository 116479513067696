import {createContext, useContext} from 'react'
import {isEmpty, isNil, noop} from 'lodash-es'
import {DateTime} from 'luxon'

import {DatePickerType} from '@/utils/dates'

/**
 * takes RHF form data that consists of only checkboxes
 * and extracts the values that are checked
 */
export function checkboxReducer(data: Record<string, boolean>): string[] {
    const values: string[] = []

    Object.keys(data).forEach((key) => {
        if (data[key]) {
            values.push(key)
        }
    })

    return values
}

/**
 * takes a searchParam value and converts it into RHF form data
 *
 * (opposite of `checkboxReducer`)
 */
export function checkboxValues(param: string | null): Record<string, any> {
    // the parameter is not set at all
    if (!param) {
        return {}
    }

    // the parameter is set with no value
    if (param === '') {
        return {}
    }

    return param.split(',').reduce((acc: Record<string, any>, value: string) => {
        acc[value] = true
        return acc
    }, {} as Record<string, any>)
}

interface SingleMonthFormData {
    dateFilterType: DatePickerType.Month
    singleMonth?: DateTime
}
interface MonthRangeFormData {
    dateFilterType: DatePickerType.MonthRange
    monthStart?: DateTime
    monthEnd?: DateTime
}
interface SingleDateFormData {
    dateFilterType: DatePickerType.Date
    singleDate?: DateTime
}
interface DateRangeFormData {
    dateFilterType: DatePickerType.DateRange
    dateStart?: DateTime
    dateEnd?: DateTime
}
type DateFormData = SingleMonthFormData | MonthRangeFormData | SingleDateFormData | DateRangeFormData

/**
 * takes RHF form data from `<GenericDateFilter>` and converts the dates to the correct format (start and end of months) for filtering
 *
 * i.e if start date is 2021-01-23, it will be converted to 2021-01-01 and
 * if end date is 2021-01-23, it will be converted to 2021-01-31
 */
export function dateReducer(_data: Record<string, string>) {
    const data = _data as unknown as DateFormData
    const dateFormat = 'yyyy-MM-dd'

    // If data is null or empty, just return an empty array.
    if (isNil(data) || isEmpty(data)) {
        return []
    }

    if (data.dateFilterType === DatePickerType.Month) {
        const dt = data.singleMonth ?? DateTime.invalid('ignoreme')
        if (dt.isValid) {
            return [dt.startOf('month').toFormat(dateFormat), dt.endOf('month').toFormat(dateFormat)]
        }
    }

    if (data.dateFilterType === DatePickerType.MonthRange) {
        const start = data.monthStart ?? DateTime.invalid('ignoreme')
        const end = data.monthEnd ?? DateTime.invalid('ignoreme')
        if (start.isValid && end.isValid) {
            return [start.startOf('month').toFormat(dateFormat), end.endOf('month').toFormat(dateFormat)]
        }
    }

    if (data.dateFilterType === DatePickerType.Date) {
        const dt = data.singleDate ?? DateTime.invalid('ignoreme')
        if (dt.isValid) {
            return [dt.toFormat(dateFormat), dt.toFormat(dateFormat)]
        }
    }

    if (data.dateFilterType === DatePickerType.DateRange) {
        const start = data.dateStart ?? DateTime.invalid('ignoreme')
        const end = data.dateEnd ?? DateTime.invalid('ignoreme')
        if (start.isValid && end.isValid) {
            return [start.toFormat(dateFormat), end.toFormat(dateFormat)]
        }
    }

    return []
}

const emptyForm = {dateFilterType: DatePickerType.DateRange}

/**
 * takes a searchParam value and converts it into RHF form data
 *
 * (opposite of `dateReducer`)
 */
export function dateValues(param: string | null): DateFormData {
    // the parameter is not set at all
    if (!param) {
        return emptyForm
    }

    // the parameter is set with no value
    if (param === '') {
        return emptyForm
    }

    const parts = param.split(',')
    if (parts.length < 2) {
        return emptyForm
    }

    const start = DateTime.fromISO(parts[0])
    const end = DateTime.fromISO(parts[1])
    if (start.isValid && end.isValid) {
        if (start.equals(end)) {
            return {
                dateFilterType: DatePickerType.Date,
                singleDate: start,
            }
        }

        if (
            start.toISODate() === start.startOf('month').toISODate() &&
            end.toISODate() === end.endOf('month').toISODate()
        ) {
            if (start.toISODate() === end.startOf('month').toISODate()) {
                return {
                    dateFilterType: DatePickerType.Month,
                    singleMonth: end,
                }
            }

            return {
                dateFilterType: DatePickerType.MonthRange,
                monthStart: start,
                monthEnd: end,
            }
        }

        return {
            dateFilterType: DatePickerType.DateRange,
            dateStart: start,
            dateEnd: end,
        }
    }

    // otherwise ignore the values
    return emptyForm
}

/**
 * takes RHF form data that consists of start date and end date
 * and converts the dates to the correct format for filtering
 */
export function exactDateReducer(data: Record<string, string>): string[] {
    // If data is null or empty, just return an empty array.
    if (isNil(data) || isEmpty(data) || data[0] === '') {
        return []
    }
    const values: string[] = ['', '']
    if (Object.keys(data)[0] === '0' && Object.keys(data)[1] === '1') {
        values[0] = data[0]
        values[1] = data[1]
    }

    if (!isNil(data.monthStart) && !isNil(data.monthEnd)) {
        const monthStart = DateTime.fromISO(data.monthStart)
        const monthEnd = DateTime.fromISO(data.monthEnd)

        if (monthStart.isValid && monthEnd.isValid) {
            values[0] = monthStart.toFormat('yyyy-MM-dd')
            values[1] = monthEnd.toFormat('yyyy-MM-dd')
        }
    }

    return values
}

type DateRange = {
    monthStart: DateTime
    monthEnd: DateTime
}
type NoDateRange = Record<string, never>

/**
 * takes a searchParam value and converts it into RHF form data
 * (opposite of `exactDateReducer`)
 */
export const exactDateRangeValues = (param: string | null): DateRange | NoDateRange => {
    if (isNil(param)) {
        return {}
    }

    const parts = param.split(',')

    if (parts.length < 2) {
        return {}
    }

    const monthStart = DateTime.fromISO(parts[0])
    const monthEnd = DateTime.fromISO(parts[1])

    if (monthStart.isValid && monthEnd.isValid) {
        return {monthStart, monthEnd}
    }

    return {}
}

/**
 * takes RHF form data that consists of a quantity range
 * and converts the dates to the correct format for filtering
 */
export function quantityReducer(data: Record<string, string>): string[] {
    // If data is null or empty, just return an empty array.
    if (isNil(data) || isEmpty(data) || (data[0] === '' && data[1] === '')) {
        return []
    }

    const values: string[] = ['', '']
    // If data param already contains the data in the proper format, default to that data.
    if (Object.keys(data)[0] === '0' && Object.keys(data)[1] === '1') {
        values[0] = data[0] === '' ? '0' : data[0]
        values[1] = data[1] === '' ? 'max' : data[1]
        values[2] = data[2]
    }

    Object.keys(data).forEach((key) => {
        if (key === 'singleValue') {
            values[0] = data[key]
            values[1] = data[key]
        } else if (key === 'rangeStart') {
            values[0] = !isNil(data[key]) ? data[key] : '0'
        } else if (key === 'rangeEnd') {
            values[1] = !isNil(data[key]) ? data[key] : 'max'
        } else if (key === 'unit') {
            values[2] = data[key]
        }
    })
    return values
}

/**
 * takes a searchParam value and converts it into RHF form data
 *
 * (opposite of `quantityReducer`)
 */
export function quantityValues(param: string | null): Record<string, any> {
    // the parameter is not set at all
    if (!param) {
        return {}
    }

    // the parameter is set with no value
    if (param === '') {
        return {}
    }

    const splitValues = param.split(',')
    return splitValues
}

// util functions used in Trade Manager & Inventory Account Manager tables
/**
 * get the filter values for the Relay query based out
 * of the searchParams
 */
export function getFilterNumbers(value: string | null): number[] | undefined {
    if (value) {
        return value
            .split(',')
            .map((n) => Number(n))
            .filter((n) => Number.isFinite(n))
    }

    return undefined
}

export function getFilterStrings(value: string | null): string[] | undefined {
    if (value) {
        return value.split(/,(?=\S)/u)
    }

    return undefined
}

export function getFilterStringsTrimmed(value: string | null): string[] | undefined {
    const splitValue = value?.split(',') ?? []
    const returnArray = []
    if (splitValue) {
        for (let i = 0; i < splitValue.length; i++) {
            returnArray.push(splitValue[i].trim())
        }
        return returnArray
    }

    return undefined
}

export function getFilterPk(value: string | null): number | undefined {
    if (value) {
        const num = Number(value)
        if (Number.isFinite(num)) {
            return num
        }
    }

    return undefined
}

export function multiTextInputReducer(data: Record<string, any>): string[] {
    if (isNil(data) || isEmpty(data) || (data.inboundId === '' && data.outboundId === '')) {
        return []
    }

    const values = [(data?.inboundId as string) ?? '', (data?.outboundId as string) ?? '']

    return values
}

export function multiTextInputValues(param: string | null): Record<string, any> {
    if (!param || param === '') {
        return {}
    }

    return param.split(',').reduce((acc, value, index) => {
        if (index === 0) {
            return {...acc, inboundId: value}
        } else if (index === 1) {
            return {...acc, outboundId: value}
        }
        return acc
    }, {})
}

export function dateRangeValues(param: string | null): Record<string, any> {
    if (
        !param ||
        param === '' ||
        !DateTime.fromISO(param?.split(',')[0] ?? '').isValid ||
        !DateTime.fromISO(param?.split(',')[1] ?? '').isValid
    ) {
        return {}
    }

    const res = param.split(',').reduce((acc, value, index) => {
        if (index === 0 && value) {
            return {...acc, monthStart: DateTime.fromISO(value)}
        } else if (index === 1 && value) {
            return {...acc, monthEnd: DateTime.fromISO(value)}
        }
        return acc
    }, {})

    return res
}

export interface UseTableState extends RowSelectionState {
    isSelectable?: boolean
    isHeader?: boolean
}

export const defaultTableState: UseTableState = {
    isSelectable: false,
    isHeader: false,

    allSelected: false,
    selectAll: noop,
    selectRow: noop,
    deselectRow: noop,
    deselectAll: noop,
    rowSelection: {} as Record<string, boolean>,
    onRowSelectionChange: (_selection: any) => {},
}
export const TableState = createContext<UseTableState>(defaultTableState)
export const useTableContext = () => useContext(TableState)

// Selectable rows
export interface RowSelectionState {
    rowSelection: Record<string, boolean>
    onRowSelectionChange: (_selection: Record<string, boolean>) => void
    allSelected: boolean
    selectAll: () => void
    deselectAll: () => void
    selectRow: (row: {id: any}) => void
    deselectRow: (row: {id: any}) => void
}

export function getFilterNumbersAndNulls(value: string | null): number[] | undefined | null {
    if (value === '-1') {
        return null
    }

    if (value) {
        return value
            .split(',')
            .map((n) => Number(n))
            .filter((n) => Number.isFinite(n))
    }

    return undefined
}
