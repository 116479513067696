import cn from 'classnames'

import {SVGProps} from './props'

export const Doc = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-doc', className)}
        fill={fill}
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.131 0H3.6C2.718 0 2 .718 2 1.6v12.8c0 .882.718 1.6 1.6 1.6h9.6c.882 0 1.6-.718 1.6-1.6V3.669L11.131 0zM10 4.8v-4l4 4h-4z"
            fillRule="evenodd"
        />
    </svg>
)
