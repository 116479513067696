import {Components, Theme} from '@mui/material'
import {ComponentsOverrides} from '@mui/material/styles'

export const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
    defaultProps: {
        color: 'secondary',
    },
    styleOverrides: {
        root: ({theme}) => ({
            color: theme.palette.grey70,
            '&.Mui-disabled': {
                color: theme.palette.grey40,
            },
            '& .MuiSvgIcon-fontSizeMedium': {
                fontSize: '1.25rem',
            },
            '& .MuiSvgIcon-fontSizeSmall': {
                fontSize: '0.875rem',
            },
            '& input': {
                // NOTE: This is the default from mui, but it gets overwritten by the
                // reset.
                height: '100%',
            },
        }),
    } as ComponentsOverrides<Theme>['MuiCheckbox'],
}

export default MuiCheckbox
