import MUIAvatar, {AvatarProps as MUIAvatarProps} from '@mui/material/Avatar'
import cn from 'classnames'

export type AvatarProps = MUIAvatarProps

/**
 * Avatars are found throughout material design with uses in everything from tables to dialog menus.
 */
export const Avatar = ({className, ...props}: AvatarProps) => (
    <MUIAvatar className={cn('Avatar', className)} {...props} />
)

export default Avatar
