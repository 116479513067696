import {ComponentsOverrides, Theme} from '@mui/material/styles'

export const MuiTabs = {
    styleOverrides: {
        root: ({theme}) => ({
            borderBottom: `1px solid ${theme.palette.grey40}`,
        }),
        vertical: {
            borderBottom: 'none',
            '& .MuiTabs-indicator': {
                width: '3px',
            },
        },
        indicator: ({theme}) => ({
            backgroundColor: theme.palette.blue50,
            height: '3px',
        }),
    } as ComponentsOverrides<Theme>['MuiTabs'],
}

export default MuiTabs
