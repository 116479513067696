import {SvgIcon, SxProps} from '@mui/material'

type LogoProps = {
    variant?: 'light' | 'dark'
    sx?: SxProps
}

const Logo = ({variant = 'light', sx = {height: '35px', width: '39px'}}: LogoProps) => (
    <SvgIcon
        component="svg"
        fill="none"
        height="35"
        sx={sx}
        viewBox="0 0 39 35"
        width="39"
        xmlns="http://www.w3.org/2000/svg">
        <path
            clipRule="evenodd"
            d="M21.9219 34.9951H31.4826V24.8125H21.9219V34.9951Z"
            fill="#008C94"
            fillRule="evenodd"
        />
        <path
            clipRule="evenodd"
            d="M19.0269 0V16.1167L9.33949 24.8169H1.83594V35H11.3971V28.261L20.1968 20.3581H38.1422V0H19.0269Z"
            fill={variant === 'light' ? '#fff' : '#000'}
            fillRule="evenodd"
        />
        <path
            clipRule="evenodd"
            d="M13.9139 15.3867V6.13895C13.9139 5.80795 13.6619 5.53906 13.3507 5.53906H0.563265C0.252475 5.53906 0 5.80795 0 6.13895V19.7585C0 20.0895 0.252475 20.3584 0.563265 20.3584H8.37928L13.9139 15.3867Z"
            fill="#005763"
            fillRule="evenodd"
        />
    </SvgIcon>
)

export default Logo
