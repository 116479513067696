import {DateTime} from 'luxon'

import {SUNDAY_FIRST_DAY_NAMES, SUNDAY_FIRST_DAYS} from './constants'

function firstDay(locale: string) {
    if (Intl && Intl.Locale) {
        /**
         * Try and get the firstDay via weekInfo if supported,
         * or check if the locale contains 'US' and set the firstDay to Sunday
         */
        if (new Intl.Locale(locale).weekInfo?.firstDay || locale.indexOf('US') !== -1) {
            return new Intl.Locale(locale).weekInfo?.firstDay ?? 7
        }
    }
    return 1
}

export class LuxonDataWrapper {
    private locale = DateTime.local().locale
    public firstDayOfWeek = firstDay(this.locale)
    public default = SUNDAY_FIRST_DAYS
    public dayNames = SUNDAY_FIRST_DAY_NAMES

    public processedDayNames: string[] = []

    public startDay = {index: 0}

    public setStartDay(dayName: string) {
        const startDay = this.default.find((day) => dayName === day.name)
        if (startDay !== undefined) {
            this.startDay = startDay
        }
    }

    public processWeekDayOrder() {
        const days = [...this.dayNames]
        const remainingDays = days.splice(0, this.startDay.index)
        this.processedDayNames = days.concat(remainingDays)
        return this.processedDayNames
    }
}
