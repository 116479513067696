import {createContext, ReactElement, ReactNode, useCallback, useContext, useMemo, useRef, useState} from 'react'
import {noop} from 'lodash-es'
import {FormattedMessage} from 'react-intl'

import {styled} from '@waybridge/wui'
import WUIAlert from '@waybridge/wui/Alert'
import Snackbar, {SnackbarProps} from '@waybridge/wui/Snackbar'
import Stack from '@waybridge/wui/Stack'
import theme from '@waybridge/wui/ThemeProvider/minehub-theme/theme'
import Typography from '@waybridge/wui/Typography'

const Alert = styled(WUIAlert)(() => ({
    width: '100%',
    justifyContent: 'center',
    '& .MuiAlert-message': {
        display: 'flex',
        justifyContent: 'center',
        flex: '1',
    },
}))

const autoHideDuration = theme?.components?.MuiSnackbar?.defaultProps?.autoHideDuration ?? 30000

export interface SnackbarState {
    showMessage: (content: ReactNode, props?: SnackbarProps) => void
    showSuccess: (content: ReactNode, props?: SnackbarProps) => void
    showError: (content: ReactNode, props?: SnackbarProps) => void
    clearMessages: () => void
}

const SnackbarContext = createContext({
    showMessage: noop,
    showSuccess: noop,
    showError: noop,
    clearMessages: noop,
})

type SnackbarProviderProps = {
    children: ReactNode
}

const SnackbarProvider = ({children}: SnackbarProviderProps) => {
    // eslint-disable-next-line react/jsx-no-useless-fragment -- FIXME
    const [content, setContent] = useState<ReactElement>(<></>)
    const [isOpen, setIsOpen] = useState(false)
    const extraSnackbarProps = useRef<SnackbarProps>({
        anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
    })

    const handleSnackbarClose = useCallback(() => {
        setIsOpen(false)
    }, [])

    const value = useMemo(
        () => ({
            showMessage: (content: ReactElement, props: SnackbarProps = {}) => {
                extraSnackbarProps.current = {
                    ...extraSnackbarProps.current,
                    autoHideDuration,
                    ClickAwayListenerProps: undefined,
                    ...props,
                }

                setContent(content)
                setIsOpen(true)
            },
            showSuccess: (content: ReactNode, props: SnackbarProps = {}) => {
                extraSnackbarProps.current = {
                    ...extraSnackbarProps.current,
                    autoHideDuration,
                    ClickAwayListenerProps: undefined,
                    ...props,
                }

                setContent(
                    <Alert onClose={handleSnackbarClose} severity="success">
                        <Stack flex={1}>
                            <Typography fontWeight="bold" textTransform="uppercase" variant="h6">
                                <FormattedMessage defaultMessage="Success" />
                            </Typography>
                            <span>{content}</span>
                        </Stack>
                    </Alert>,
                )
                setIsOpen(true)
            },
            showError: (content: ReactNode, props: SnackbarProps = {}) => {
                extraSnackbarProps.current = {
                    ...extraSnackbarProps.current,
                    autoHideDuration: null,
                    ClickAwayListenerProps: {onClickAway: () => {}},
                    ...props,
                }

                setContent(
                    <Alert onClose={handleSnackbarClose} severity="error">
                        <Stack flex={1}>
                            <Typography fontWeight="bold" textTransform="uppercase" variant="h6">
                                <FormattedMessage defaultMessage="Error" />
                            </Typography>
                            <span>{content}</span>
                        </Stack>
                    </Alert>,
                )
                setIsOpen(true)
            },
            clearMessages() {
                setIsOpen(false)
            },
        }),
        [handleSnackbarClose],
    )

    return (
        <>
            <Snackbar {...extraSnackbarProps.current} onClose={handleSnackbarClose} open={isOpen}>
                {content}
            </Snackbar>
            <SnackbarContext.Provider value={value}>{children}</SnackbarContext.Provider>
        </>
    )
}

export default SnackbarProvider

export const useSnackbar = () => useContext(SnackbarContext) as SnackbarState
