import {SxProps, Theme} from '@mui/system'
import {first, toUpper} from 'lodash-es'
import {useFragment} from 'react-relay/hooks'

import {styled} from '@waybridge/wui'
import Avatar from '@waybridge/wui/Avatar'

import {OrgAvatar_org$key} from '@/__generated__/OrgAvatar_org.graphql'

import {orgAvatarFragment} from './OrgAvatar.queries'

const BaseAvatar = styled(Avatar)(({theme}) => ({
    border: `1px solid ${theme.palette.grey40}`,
}))

const Sizes = {
    small: {width: 24, height: 24},
    medium: {width: 32, height: 32},
    large: {width: 40, height: 40},
}

type BaseOrgAvatarProps = {
    org?: {name: string | null; photo?: string | null}
    /** The size of the photo. Small = 24x24, Medium = 32x32, large = 40x40 */
    size?: 'small' | 'medium' | 'large'
    sx?: SxProps<Theme>
    style?: React.CSSProperties
}

const imgProps = {loading: 'lazy' as const}

/**
 * Avatar for an organization in the Waybridge system.
 *
 * This is used when we use a SQLModel to get this data and don't have access to
 * an Organization fragment.
 */
export const BaseOrgAvatar = ({org, size = 'small', sx: _sx, style, ...props}: BaseOrgAvatarProps) => {
    const ourSx = Sizes[size] ?? Sizes.small
    const sx = {..._sx, ...ourSx}

    // just blank if data isn't ready
    if (!org) {
        return (
            <BaseAvatar style={style} sx={sx} {...props}>
                U
            </BaseAvatar>
        )
    }

    const name = org.name ? toUpper(first(org.name)) : 'U'

    if (org.photo) {
        return <BaseAvatar alt={name} imgProps={imgProps} src={org.photo} style={style} sx={sx} {...props} />
    }

    return (
        <BaseAvatar style={style} sx={sx} {...props}>
            {name}
        </BaseAvatar>
    )
}

type OrgAvatarProps = Omit<BaseOrgAvatarProps, 'org'> & {
    /** fragment key */
    org: OrgAvatar_org$key
}

/**
 * Avatar for an organization in the Waybridge system.
 * `org` must be an Organization from graphql
 */
const OrgAvatar = ({org, ...props}: OrgAvatarProps) => {
    const data = useFragment<OrgAvatar_org$key>(orgAvatarFragment, org)

    return <BaseOrgAvatar org={data} {...props} />
}

export default OrgAvatar
