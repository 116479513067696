import posthog, {Properties} from 'posthog-js'

import {configValue} from '@/utils/config'

const phApiKey = configValue('PH_API_KEY')

export function startPosthog(initPh: boolean = true) {
    if (!phApiKey || !initPh) {
        return
    }

    posthog.init(phApiKey, {
        api_host: 'https://evt.minehub.app',
        autocapture: true,
        // since we have different apps, this should set different cookies for each.
        cross_subdomain_cookie: false,
        sanitize_properties: (properties: Properties, _eventName: string) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, dot-notation -- FIXME
            const pathname: Optional<string> = properties['$pathname']
            if (pathname) {
                // eslint-disable-next-line dot-notation, require-unicode-regexp -- FIXME
                properties['$pathname'] = pathname.replace(/\/[0-9]+/g, '/:id')

                // work-around for putting the Relay ID in the URL :(
                if (pathname.includes('/inventory/accounts/')) {
                    // eslint-disable-next-line dot-notation -- FIXME
                    properties['$pathname'] = pathname.replace(
                        // eslint-disable-next-line require-unicode-regexp -- FIXME
                        /\/inventory\/accounts\/[^/]+\//,
                        '/inventory/accounts/:id',
                    )
                }
            }

            return properties
        },
    })
}
