import {ForwardedRef, forwardRef} from 'react'
import {Link} from 'react-router-dom'

import Button, {ExtendedButtonProps} from '@waybridge/wui/Button'

// eslint-disable-next-line react/display-name -- FIXME
const ButtonLink = forwardRef(
    (
        props: Omit<ExtendedButtonProps<typeof Link>, 'component'> & {to: string; component?: React.ElementType},
        ref: ForwardedRef<HTMLAnchorElement>,
    ) => {
        const {component = Link, to, ...rest} = props
        return <Button component={component} ref={ref} to={to} {...rest} />
    },
)

export default ButtonLink
