import {ComponentsVariants, Theme} from '@mui/material/styles'

export const MuiSvgIcon = {
    variants: [
        {
            props: {fontSize: 'small'},
            style: ({theme}) => ({
                ...theme.typography['small-icons'],
            }),
        },
        {
            props: {fontSize: 'medium'},
            style: ({theme}) => ({
                ...theme.typography['medium-icons'],
            }),
        },
        {
            props: {fontSize: 'large'},
            style: ({theme}) => ({
                ...theme.typography['large-icons'],
            }),
        },
        {
            props: {fontSize: 'extra-large'},
            style: ({theme}) => ({
                ...theme.typography['extra-large-icons'],
            }),
        },
    ] as ComponentsVariants<Theme>['MuiSvgIcon'],
}

export default MuiSvgIcon
