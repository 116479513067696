import {SvgIcon, SvgIconProps} from '@mui/material'
import cn from 'classnames'

export const X = ({fill, fontSize, className}: SvgIconProps) => (
    <SvgIcon
        className={cn('icon icon-x', className)}
        fill={fill}
        fontSize={fontSize}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16 2.403L13.598 0 8 5.597 2.404 0 0 2.403 5.597 8 0 13.597 2.404 16l5.597-5.597L13.597 16 16 13.597 10.404 8z"
            fillRule="evenodd"
        />
    </SvgIcon>
)
