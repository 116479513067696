import {ElementType, ForwardedRef, forwardRef} from 'react'
import MUIMenuItem, {MenuItemTypeMap} from '@mui/material/MenuItem'
import {OverrideProps} from '@mui/material/OverridableComponent'
import cn from 'classnames'

export type MenuItemProps<
    D extends ElementType = MenuItemTypeMap['defaultComponent'],
    P = Record<string, any>,
> = OverrideProps<MenuItemTypeMap<P, D>, D>

/**
 * ...
 */
// eslint-disable-next-line react/display-name -- FIXME
export const MenuItem = forwardRef(({className, ...props}: MenuItemProps, ref: ForwardedRef<HTMLLIElement>) => (
    <MUIMenuItem className={cn('MenuItem', className)} ref={ref} {...props} />
))

export default MenuItem
