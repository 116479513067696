import {ComponentsOverrides, Theme} from '@mui/material/styles'

export const MuiFormHelperText = {
    styleOverrides: {
        root: ({theme}) => ({
            ...theme.typography.caption,
            marginTop: 0,
        }),
    } as ComponentsOverrides<Theme>['MuiFormHelperText'],
}

export default MuiFormHelperText
