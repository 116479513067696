import React from 'react'

import {styled} from '@waybridge/wui'
import Stack from '@waybridge/wui/Stack'

const FooterContainer = styled(Stack)(({theme}) => ({
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 2),
    borderTop: `1px solid ${theme.palette.divider}`,
}))

export const MobileFooterActions = ({children}: {children?: React.ReactNode}) => (
    <FooterContainer alignItems="center" direction="row" justifyContent="flex-end" spacing={1}>
        {children}
    </FooterContainer>
)
