import {ForwardedRef, forwardRef} from 'react'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import MUISelect, {SelectProps as MUISelectProps} from '@mui/material/Select'
import cn from 'classnames'

export type {SelectChangeEvent} from '@mui/material/Select'

export type SelectProps = Omit<MUISelectProps, 'multiple'>

/**
 * Select components are used for collecting user provided information from a list of options.
 */
// eslint-disable-next-line react/display-name -- FIXME
export const Select = forwardRef(({className, children, ...props}: SelectProps, ref: ForwardedRef<HTMLDivElement>) => (
    <MUISelect
        IconComponent={KeyboardArrowDown}
        className={cn('Select', className)}
        {...props}
        MenuProps={{
            ...props.MenuProps,
            PaperProps: {
                ...props.MenuProps?.PaperProps,
                sx: {
                    boxShadow: (theme) => theme.shadows[12],
                    // ↓ in case the parent wants to override the above styles
                    ...props.MenuProps?.PaperProps?.sx,
                },
            },
        }}
        multiple={false}
        ref={ref}>
        {children}
    </MUISelect>
))

export default Select
