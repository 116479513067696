/**
 * @generated SignedSource<<e1540488e18378f49b8b7f2fd16e18b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwitchOrgDialogQuery$variables = {};
export type SwitchOrgDialogQuery$data = {
  readonly meAdmin: {
    readonly orgSwitchTargets: ReadonlyArray<{
      readonly name: string;
      readonly pk: number;
      readonly " $fragmentSpreads": FragmentRefs<"OrgAvatar_org">;
    }>;
  } | null;
};
export type SwitchOrgDialogQuery = {
  response: SwitchOrgDialogQuery$data;
  variables: SwitchOrgDialogQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SwitchOrgDialogQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserAdmin",
        "kind": "LinkedField",
        "name": "meAdmin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "orgSwitchTargets",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OrgAvatar_org"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SwitchOrgDialogQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserAdmin",
        "kind": "LinkedField",
        "name": "meAdmin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "orgSwitchTargets",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "photo",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "257baea9f680abec2170e14d4ce48633",
    "id": null,
    "metadata": {},
    "name": "SwitchOrgDialogQuery",
    "operationKind": "query",
    "text": "query SwitchOrgDialogQuery {\n  meAdmin {\n    orgSwitchTargets {\n      name\n      pk\n      ...OrgAvatar_org\n      id\n    }\n    id\n  }\n}\n\nfragment OrgAvatar_org on Organization {\n  id\n  name\n  photo\n}\n"
  }
};
})();

(node as any).hash = "684a0c91dd97ffc881803dd7a99b324e";

export default node;
