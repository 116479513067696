import {ComponentsOverrides, Theme} from '@mui/material/styles'

export const MuiChip = {
    styleOverrides: {
        root: ({theme}) => ({
            ...theme.typography.body1,
            height: 'auto',
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
        }),
        deleteIcon: {
            fontSize: '1rem',
        },
    } as ComponentsOverrides<Theme>['MuiChip'],
}

export default MuiChip
