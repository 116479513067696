import * as React from 'react'
import {basicLogger} from 'launchdarkly-js-client-sdk'
import {asyncWithLDProvider} from 'launchdarkly-react-client-sdk'

import {configValue} from '@/utils/config'

type ComponentProps = {
    children: React.ReactNode
}

const ldClientId = configValue('LD_CLIENT_ID')

export async function getLDApp() {
    if (ldClientId) {
        const LDProvider = await asyncWithLDProvider({
            clientSideID: ldClientId,
            options: {
                logger: basicLogger({level: 'error'}),
            },
        })

        // eslint-disable-next-line react/display-name, react/function-component-definition -- FIXME
        return ({children}: ComponentProps) => <LDProvider>{children}</LDProvider>
    }

    // eslint-disable-next-line react/display-name, react/function-component-definition, react/jsx-no-useless-fragment -- FIXME
    return ({children}: ComponentProps) => <>{children}</>
}
