import {SvgIcon} from '@mui/material'
import cn from 'classnames'

import {ConveyanceSVGProps} from './props'

export const Forklift = ({background, fill, fontSize = 'medium', className, sx}: ConveyanceSVGProps) => (
    <SvgIcon
        className={cn('icon icon-forklift', className, {
            'with-background': background,
        })}
        fill={fill}
        fontSize={fontSize}
        sx={sx}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4 2.333v11.334c0 .368-.298.666-.667.666H0V13h2.667v-2h-2V9.667h2V2.333H4zm8.667 8c1.104 0 2 .896 2 2 0 1.105-.896 2-2 2-1.105 0-2-.895-2-2 0-1.104.895-2 2-2zm-6 0c1.104 0 2 .896 2 2 0 1.105-.896 2-2 2-1.105 0-2-.895-2-2 0-1.104.895-2 2-2zM12 1c1.47 0 2.667 1.196 2.667 2.667V7.39l1.138 1.138c.125.125.195.294.195.471v2.667c0 .368-.298.666-.667.666 0-1.472-1.194-2.666-2.666-2.666-1.473 0-2.667 1.194-2.667 2.666h-.667c0-1.472-1.194-2.666-2.666-2.666-.698 0-1.328.274-1.804.713L6 7.538V7c0-3.308 2.691-6 6-6zm0 1.333C9.427 2.333 7.333 4.427 7.333 7h3.48l.54-2.161 1.294.323L12.187 7h1.146V3.667c0-.735-.597-1.334-1.333-1.334z"
            transform="translate(-652 -700) translate(620 700) translate(32)"
        />
    </SvgIcon>
)
