import {ComponentsOverrides, ComponentsVariants, Theme} from '@mui/material/styles'

export const MuiLink = {
    styleOverrides: {
        root: ({theme}) => ({
            color: theme.palette.secondary.main,
            textDecoration: 'none',
            '&:hover': {
                filter: 'brightness(80%)',
            },
            '&:active': {
                filter: 'brightness(60%)',
            },
        }),
        underlineAlways: {
            borderBottom: 'none',
        },
        underlineHover: {
            '&:hover': {
                textDecoration: 'none',
                borderBottom: '1px solid currentColor',
            },
        },
    } as ComponentsOverrides<Theme>['MuiLink'],
    variants: [
        {
            props: {variant: 'header'},
            style: ({theme}) => ({
                ...theme.typography.body1,
                color: theme.palette.primary.main,
                '&.active': {
                    fontWeight: 'bold',
                    borderBottomColor: theme.palette.secondary.main,
                },
            }),
        },
    ] as ComponentsVariants<Theme>['MuiLink'],
}

export default MuiLink
