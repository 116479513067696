import cn from 'classnames'

import {SVGProps} from './props'

export const Reject = ({fill, height, width, className}: SVGProps) => (
    <svg
        className={cn('icon icon-reject', className)}
        fill={fill}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.658 2.343C12.148.832 10.138 0 8.001 0S3.855.832 2.344 2.343C.833 3.854 0 5.863 0 8c0 2.137.833 4.147 2.344 5.659C3.855 15.168 5.864 16 8.001 16s4.145-.832 5.657-2.342c3.119-3.12 3.119-8.196 0-11.315zm-.606 9.579L9.132 8l3.92-3.92c1.787 2.3 1.787 5.54 0 7.842zm-1.13-8.973l-3.921 3.92L4.08 2.95C5.198 2.078 6.562 1.6 8 1.6c1.44 0 2.804.478 3.922 1.349zM1.6 8c0-1.44.478-2.803 1.349-3.92L6.869 8l-3.92 3.922C2.078 10.803 1.6 9.44 1.6 8zm2.48 5.052L8 9.132l3.92 3.92C10.803 13.923 9.44 14.4 8 14.4c-1.438 0-2.802-.477-3.92-1.348z"
            fillRule="evenodd"
        />
    </svg>
)
