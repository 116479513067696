// DatePicker constants
export const MONDAY_FIRST_DAY_NAMES = ['M', 'T', 'W', 'T', 'F', 'S', 'S']
export const SUNDAY_FIRST_DAY_NAMES = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

export const SUNDAY_FIRST_DAYS = [
    {
        index: 0,
        name: 'Sun',
        dayName: 'S',
    },
    {
        index: 1,
        name: 'Mon',
        dayName: 'M',
    },
    {
        index: 2,
        name: 'Tue',
        dayName: 'T',
    },
    {
        index: 3,
        name: 'Wed',
        dayName: 'W',
    },
    {
        index: 4,
        name: 'Thu',
        dayName: 'T',
    },
    {
        index: 5,
        name: 'Fri',
        dayName: 'F',
    },
    {
        index: 6,
        name: 'Sat',
        dayName: 'S',
    },
]

export const MONDAY_FIRST_DAYS = [
    {
        index: 0,
        name: 'Mon',
        dayName: 'M',
    },
    {
        index: 1,
        name: 'Tue',
        dayName: 'T',
    },
    {
        index: 2,
        name: 'Wed',
        dayName: 'W',
    },
    {
        index: 3,
        name: 'Thu',
        dayName: 'T',
    },
    {
        index: 4,
        name: 'Fri',
        dayName: 'F',
    },
    {
        index: 5,
        name: 'Sat',
        dayName: 'S',
    },
    {
        index: 6,
        name: 'Sun',
        dayName: 'S',
    },
]
