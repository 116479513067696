import cn from 'classnames'

import {SVGProps} from './props'

export const Caret = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-caret', className)}
        fill={fill}
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path d="M8 9.49L2.354 3.846.846 5.353 8 12.507l7.154-7.154-1.508-1.508z" fillRule="evenodd" />
    </svg>
)
