import {forwardRef} from 'react'
import MUITable, {TableProps as MUITableProps} from '@mui/material/Table'
import cn from 'classnames'

export type TableProps = MUITableProps

/**
 * Tables display sets of data. They can be fully customized.
 *
 * Tables display information in a way that's easy to scan, so that users can look for patterns and insights. They can be embedded in primary content, such as cards. They can include:
 *
 * - A corresponding visualization
 * - Navigation
 * - Tools to query and manipulate data
 */
export const Table = forwardRef<HTMLTableElement, TableProps>(({className, ...props}, ref) => (
    <MUITable className={cn('Table', className)} ref={ref} {...props} />
))

Table.displayName = 'Table'

export default Table
