import {Components, Theme} from '@mui/material'
import {ComponentsOverrides} from '@mui/material/styles'

export const MuiTableCell: Components<Theme>['MuiTableCell'] = {
    defaultProps: {},
    styleOverrides: {
        head: ({theme}) => ({
            ...theme.typography.body1,
        }),
        body: ({theme}) => ({
            ...theme.typography.body1,
        }),
        footer: ({theme}) => ({
            ...theme.typography.body1,
            color: 'black',
            borderBottom: 'none',
        }),
    } as ComponentsOverrides<Theme>['MuiTableCell'],
}

export default MuiTableCell
