import * as Sentry from '@sentry/browser'
import LogRocket from 'logrocket'

import {configValue, configValueBool} from '@/utils/config'

const logrocketAppId = configValue('LR_APP_ID')
const isSentryEnabled = configValueBool('SENTRY')

export function startLogRocket(isEnabled: boolean = true) {
    if (isEnabled && logrocketAppId) {
        LogRocket.init(logrocketAppId, {
            release: `ui@${process.env.GIT_COMMIT}`,
            network: {
                requestSanitizer: (request) => {
                    // eslint-disable-next-line dot-notation -- FIXME
                    if (request.headers && request.headers['Authorization']) {
                        // eslint-disable-next-line dot-notation -- FIXME
                        request.headers['Authorization'] = '*redacted*'
                    }
                    if (request.method === 'GET') {
                        return request
                    }

                    const {body} = request
                    if (body) {
                        let parsedBody: any
                        try {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME
                            parsedBody = JSON.parse(body)
                            // eslint-disable-next-line dot-notation, @typescript-eslint/no-unsafe-member-access -- FIXME
                            if (parsedBody['password']) {
                                // eslint-disable-next-line dot-notation, @typescript-eslint/no-unsafe-member-access -- FIXME
                                parsedBody['password'] = '*redacted*'
                            }
                        } catch (error) {
                            return request
                        }

                        request.body = JSON.stringify(parsedBody)
                    }
                    return request
                },
            },
        })

        if (isSentryEnabled) {
            LogRocket.getSessionURL((sessionURL) => {
                Sentry.configureScope((scope) => {
                    scope.setExtra('sessionURL', sessionURL)
                })
            })
        }
    }
}
