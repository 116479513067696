import {defineMessages} from 'react-intl'

export default defineMessages({
    openAccounts: {
        defaultMessage: 'All accounts',
    },
    searchInventory: {
        defaultMessage: 'Search inventory',
    },
    trades: {
        defaultMessage: 'Trades',
    },
    inventory: {
        defaultMessage: 'Inventory',
    },
    appointments: {
        defaultMessage: 'Appointments',
    },
    shipments: {
        defaultMessage: 'Shipments',
    },
    allShipments: {
        defaultMessage: 'All shipments',
    },
    scheduling: {
        defaultMessage: 'Scheduling',
    },
    OpenMenu: {
        defaultMessage: 'Open Menu',
    },
    OpenUserMenu: {
        defaultMessage: 'Open User Menu',
    },
    reports: {
        defaultMessage: 'Reports',
    },
    stockFlow: {
        defaultMessage: 'Stock \u0026 flow',
    },
    physicalPosition: {
        defaultMessage: 'Physical position',
    },
    draftTrades: {
        defaultMessage: 'Draft trades',
    },
    openTrades: {
        defaultMessage: 'Open trades',
    },
    closedTrades: {
        defaultMessage: 'Closed trades',
    },
    allTrades: {
        defaultMessage: 'All trades',
    },
    tradeManager: {
        defaultMessage: 'Trade manager',
    },
    invoices: {
        defaultMessage: 'Invoices',
    },
})
