import {PermissionAndNavProviderQuery$data} from '@/__generated__/PermissionAndNavProviderQuery.graphql'
import {PermissionCode} from '@/__generated__/types'

export type Roles = NonNullable<NonNullable<PermissionAndNavProviderQuery$data['meAdmin']>['roles']>

export type Organization = PermissionAndNavProviderQuery$data['organizationByPk']
export type OrganizationLocations = NonNullable<PermissionAndNavProviderQuery$data['organizationByPk']>['locations']

export enum PermissionLevel {
    Read = 1,
    Write = 2,
}

export type UserPermission = {
    code: PermissionCode
    level: number
}

export type LocationPermission = NonNullable<Roles['locationPermissions']>[0]
export type RegionPermission = NonNullable<Roles['regionPermissions']>[0]
export type Permission = NonNullable<RegionPermission['permissions']>[0]
