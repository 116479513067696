import cn from 'classnames'

import {SVGProps} from './props'

export const AllocateTarget = ({fill, width = 16, height = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-allocate-target', className)}
        fill={fill}
        height={height}
        viewBox="0 0 32 32"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M27.312 4.688l-2.256 2.256C27.36 9.264 28.8 12.464 28.8 16c0 7.072-5.728 12.8-12.8 12.8-7.072 0-12.8-5.728-12.8-12.8 0-6.528 4.88-11.904 11.2-12.688v3.232c-4.544.768-8 4.704-8 9.456 0 5.296 4.304 9.6 9.6 9.6 5.296 0 9.6-4.304 9.6-9.6 0-2.656-1.072-5.056-2.816-6.784l-2.256 2.256C21.68 12.64 22.4 14.24 22.4 16c0 3.536-2.864 6.4-6.4 6.4-3.536 0-6.4-2.864-6.4-6.4 0-2.976 2.048-5.456 4.8-6.176v3.424c-.96.56-1.6 1.568-1.6 2.752 0 1.76 1.44 3.2 3.2 3.2 1.76 0 3.2-1.44 3.2-3.2 0-1.184-.64-2.208-1.6-2.752V0H16C7.168 0 0 7.168 0 16s7.168 16 16 16 16-7.168 16-16c0-4.416-1.792-8.416-4.688-11.312z"
            fillRule="evenodd"
        />
    </svg>
)
