import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon'
import {DateTime} from 'luxon'

import {MONDAY_FIRST_DAY_NAMES, SUNDAY_FIRST_DAY_NAMES} from './constants'
import {LuxonDataWrapper} from './LuxonDataWrapper'

// Luxon doesn't locale the weekdays' order: https://github.com/mui-org/material-ui-pickers/issues/1626
export class CustomLuxonUtils extends AdapterLuxon {
    public dayNames: string[]

    public wrapper: LuxonDataWrapper

    constructor() {
        super()
        this.wrapper = new LuxonDataWrapper()
        this.wrapper.setStartDay(this.wrapper.firstDayOfWeek === 7 ? 'Sun' : 'Mon')
        this.dayNames = this.wrapper.firstDayOfWeek === 7 ? SUNDAY_FIRST_DAY_NAMES : MONDAY_FIRST_DAY_NAMES
    }

    public getWeekdays = () => this.wrapper.processWeekDayOrder()

    // Original implementation: https://github.com/dmtrKovalenko/date-io/blob/77f89b4859d228ed8f34c740e3defc78d52bdba1/packages/luxon/src/luxon-utils.ts#L333
    public getWeekArray = (date: DateTime) => {
        const {index} = this.wrapper.startDay

        // timeshift from startOfWeek to index of startDay
        const startOfWeek = date.startOf('month').startOf('week')

        // This assumes the default is Monday (1) and any difference should subtracted
        const daysShift = startOfWeek.weekday - index
        const firstDayOfWeek = startOfWeek.minus({days: daysShift})
        const lastDayOfWeek = date.endOf('month').endOf('week').minus({days: daysShift})

        const {days} = lastDayOfWeek.diff(firstDayOfWeek, 'days').toObject()

        const weeks: DateTime[][] = []
        new Array<number>(Math.round(days as number))
            .fill(0)
            .map((_, i) => i)
            .map((day) => firstDayOfWeek.plus({days: day}))
            .forEach((v, i) => {
                if (i === 0 || (i % 7 === 0 && i > 6)) {
                    weeks.push([v])
                    return
                }

                weeks[weeks.length - 1].push(v)
            })

        return weeks.filter((w) => w.some((d: DateTime) => d.month === date.month))
    }
}
