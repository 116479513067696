import {ComponentsOverrides, Theme} from '@mui/material/styles'

export const MuiInput = {
    styleOverrides: {
        root: ({theme}) => ({
            '&.Mui-disabled': {
                '&:before': {
                    borderBottomColor: theme.palette.grey50,
                    borderBottomStyle: 'solid',
                },
            },
        }),
        inputSizeSmall: {
            fontSize: '0.75rem',
            lineHeight: 1,
        },
    } as ComponentsOverrides<Theme>['MuiInput'],
}

export default MuiInput
