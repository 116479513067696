import {ForwardedRef, forwardRef} from 'react'
import {Link as RRLink, NavLink as RRNavLink, NavLinkProps as RRNavLinkProps} from 'react-router-dom'

import Link, {LinkProps} from '@waybridge/wui/Link'

export type RouterLinkProps = LinkProps

export type NavLinkProps = LinkProps & RRNavLinkProps

/**
 * Composed `wui/Link` and React Router `Link`
 */
export const RouterLink = forwardRef(({...props}: LinkProps, ref: ForwardedRef<HTMLAnchorElement>) => (
    <Link component={RRLink} ref={ref} {...props} />
))
RouterLink.displayName = 'RouterLink'

/**
 * Composed `wui/Link` and React Router `NavLink`
 */
export const NavLink = forwardRef(({...props}: NavLinkProps, ref: ForwardedRef<HTMLAnchorElement>) => (
    <Link component={RRNavLink} ref={ref} {...props} />
))
NavLink.displayName = 'NavLink'

export default RouterLink
