import cn from 'classnames'

import {SVGProps} from './props'

export const MathPlus = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-math-plus', className)}
        fill={fill}
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path d="M16 9.414V6.586H9.413V0H6.586v6.586H0v2.828h6.586V16L9.414 16V9.414z" fillRule="evenodd" />
    </svg>
)
