import cn from 'classnames'

import {SVGProps} from './props'

export const Info = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-info', className)}
        fill={fill}
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8 0C3.589 0 0 3.59 0 8c0 4.412 3.589 8 8 8s8-3.588 8-8c0-4.41-3.589-8-8-8zm0 3.8a1 1 0 110 2.002A1 1 0 018 3.8zm2.4 8.2H5.6v-1.6h1.6V8h-.8V6.4H8a.8.8 0 01.8.8v3.2h1.6V12z"
            fillRule="evenodd"
        />
    </svg>
)
