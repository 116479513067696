import MUIDivider, {DividerProps as MUIDividerProps} from '@mui/material/Divider'
import cn from 'classnames'

import {Color} from '@waybridge/wui/ThemeProvider/colors'

type DividerProps = Omit<MUIDividerProps, 'light' | 'component'> & {
    /** Whether the divider should be dashed */
    dashed?: boolean
    /** Whether the divider should be thick (3px) */
    thick?: boolean
    /** The color of the divider */
    color?: Color
}

export const Divider = ({
    dashed = false,
    thick = false,
    color = 'black',
    orientation = 'horizontal',
    className,
    ...props
}: DividerProps) => (
    <MUIDivider
        {...props}
        className={cn('DividerV2', {'DividerV2-thick': thick, 'DividerV2-dashed': dashed}, className)}
        component="div"
        orientation={orientation}
        sx={{...props.sx, color, borderColor: color}}
    />
)

export default Divider
