import {Components} from '@mui/material'

export const MuiDivider = {
    defaultProps: {
        component: 'div',
    },
    styleOverrides: {
        root: {
            '&.DividerV2-thick': {
                borderBottomWidth: '3px',
            },
            '&.DividerV2-dashed': {
                borderBottomStyle: 'dashed',
            },
            '&.MuiDivider-withChildrenVertical': {
                borderRightWidth: '0px',
                borderRightStyle: 'none',
            },
        },
        vertical: {
            '&.DividerV2-thick': {
                borderRightWidth: '3px',
            },
            '&.DividerV2-dashed': {
                borderRightStyle: 'dashed',
            },
            '&.MuiDivider-withChildrenVertical': {
                borderRightWidth: '0px',
                borderRightStyle: 'none',
            },
        },
        wrapper: {
            color: 'initial',
        },
        withChildren: {
            borderBottomStyle: 'none',
            borderBottomWidth: 'none',
            '&.DividerV2:before, &.DividerV2:after': {
                borderColor: 'currentColor',
            },
            '&.DividerV2-thick': {
                borderBottomWidth: '0',
                '&:before, &:after': {
                    borderTopWidth: '3px',
                },
            },
            '&.DividerV2-dashed': {
                borderBottomStyle: 'none',
                '&:before, &:after': {
                    borderTopStyle: 'dashed',
                },
            },
        },
        withChildrenVertical: {
            '&.DividerV2-thick': {
                '&:before, &:after': {
                    borderTopWidth: 'none',
                    borderLeftWidth: '3px',
                },
            },
            '&.DividerV2-dashed': {
                '&:before, &:after': {
                    borderTopStyle: 'none',
                    borderLeftStyle: 'dashed',
                },
            },
        },
    },
} as Components['MuiDivider']

export default MuiDivider
