import cn from 'classnames'

import {SVGProps} from './props'

export const Receive = ({fill, height, width, className}: SVGProps) => (
    <svg
        className={cn('icon icon-receive', className)}
        fill={fill}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.914 10.4C5.27 11.778 6.51 12.8 8 12.8c1.489 0 2.73-1.022 3.086-2.4H16V16H0v-5.6zM8.8 0v6.869l1.834-1.835 1.132 1.132L8 9.93 4.234 6.166l1.132-1.132L7.2 6.87V0h1.6z"
            fillRule="evenodd"
            transform="translate(-1414 -150) translate(1400 134) translate(14 16)"
        />
    </svg>
)
