import MUIContainer, {ContainerProps as MUIContainerProps} from '@mui/material/Container'
import cn from 'classnames'

export type ContainerProps = MUIContainerProps

/**
 * The container centers your content horizontally. It's the most basic layout element.
 */
export const Container = ({className, ...props}: ContainerProps) => (
    <MUIContainer className={cn('Container', className)} {...props} />
)

export default Container
