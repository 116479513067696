import {useEffect, useMemo, useRef} from 'react'

import {styled} from '@waybridge/wui'
import WUITable, {TableProps} from '@waybridge/wui/Table'

import {defaultTableState, TableState, UseTableState} from './utils'

export const TableStateProvider = ({
    isSelectable,
    isHeader = false,
    children,
    ...tableCtx
}: {
    isSelectable: boolean
    isHeader?: boolean
    children: React.ReactNode
} & Partial<UseTableState>) => {
    const value = useMemo(
        () => ({...defaultTableState, ...tableCtx, isSelectable, isHeader}),
        [tableCtx, isSelectable, isHeader],
    )
    return <TableState.Provider value={value}>{children}</TableState.Provider>
}

const PlainTable = styled(WUITable, {
    shouldForwardProp: (prop) => prop !== 'isZebra',
})<WTableProps>(({theme, isZebra}) => ({
    borderCollapse: 'separate',
    ...(isZebra && {
        'tbody tr[data-row-number="odd"] td': {
            backgroundColor: theme.palette.white,
        },
        'tbody tr[data-row-number="even"] td': {
            backgroundColor: theme.palette.grey10,
        },
    }),
}))

export type WTableProps = {
    isSelectable?: boolean
    children?: React.ReactNode
    state?: UseTableState
    isZebra?: boolean
} & TableProps
const Table = ({isSelectable = false, isZebra = false, children, state, ...props}: WTableProps) => {
    const tableRef = useRef<HTMLTableElement>(null)

    useEffect(() => {
        if (!isZebra || !tableRef.current) {
            return undefined
        }

        const applyZebraStriping = () => {
            const rows = tableRef.current?.querySelectorAll('tbody tr:not(.monthLabel)')
            if (rows) {
                rows.forEach((row, index) => {
                    row.setAttribute('data-row-number', index % 2 === 0 ? 'even' : 'odd')
                })
            }
        }

        applyZebraStriping()
        const observer = new MutationObserver(() => applyZebraStriping())
        observer.observe(tableRef.current, {childList: true, subtree: true})
        return () => {
            observer.disconnect()
        }
    }, [isZebra])

    return (
        <TableStateProvider isSelectable={isSelectable} {...(state ? state : null)}>
            <PlainTable isZebra={isZebra} ref={tableRef} {...props}>
                {children}
            </PlainTable>
        </TableStateProvider>
    )
}
export default Table
