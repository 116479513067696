import {styled, SxProps, Theme} from '@waybridge/wui'
import {StatusColorDark, StatusColorLightest} from '@waybridge/wui/ThemeProvider/minehub-theme/theme'

import {getBackgroundColorGeneric, getTextColorGeneric} from './utils'

export const StatusPillInner = styled('div')(({theme}) => ({
    padding: theme.spacing(0.25, 1.25),
    borderRadius: '16px',
    background: theme.palette.grey50,
    ...theme.typography.caption,
    letterSpacing: '1.5px',
    fontWeight: '600',
    textAlign: 'center',
    textTransform: 'uppercase',
    display: 'inline-block',
}))

export const StatusPillFromColors = ({
    children,
    bgColor,
    textColor,
    sx,
}: {
    children: React.ReactNode
    bgColor: StatusColorLightest
    textColor: StatusColorDark
    sx?: SxProps<Theme>
}) => (
    <StatusPillInner
        sx={{
            bgcolor: bgColor,
            color: textColor,
            ...sx,
        }}>
        {children}
    </StatusPillInner>
)

export const StatusPillFromMap = ({
    children,
    backgroundColorMap,
    textColorMap,
    instance,
    sx,
}: {
    children: React.ReactNode
    backgroundColorMap: Record<string | number | symbol, StatusColorLightest>
    textColorMap: Record<string | number | symbol, StatusColorDark>
    instance: string | number | symbol | null
    sx?: SxProps<Theme>
}) => (
    <StatusPillInner
        sx={{
            bgcolor: getBackgroundColorGeneric(backgroundColorMap, instance),
            color: getTextColorGeneric(textColorMap, instance),
            ...sx,
        }}>
        {children}
    </StatusPillInner>
)

export default StatusPillFromMap
