import {IntlShape} from 'react-intl'

import {ColumnConstraints, ColumnState} from '@waybridge/wui/CustomizeTableColumnsDialog/types'

import {Trades$data} from '@/__generated__/Trades.graphql'
import {TradesFiltersQuery$data} from '@/__generated__/TradesFiltersQuery.graphql'
import {MetalsTradeResourceExportOrder, QuantityUnit} from '@/__generated__/types'

type TradeList = Trades$data['metalsTradeList']
export type RawTrade = NonNullable<NonNullable<TradeList>['edges'][0]>['node']
export type Organization = NonNullable<RawTrade['buyer']>
export type Trade = RawTrade & {
    skipCounterparties?: boolean
    counterparties: Organization[]
    isEditable: boolean
}

export interface RowData {
    id: string
    counterparty: Organization[]
    buyer: Organization
    seller: Organization
    shippingOrg: Organization
    originator: {pk: number}
    tradeName: string
    pk: Trade['pk']
    tradeType: Trade['tradeType']
    tradeFulfillmentType: Trade['tradeFulfillmentType']
    tradeStatus: Trade['tradeStatus']
    startDate: Trade['startDate']
    endDate: Trade['startDate']
    commodity: {
        id: string
        pk: number
        name: string
    }
    specification: Trade['materialSpecifications']
    material: string
    deliveryTerms: Trade['deliveryTerms']
    destination: string
    quantity: {
        minQuantity: number
        maxQuantity: number
        quantityUnit: QuantityUnit
    }
    quantityLB: {
        minQuantity: number
        maxQuantity: number
        quantityUnit: QuantityUnit
    }
    quantityMT: {
        minQuantity: number
        maxQuantity: number
        quantityUnit: QuantityUnit
    }
    region: {
        pk: number
    }
    buyerRegion: {
        id: string
        pk: number
        name: string
    }
    sellerRegion: {
        id: string
        pk: number
        name: string
    }
}

export enum Field {
    counterparty = 'counterparty',
    pk = 'pk',
    tradeStatus = 'tradeStatus',
    tradeType = 'tradeType',
    tradeFulfillmentType = 'tradeFulfillmentType',
    deliveryPeriod = 'deliveryPeriod',
    material = 'material',
    specification = 'specification',
    destination = 'destination',
    quantity = 'quantity',
    region = 'region',
    settings = 'settings',
    actions = 'actions',
}

export const getExportFields = (field: Field, intl: IntlShape) => {
    switch (field) {
        case Field.counterparty:
            return {counterparties: {columnName: intl.formatMessage({defaultMessage: 'Counterparty'})}}
        case Field.pk:
            return {id: {columnName: intl.formatMessage({defaultMessage: 'ID'})}}
        case Field.tradeStatus:
            return {tradeStatus: {columnName: intl.formatMessage({defaultMessage: 'Status'})}}
        case Field.tradeType:
            return {buySell: {columnName: intl.formatMessage({defaultMessage: 'Buy/Sell'})}}
        case Field.tradeFulfillmentType:
            return {
                tradeFulfillmentType: {columnName: intl.formatMessage({defaultMessage: 'Trade type'})},
            }
        case Field.deliveryPeriod:
            return {
                startDate: {columnName: intl.formatMessage({defaultMessage: 'Trade period - start date'})},
                endDate: {columnName: intl.formatMessage({defaultMessage: 'Trade period - end date'})},
            }
        case Field.material:
            return {
                material: {columnName: intl.formatMessage({defaultMessage: 'Material'})},
            }

        case Field.specification:
            return {
                specification: {columnName: intl.formatMessage({defaultMessage: 'Specification'})},
            }
        case Field.destination:
            return {
                destination: {columnName: intl.formatMessage({defaultMessage: 'Destination'})},
            }
        case Field.quantity:
            return {
                minQuantity: {columnName: intl.formatMessage({defaultMessage: 'Quantity - min'})},
                maxQuantity: {columnName: intl.formatMessage({defaultMessage: 'Quantity - max'})},
                quantityUnit: {columnName: intl.formatMessage({defaultMessage: 'Quantity - unit'})},
            }
        case Field.region:
            return {
                region: {columnName: intl.formatMessage({defaultMessage: 'Region'})},
            }
        default:
            return {}
    }
}

export const exportFieldsOrderMappings: Partial<Record<Field, MetalsTradeResourceExportOrder[]>> = {
    [Field.counterparty]: [MetalsTradeResourceExportOrder.Counterparties],
    [Field.pk]: [MetalsTradeResourceExportOrder.Id],
    [Field.tradeStatus]: [MetalsTradeResourceExportOrder.TradeStatus],
    [Field.tradeType]: [MetalsTradeResourceExportOrder.BuySell],
    [Field.tradeFulfillmentType]: [MetalsTradeResourceExportOrder.TradeFulfillmentType],
    [Field.deliveryPeriod]: [MetalsTradeResourceExportOrder.StartDate, MetalsTradeResourceExportOrder.EndDate],
    [Field.material]: [MetalsTradeResourceExportOrder.Material],
    [Field.specification]: [MetalsTradeResourceExportOrder.Specification],
    [Field.destination]: [MetalsTradeResourceExportOrder.Destination],
    [Field.quantity]: [
        MetalsTradeResourceExportOrder.MinQuantity,
        MetalsTradeResourceExportOrder.MaxQuantity,
        MetalsTradeResourceExportOrder.QuantityUnit,
    ],
    [Field.region]: [MetalsTradeResourceExportOrder.Region],
}

export enum TradeListType {
    Draft = 'Draft',
    Live = 'Live',
    Closed = 'Closed',
}

export const allTradesDefaultColumnOrder: ColumnState[] = [
    {field: Field.settings, enabled: true},
    {field: Field.counterparty, enabled: true},
    {field: Field.pk, enabled: true},
    {field: Field.tradeStatus, enabled: true},
    {field: Field.tradeType, enabled: true},
    {field: Field.tradeFulfillmentType, enabled: true},
    {field: Field.deliveryPeriod, enabled: true},
    {field: Field.material, enabled: true},
    {field: Field.specification, enabled: true},
    {field: Field.destination, enabled: true},
    {field: Field.quantity, enabled: true},
    {field: Field.region, enabled: true},
    {field: Field.actions, enabled: true},
]

export const columnContraints: Partial<Record<Field, ColumnConstraints>> = {
    [Field.counterparty]: {required: true, reorderable: false},
    [Field.pk]: {required: true, reorderable: false},
    [Field.actions]: {required: true, reorderable: false, visible: false},
}

export type Destination = NonNullable<
    NonNullable<NonNullable<TradesFiltersQuery$data['metalsTradeList']>['locations']>[0]
>

export type Counterparty = NonNullable<
    NonNullable<NonNullable<TradesFiltersQuery$data['myOrganization']>['counterpartyRelationships'][0]>['counterparty']
>

export type Commodity = NonNullable<
    NonNullable<NonNullable<TradesFiltersQuery$data['allCommodities']>['edges'][0]>['node']
>

export type Region = NonNullable<NonNullable<NonNullable<TradesFiltersQuery$data['metalsTradeList']>['regions']>[0]>

export interface Settings extends Partial<Record<Field, any>> {
    columns: ColumnState[]
}

export enum GlobalFilter {
    Live = 'Live',
    Draft = 'Draft',
    Closed = 'Closed',
}
