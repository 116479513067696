import React, {forwardRef} from 'react'

import {styled} from '@waybridge/wui'
import {Box} from '@waybridge/wui/Box'

export type ContentProps = {
    /** Page content */
    children?: React.ReactNode
    style?: React.CSSProperties
}

const ContentWrapper = styled(Box)({
    flex: 1,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
})

// eslint-disable-next-line react/display-name -- FIXME
export const Content = forwardRef<HTMLDivElement, ContentProps>(({children, style}, ref) => (
    <ContentWrapper className="PageContent" ref={ref} style={style}>
        {children}
    </ContentWrapper>
))

export default Content
