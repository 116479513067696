import MUIListItemText, {ListItemTextProps as MUIListItemTextProps} from '@mui/material/ListItemText'
import cn from 'classnames'

export type ListItemTextProps = MUIListItemTextProps

/**
 * ...
 */
export const ListItemText = ({className, ...props}: ListItemTextProps) => (
    <MUIListItemText className={cn('ListItemText', className)} {...props} />
)

export default ListItemText
