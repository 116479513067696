/**
 * @generated SignedSource<<5393f6e16a3817d25d5156994cbcdbf7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrgAvatar_org$data = {
  readonly id: string;
  readonly name: string;
  readonly photo: string | null;
  readonly " $fragmentType": "OrgAvatar_org";
};
export type OrgAvatar_org$key = {
  readonly " $data"?: OrgAvatar_org$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrgAvatar_org">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrgAvatar_org",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photo",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "28019419f34195d6521adedc2f690eb5";

export default node;
