import {defineMessages} from 'react-intl'

import {Field} from './types'

export default defineMessages({
    // COLUMN LABELS
    [Field.id]: {
        defaultMessage: 'ID',
    },
    [Field.internalProductReference]: {
        defaultMessage: 'Internal product reference',
    },
    shipmentId: {
        defaultMessage: 'Shipment ID',
    },
    exportDataBolLineItemId: {
        defaultMessage: 'BOL line item ID',
    },
    [Field.status]: {
        defaultMessage: 'Status',
    },
    [Field.releaseDate]: {
        defaultMessage: 'Release date',
    },
    [Field.releaseNumbers]: {
        defaultMessage: 'Release numbers',
    },
    [Field.origin]: {
        defaultMessage: 'Origin',
    },
    [Field.shipDate]: {
        defaultMessage: 'Ship date',
    },
    [Field.destination]: {
        defaultMessage: 'Destination',
    },
    [Field.arrivalDate]: {
        defaultMessage: 'Arrival date',
    },
    [Field.trackingEta]: {
        defaultMessage: 'ETA',
    },
    [Field.waybridgeEta]: {
        defaultMessage: 'Waybridge ETA',
    },
    [Field.predictiveETA]: {
        defaultMessage: 'Predictive ETA',
    },
    [Field.currentLocation]: {
        defaultMessage: 'Current location',
    },
    [Field.receiveDate]: {
        defaultMessage: 'Received date',
    },
    [Field.appointment]: {
        defaultMessage: 'Appointment',
    },
    [Field.bol]: {
        defaultMessage: 'BOL',
    },
    [Field.co2e]: {
        defaultMessage: 'CO2e',
    },
    [Field.inboundDeliveryId]: {
        defaultMessage: 'Inbound delivery ID',
    },
    [Field.outboundDeliveryId]: {
        defaultMessage: 'Outbound delivery ID',
    },
    [Field.inboundBatchId]: {
        defaultMessage: 'Inbound batch ID',
    },
    [Field.outboundBatchId]: {
        defaultMessage: 'Outbound batch ID',
    },
    [Field.conveyance]: {
        defaultMessage: 'Conveyance',
    },
    [Field.conveyanceDetails]: {
        defaultMessage: 'Conveyance details',
    },
    [Field.carrier]: {
        defaultMessage: 'Carrier',
    },
    [Field.seal]: {
        defaultMessage: 'Seal #',
    },
    [Field.shipper]: {
        defaultMessage: 'Shipper',
    },
    [Field.seller]: {
        defaultMessage: 'Seller',
    },
    [Field.toConsignee]: {
        defaultMessage: 'To consignee',
    },
    [Field.material]: {
        defaultMessage: 'Material',
    },
    [Field.productName]: {
        defaultMessage: 'Product name',
    },
    [Field.order]: {
        defaultMessage: 'Order #',
    },
    [Field.orderType]: {
        defaultMessage: 'Order type',
    },
    [Field.bolWeight]: {
        defaultMessage: 'BOL weight',
    },
    [Field.scaleNet]: {
        defaultMessage: 'Scale net',
    },
    [Field.bundles]: {
        defaultMessage: 'Bundles',
    },
    [Field.storageLocation]: {
        defaultMessage: 'Storage location',
    },
    [Field.weightDiff]: {
        defaultMessage: 'Weight diff',
    },
    [Field.weightDiffPercent]: {
        defaultMessage: 'Weight diff %',
    },
    [Field.hasExceptions]: {
        defaultMessage: 'Has exceptions',
    },
    [Field.submittedAtDate]: {
        defaultMessage: 'Submitted date',
    },
    [Field.documents]: {
        defaultMessage: 'Documents',
    },
    [Field.tradeId]: {
        defaultMessage: 'Trade ID',
    },
    [Field.quotaMonth]: {
        defaultMessage: 'Quota month',
    },
    [Field.incoterm]: {
        defaultMessage: 'Incoterm',
    },
    [Field.productSpecs]: {
        defaultMessage: 'Product specifications',
    },
    [Field.createdAt]: {
        defaultMessage: 'Created at',
    },
    [Field.updatedAt]: {
        defaultMessage: 'Updated at',
    },
    // OTHER LABELS
    scaleWeightUnit: {
        defaultMessage: 'Scale weight unit',
    },
    bolWeightUnit: {
        defaultMessage: 'BOL weight unit',
    },
    weightDiffUnit: {
        defaultMessage: 'Weight diff unit',
    },
    quantityUnit: {
        defaultMessage: 'Quantity unit',
    },
    quantity: {
        defaultMessage: 'Quantity',
    },
    vehicleInfo: {
        defaultMessage: 'Vehicle info',
    },
    unknown: {
        defaultMessage: 'Unknown',
    },
    // CONVEYANCES
    truck: {
        defaultMessage: 'Truck',
    },
    rail: {
        defaultMessage: 'Rail',
    },
    vessel: {
        defaultMessage: 'Vessel',
    },
    transfer: {
        defaultMessage: 'Transfer',
    },
    brand: {
        defaultMessage: 'Brand',
    },
    brands: {
        defaultMessage: 'Brands',
    },
})
