import MUIListItemButton, {ListItemButtonTypeMap} from '@mui/material/ListItemButton'
import {OverrideProps} from '@mui/material/OverridableComponent'
import cn from 'classnames'

export type ListItemButtonProps<
    D extends React.ElementType = ListItemButtonTypeMap['defaultComponent'],
    P = Record<string, any>,
> = OverrideProps<ListItemButtonTypeMap<P, D>, D>

/**
 * ...
 */
export const ListItemButton = ({className, ...props}: ListItemButtonProps) => (
    <MUIListItemButton className={cn('ListItemButton', className)} {...props} />
)

export default ListItemButton
