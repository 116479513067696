import {SvgIcon, SvgIconProps} from '@mui/material'
import cn from 'classnames'

export const Printer = ({fill, fontSize = 'medium', className}: SvgIconProps) => (
    <SvgIcon
        className={cn('icon icon-printer', className)}
        fill={fill}
        fontSize={fontSize}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.6 5H2.4C1.072 5 0 6.072 0 7.4v4.8h3.2v3.2h9.6v-3.2H16V7.4C16 6.072 14.928 5 13.6 5zm-2.4 8.8H4.8v-4h6.4v4zm2.4-5.6c-.44 0-.8-.36-.8-.8 0-.44.36-.8.8-.8.44 0 .8.36.8.8 0 .44-.36.8-.8.8zM12.8 1H3.2v3.2h9.6V1z"
            fillRule="evenodd"
        />
    </SvgIcon>
)
