import Box, {BoxProps} from '@waybridge/wui/Box'
import CircularProgress from '@waybridge/wui/CircularProgress'

/**
 * This is the default fallback component for Suspense
 */
type FallbackProps = BoxProps

const Fallback = ({height = '100%', ...props}: FallbackProps) => (
    <Box alignItems="center" display="flex" height={height} justifyContent="center" minHeight={120} {...props}>
        <CircularProgress disableShrink />
    </Box>
)

export default Fallback
