import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon'

export const Signal = (props: SvgIconProps) => (
    <SvgIcon {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.8,10.2L4.8,10.2c-0.4-1-0.6-2-0.6-3.1C4.3,6,4.5,5,4.9,4c0.3-0.8,0.8-1.5,1.4-2.2l0.3-0.4l-1-1L5.2,0.9
	C4.5,1.6,4,2.5,3.5,3.5C3,4.6,2.8,5.8,2.8,7.1c0,1.3,0.3,2.6,0.8,3.6c0.4,0.9,0.9,1.8,1.6,2.6l0.4,0.4l1-1l-0.3-0.4
	C5.7,11.8,5.2,11.1,4.8,10.2z"
        />
        <path
            d="M20.8,3.5c-0.4-1-1-1.9-1.6-2.6l-0.4-0.4l-1,1l0.3,0.4c0.5,0.6,1,1.3,1.4,2.2l0,0c0.4,1,0.6,2,0.6,3.1
	c0,1.1-0.2,2.2-0.7,3.1c-0.3,0.8-0.8,1.5-1.4,2.1l-0.3,0.4l1,1l0.4-0.4c0.7-0.7,1.2-1.6,1.6-2.6c0.5-1.2,0.8-2.4,0.8-3.7
	S21.4,4.6,20.8,3.5z"
        />
        <path
            d="M17.4,4.9L17.4,4.9c-0.2-0.5-0.5-1-0.8-1.4l-0.4-0.4l-1,1l0.3,0.4c0.3,0.3,0.5,0.7,0.6,1c0.2,0.6,0.3,1.1,0.3,1.6
	c0,0.6-0.1,1.2-0.3,1.6c-0.2,0.4-0.4,0.7-0.6,1l-0.3,0.3l1,1l0.3-0.4c0.3-0.4,0.6-0.9,0.8-1.4c0.3-0.7,0.4-1.4,0.4-2.2
	C17.8,6.3,17.7,5.5,17.4,4.9z"
        />
        <path
            d="M8.3,8.8C8.1,8.2,8,7.6,8,7.1c0-0.6,0.1-1.2,0.4-1.7c0.2-0.4,0.4-0.7,0.6-1l0.3-0.3l-1-1L7.9,3.5C7.6,3.9,7.3,4.4,7,4.9
	C6.7,5.6,6.6,6.3,6.6,7c0,0.8,0.2,1.6,0.5,2.3c0.2,0.5,0.5,0.9,0.8,1.3l0.4,0.4l1-1L8.9,9.8C8.7,9.5,8.5,9.1,8.3,8.8z"
        />
        <path
            d="M21.4,22.7l0-1h-2.3L13.5,8.6c0.4-0.4,0.7-0.9,0.7-1.5c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2c0,0.7,0.3,1.2,0.8,1.6L5.7,21.7
	H3.6v0.1l0,0l0,1h17.8L21.4,22.7L21.4,22.7z M12.1,9C12.1,9,12.2,9.1,12.1,9c0.1,0,0.2,0,0.3,0l1.7,3.9l-3.5,0L12.1,9z M10.1,14
	l4.4,0l1.8,4l-7.8,0L10.1,14z M8,19.1l8.8,0l1.2,2.6h-11L8,19.1z"
        />{' '}
    </SvgIcon>
)

export default Signal
