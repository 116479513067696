import {styled} from '@waybridge/wui'
import TableCell from '@waybridge/wui/TableCell'
import TableRow from '@waybridge/wui/TableRow'

const Shadow = styled('div')(({theme}) => ({
    top: '0px',
    zIndex: 2,
    width: '100%',
    height: '25px',
    marginTop: '-25px',
    position: 'absolute',
    pointerEvents: 'none',
    boxShadow: theme.shadows[8],
}))

type HeaderShadowProps = React.CSSProperties

const HeaderShadow = (props: HeaderShadowProps) => (
    <TableRow style={{position: 'sticky', ...props}}>
        <TableCell colSpan={99} style={{border: 'none', padding: '0'}}>
            <Shadow />
        </TableCell>
    </TableRow>
)

export default HeaderShadow
