import {Train} from '@mui/icons-material'
import {useTheme} from '@mui/material'
import cn from 'classnames'

import {ConveyanceSVGProps} from './props'

export const Rail = ({sx, className}: ConveyanceSVGProps) => {
    const theme = useTheme()
    return (
        <Train className={cn('icon icon-rail', className, {})} sx={{...theme.typography['conveyance-icons'], ...sx}} />
    )
}
