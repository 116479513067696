import MUIList, {ListProps as MUIListProps} from '@mui/material/List'
import cn from 'classnames'

export type ListProps = MUIListProps

/**
 * ...
 */
export const List = ({className, ...props}: ListProps) => <MUIList className={cn('List', className)} {...props} />

export default List
