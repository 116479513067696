import {lazy} from 'react'
import {FormattedMessage} from 'react-intl'

import TablePageSuspense from '@waybridge/wui/TablePageSuspense/TablePageSuspense'

import definedMessagesToHeaderLabels from '@/hooks/definedMessagesToHeaderLabels'

import {columnTitles} from './messages'
import {defaultColumnOrder} from './types'

const InvoiceList = lazy(() => import('./InvoiceList'))

export const InvoicesSuspenseProps = {
    buttons: [{label: <FormattedMessage defaultMessage="Export" />}],
    defaultColumnOrder: defaultColumnOrder,
    headerLabels: definedMessagesToHeaderLabels(columnTitles),
    title: {label: <FormattedMessage defaultMessage="Invoices" />},
}

export const LazyInvoiceList = () => (
    <TablePageSuspense {...InvoicesSuspenseProps}>
        <InvoiceList />
    </TablePageSuspense>
)

export default LazyInvoiceList
