import {FormattedMessage} from 'react-intl'

import Button from '@waybridge/wui/Button'
import ButtonLink from '@waybridge/wui/ButtonLink'
import FullPage from '@waybridge/wui/FullPage'
import {LockIcon} from '@waybridge/wui/Icons'
import Stack from '@waybridge/wui/Stack'
import Typography from '@waybridge/wui/Typography'

import {tradesPath} from '@/pages/routes/paths'

export const Unauthorized = () => (
    <Stack direction="row" gap={3}>
        <div>
            <LockIcon fontSize="extra-large" sx={{color: 'red50'}} />
        </div>
        <div style={{maxWidth: '450px'}}>
            <Stack direction="column" gap={3}>
                <Typography display="block" fontWeight="bold" variant="h4">
                    <FormattedMessage defaultMessage="You don&rsquo;t have permission to view this page." />
                </Typography>
                <Typography display="block" variant="body1">
                    <FormattedMessage defaultMessage="Reach out to support if you believe you have encountered this page by mistake." />
                </Typography>
                <Stack direction="row" gap={1}>
                    <Button href="mailto:support@minehub.com" variant="outlined">
                        <FormattedMessage defaultMessage="Contact support" />
                    </Button>
                    <ButtonLink to={`/${tradesPath}`} variant="contained">
                        <FormattedMessage defaultMessage="View trades" />
                    </ButtonLink>
                </Stack>
            </Stack>
        </div>
    </Stack>
)

const UnauthorizedPage = () => (
    <div style={{display: 'flex', flex: '1', background: 'white'}}>
        <div
            style={{
                flex: 1,
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <FullPage>
                <Unauthorized />
            </FullPage>
        </div>
    </div>
)

export default UnauthorizedPage
