import React, {useEffect, useRef, useState} from 'react'
import {useAtomValue} from 'jotai'
import {Settings} from 'luxon'
import {IntlProvider} from 'react-intl'
import {getLangDir} from 'rtl-detect'

import useFlags from '@/hooks/useFlags'

import {localeAtom, store} from './store'

interface InternationalizationDescription {
    loadFile: () => Promise<any>
    /**
     * Display language label
     */
    label: string
    /**
     * BCP 47 language tag
     */
    languageTag: string
    /**
     * ISO 639-1 HTML lang attribute value
     */
    lang: string
}

export const languages: {[lang: string]: InternationalizationDescription} = {
    'en-US': {
        loadFile: () => import('@/translations/en-US.json'),
        lang: 'en',
        languageTag: 'en-US', // BCP 47 language tag
        label: 'English',
    },
    pt: {
        loadFile: () => import('@/translations/pt.json'),
        lang: 'pt',
        languageTag: 'pt-BR',
        label: 'Português (Portuguese)',
    },
    // 'zh-TW': {
    //     lang: 'zh',
    //     languageTag: 'zh-CN',
    //     label: '中文 (Chinese)',
    // },
    // ja: {
    //     file: 'ja.json',
    //     lang: 'ja',
    //     languageTag: 'ja-JP',
    //     label: '日本語 (Japanese)',
    // },
}

/**
 * Try and lookup what language file should be used based on specified setting
 * @param locale
 */
export function getLanguage(locale: string) {
    const desc = languages[locale]
    if (desc) {
        return desc
    }
    // eslint-disable-next-line no-warning-comments -- FIXME
    // TODO: Try and resolve mapping navigator.langauge to a language
    return languages['en-US']
}

function wrapPromise<T>(promise: Promise<T>) {
    let status = 'pending'
    let result: T
    const suspender = promise.then(
        (r) => {
            status = 'success'
            result = r
        },
        (e) => {
            status = 'error'
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME
            result = e
        },
    )
    return {
        read() {
            if (status === 'pending') {
                throw suspender
            } else if (status === 'error') {
                throw result
            } else if (status === 'success') {
                return result
            }
            throw suspender
        },
    }
}

const useGetMessages = (locale: string) => {
    const initialResource = null
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME
    const [resource, setResource] = useState<any>(initialResource)
    const currentLocale = useRef(initialResource ? locale : null)
    useEffect(() => {
        const lang = getLanguage(locale)
        if (currentLocale.current !== locale) {
            currentLocale.current = locale
            // eslint-disable-next-line @typescript-eslint/no-use-before-define -- FIXME
            const _resource = wrapPromise(lang.loadFile())
            setResource(_resource)
        }
    }, [locale])
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access -- FIXME
    return resource?.read()
}

export const LocaleApp = ({children}: {children?: React.ReactNode}) => {
    const flags = useFlags() // false to render children while waiting on this
    const stateLocale = useAtomValue(localeAtom, store)
    // if feature is enabled, allow custom language
    const locale = flags.langSwitcher ? stateLocale ?? 'en-US' : 'en-US'
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-use-before-define -- FIXME
    const messages = useGetMessages(locale)

    Settings.defaultLocale = getLanguage(locale).languageTag

    useEffect(() => {
        document.documentElement.lang = getLanguage(locale).lang
        document.documentElement.dir = getLangDir(locale) ?? 'ltr'
    }, [locale])

    return (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME
        <IntlProvider defaultLocale={locale} key={locale} locale={locale} messages={messages}>
            {children}
        </IntlProvider>
    )
}
