import cn from 'classnames'

import {SVGProps} from './props'

export const ArrowRight = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-arrow-right', className)}
        fill={fill}
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path d="M5.597 13.597L9.494 9.7H0V6.3h9.494L5.597 2.403 8 0l8 8-8 8z" fillRule="evenodd" />
    </svg>
)
