import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon'

export const Dot = (props: SvgIconProps) => (
    <SvgIcon {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <rect fill="none" height="24" width="24" />
        <circle cx="12" cy="12" r="4" />
    </SvgIcon>
)

export default Dot
