import {DirectionsBoat} from '@mui/icons-material'
import {useTheme} from '@mui/material'
import {SvgIconProps} from '@mui/material/SvgIcon'

export const Vessel = ({sx, ...props}: SvgIconProps) => {
    const theme = useTheme()
    return <DirectionsBoat {...props} sx={{...theme.typography['conveyance-icons'], ...sx}} />
}

export default Vessel
