import React from 'react'
import cn from 'classnames'

import {styled} from '@waybridge/wui'
import Box, {BoxProps} from '@waybridge/wui/Box'
import Stack, {StackProps} from '@waybridge/wui/Stack'

const ActionSubnavWrapper = styled(Stack)(({theme}) => ({
    zIndex: theme.zIndex.appBar,
    minHeight: 64,
    position: 'sticky',
    top: 0,
    flexWrap: 'wrap',
    background: theme.palette.white,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.03)',
    boxSizing: 'border-box',
    '& .WUIActionSubnavSection-root:first-of-type > .WUIActionSubnavSection-wrapper': {
        marginRight: 'auto',
    },
    '& .WUIActionSubnavSection-root:last-child': {},
    '& .WUIActionSubnavSection-root:last-child > .WUIActionSubnavSection-wrapper': {
        marginLeft: 'auto',
    },
}))

export type ActionSubnavProps = {
    children?: React.ReactNode
    className?: string
} & StackProps

// Component for rendering a bar of page actions
export const ActionSubnav = ({children, className, ...props}: ActionSubnavProps) => (
    <ActionSubnavWrapper
        className={cn('WUIActionSubnav-root', className)}
        direction="row"
        gap={{xs: 0, sm: 2}}
        left={0}
        position="sticky"
        px={{sm: 6, xs: 2}}
        role="navigation"
        width="100%"
        {...props}>
        {children}
    </ActionSubnavWrapper>
)

const ActionSubnavSectionWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
})

// Component used to display sections in the ActionSubnav. The first and last are aligned to the edges.
export const ActionSubnavSection = ({children, className, fullWidth, ...props}: BoxProps & {fullWidth?: boolean}) => (
    <ActionSubnavSectionWrapper className={cn(className, 'WUIActionSubnavSection-root')} {...props}>
        <span className="WUIActionSubnavSection-wrapper" style={{flex: fullWidth ? 1 : 'initial', flexShrink: 0}}>
            {children}
        </span>
    </ActionSubnavSectionWrapper>
)
