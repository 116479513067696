import MUIDialog, {DialogProps as MUIDialogProps} from '@mui/material/Dialog'
import cn from 'classnames'

import {styled, useMediaQuery, useTheme} from '@waybridge/wui'

type sizes = 'sm' | 'md' | 'lg'

const StyledDialog = styled(MUIDialog)<{size?: sizes}>(({theme}) => ({
    '&.Dialog-sizesm': {
        '.MuiDialogTitle-root+.MuiDialogContent-root': {
            padding: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2),
            },
        },
        '.MuiDialogActions-root': {
            padding: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                paddingRight: theme.spacing(2),
                paddingLeft: theme.spacing(2),
            },
        },
    },
    '&.Dialog-sizemd': {
        '.MuiDialogTitle-root+.MuiDialogContent-root': {
            padding: theme.spacing(6),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2),
            },
        },
        '.MuiDialogActions-root': {
            borderTop: `1px solid ${theme.palette.grey40}`,
            paddingRight: theme.spacing(6),
            paddingLeft: theme.spacing(6),
            [theme.breakpoints.down('sm')]: {
                paddingRight: theme.spacing(2),
                paddingLeft: theme.spacing(2),
            },
        },
    },
    '&.Dialog-sizelg': {
        '.MuiDialogTitle-root': {
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
        },
        '.MuiDialogTitle-root+.MuiDialogContent-root': {
            padding: theme.spacing(6),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2),
            },
        },
        '.MuiDialogActions-root': {
            borderTop: `1px solid ${theme.palette.grey40}`,
            paddingRight: theme.spacing(6),
            paddingLeft: theme.spacing(6),
            [theme.breakpoints.down('sm')]: {
                paddingRight: theme.spacing(2),
                paddingLeft: theme.spacing(2),
            },
        },
    },
}))

export type DialogProps = {size?: sizes} & MUIDialogProps

/**
 * ...
 */
export const Dialog = ({className, size = 'lg', ...props}: DialogProps) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <StyledDialog
            className={cn('Dialog', className, `Dialog-size${size}`)}
            // eslint-disable-next-line react/jsx-no-leaked-render -- FIXME
            fullScreen={size !== 'sm' && fullScreen}
            fullWidth
            maxWidth={size}
            {...props}
        />
    )
}

export default Dialog
