import React, {lazy} from 'react'
import {SxProps, Theme} from '@mui/system'
import {atom, useAtom, useAtomValue, useSetAtom} from 'jotai'

import {styled} from '@waybridge/wui'
import Stack from '@waybridge/wui/Stack'
import Suspense from '@waybridge/wui/Suspense'

import Nav from '@/components/Nav'
import {showFlagsPanelAtom} from '@/components/UserFlagsPanel/atoms'
import useBodyClass from '@/hooks/useBodyClass'

import Container from './Container'
import Title from './Title'
import TopBar from './TopBar'

const UserFlagsPanel = lazy(() => import('@/components/UserFlagsPanel'))

const pageLayoutContextAtom = atom<null | HTMLDivElement>(null)

export const usePageLayoutContentRef = () => useAtomValue(pageLayoutContextAtom)

export const useGreyPage = () => {
    useBodyClass('WUIGreyPage')
}

export const useWhitePage = () => {
    useBodyClass('WUIWhitePage')
}

const OverflowContent = styled('div')({
    flex: 1,
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
})

export type PageLayoutProps = {
    /** whether to use old fonts */
    isOldDesign?: boolean

    /** if set, will update the page title */
    title?: string
    /** page content */
    children?: React.ReactNode
    /** subnav contents */
    // eslint-disable-next-line react/no-unused-prop-types -- FIXME
    subnav?: React.ReactNode
    style?: React.CSSProperties
    sx?: SxProps<Theme>
    overflow?: boolean
}

export const PageLayout = ({title, children, style, sx, overflow = true, isOldDesign = false}: PageLayoutProps) => {
    useBodyClass(isOldDesign ? 'OldDesignSystem' : 'NewDesignSystem')
    const [showFlags, setShowFlags] = useAtom(showFlagsPanelAtom)
    const setContentRef = useSetAtom(pageLayoutContextAtom)

    return (
        <Container style={style} sx={sx}>
            {/* eslint-disable-next-line react/jsx-no-leaked-render -- FIXME */}
            {title && <Title title={title} />}
            <TopBar>
                <Nav />
            </TopBar>
            <Stack direction="row" flex={1} overflow="auto">
                <Stack direction="column" flex={1} overflow="auto" ref={setContentRef}>
                    {overflow ? (
                        <OverflowContent className="PageContent">
                            <Suspense>{children}</Suspense>
                        </OverflowContent>
                    ) : (
                        <Suspense>{children}</Suspense>
                    )}
                </Stack>
                {/* eslint-disable-next-line react/jsx-no-leaked-render -- FIXME */}
                {showFlags && (
                    <UserFlagsPanel onClose={() => setShowFlags(false)} style={{width: 300, height: '100%'}} />
                )}
            </Stack>
        </Container>
    )
}

export default PageLayout
