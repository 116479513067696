import {styled} from '@waybridge/wui'
import {Stack} from '@waybridge/wui/Stack'

export const TablePageContainer = styled(Stack, {
    shouldForwardProp: (prop: string) => prop !== 'scrollBarWidth',
})<{scrollBarWidth: number}>(({theme, scrollBarWidth}) => ({
    overflow: 'auto',
    height: '100%',
    paddingLeft: theme.spacing(5),
    paddingBlock: theme.spacing(0, 14),
    paddingRight: `calc(${theme.spacing(5)} - ${scrollBarWidth}px)`,
    backgroundColor: theme.palette.grey20,

    ['@media screen']: {
        [theme.breakpoints.up('lg')]: {
            paddingLeft: theme.spacing(7.5),
            paddingRight: `calc(${theme.spacing(7)} - ${scrollBarWidth}px)`,
        },
    },
}))
export default TablePageContainer
