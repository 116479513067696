import {Components, Theme} from '@mui/material'
import {ComponentsOverrides} from '@mui/material/styles'
import {pickersCalendarHeaderClasses} from '@mui/x-date-pickers'

// See also MuiPickersDay for the Calendar's content
export const MuiCalendarPicker: Components<Theme>['MuiCalendarPicker'] = {
    defaultProps: {},
    styleOverrides: {
        root: ({theme}) => ({
            margin: 0,
            padding: 0,
            maxHeight: '360px',
            overflowY: 'hidden',
            '.MuiCalendarPicker-viewTransitionContainer': {
                'button.Mui-selected': {
                    backgroundColor: theme.palette.secondary.main,
                    color: 'white',
                },
            },
            [`.${pickersCalendarHeaderClasses.label}`]: {
                transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',
            },
            '& .MuiDayPicker-header span': {
                flex: 1,
            },

            // CalendarPicker header
            '& > div:first-of-type': {
                margin: 0,
                minHeight: '56px',
                maxHeight: '56px',
                '& > div:first-of-type': {
                    marginLeft: 'auto',
                    paddingLeft: '50px',
                },
                // Header's text
                '& .PrivatePickersFadeTransitionGroup-root': {
                    fontWeight: theme.typography.fontWeightBold,
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.h6.fontSize,
                    lineHeight: theme.typography.h6.lineHeight,
                    '& > div': {
                        // It's a pointer when the year can be selected by clicking on the month in the header
                        cursor: 'default',
                    },
                },
                // Chevron buttons to change months
                '& > div:last-of-type': {
                    '& > button:first-of-type': {
                        position: 'absolute',
                        left: 12, // Not a relative space, no need for a variable (doesn't depend on typography)
                    },
                },
            },
            // Week days wrapper
            '& > div:last-of-type > div:first-of-type > div:first-of-type': {
                justifyContent: 'space-around',
                // Week days names (M, T, W...)
                '& span': {
                    fontWeight: theme.typography.fontWeightBold,
                    fontSize: theme.typography.body2.fontSize,
                    lineHeight: theme.typography.body2.lineHeight,
                    width: '32px',
                },
            },
            // The days wrapper
            '& .PrivatePickersSlideTransition-root': {
                minHeight: '200px',
                display: 'flex',
                width: '100%',

                // Single row of days
                '& div[role=rowgroup]': {
                    position: 'relative',
                    width: '100%',
                    minWidth: '320px',
                },
                '& div[role=row]': {
                    justifyContent: 'space-around',
                },
            },
            // selected month
            '& .MuiMonthPicker-root > .PrivatePickersMonth-root': {
                fontSize: theme.typography.body2.fontSize,
                '&.Mui-selected': {
                    fontWeight: 'bold',
                    fontSize: theme.typography.body2.fontSize,
                },
            },
        }),
    } as ComponentsOverrides<Theme>['MuiCalendarPicker'],
}

export default MuiCalendarPicker
