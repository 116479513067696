import {useLayoutEffect} from 'react'

/**
 * Adds a class to the body when the component mounts, and removes the class
 * from the body when unmounted
 */
export default function useBodyClass(className: string) {
    useLayoutEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define -- FIXME
        addClass(className)
        // eslint-disable-next-line @typescript-eslint/no-use-before-define -- FIXME
        return () => removeClass(className)
    }, []) // eslint-disable-line
    //  Empty array ensures effect is only run on mount and unmount
}

function addClass(className: string) {
    const body = document.querySelector('body')
    if (body) {
        body.setAttribute('class', [...body.classList, className].join(' '))
    }
}

function removeClass(className: string) {
    const body = document.querySelector('body')
    if (body) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define -- FIXME
        const newList = rejectFirst(Array.from(body.classList), className)
        body.setAttribute('class', newList.join(' '))
    }
}

// removes the first instance of the classname from the list
// (this is so we can dont have to track how many modals are open at once)
function rejectFirst(classes: string[], className: string) {
    const idx = classes.indexOf(className)

    // eslint-disable-next-line curly -- FIXME
    if (idx < 0) return [...classes]

    if (idx === 0) {
        return classes.slice(1)
    }

    return [...classes.slice(0, idx), ...classes.slice(idx + 1)]
}
