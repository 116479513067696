import {forwardRef} from 'react'
import MUIFormControlLabel, {FormControlLabelProps as MUIFormControlLabelProps} from '@mui/material/FormControlLabel'
import cn from 'classnames'

export type FormControlLabelProps = MUIFormControlLabelProps
// eslint-disable-next-line react/display-name -- FIXME
export const FormControlLabel = forwardRef(({className, ...props}: FormControlLabelProps, ref) => (
    <MUIFormControlLabel className={cn('FormControlLabel', className)} ref={ref} {...props} />
))

export default FormControlLabel
