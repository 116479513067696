import {ForwardedRef, forwardRef} from 'react'
import MUIIconButton, {IconButtonProps as MuiIconButtonProps} from '@mui/material/IconButton'
import cn from 'classnames'

export type IconButtonProps = MuiIconButtonProps & {
    /** The default behavior is button. (see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type) */
    type?: 'button' | 'submit' | 'reset'
    /**
     * - `white` variant will make the Icon white and have a white hover effect; useful for dark backgrounds.
     * - `default` is the normal variant.
     */
    variant?: 'default' | 'white'
    /** The color of the component.  */
    color?: 'default' | 'primary' | 'secondary'
    /** The size of the component. `small` is equivalent to the dense button styling.  */
    size?: 'small' | 'medium' | 'large'
    href?: string
    target?: string
}

// eslint-disable-next-line react/display-name -- FIXME
export const IconButton = forwardRef(
    (
        {
            children,
            className,
            color = 'default',
            disabled = false,
            type = 'button',
            variant = 'default',
            ...otherProps
        }: IconButtonProps,
        ref: ForwardedRef<HTMLButtonElement>,
    ) => (
        <MUIIconButton
            centerRipple={false}
            className={cn('IconButton', `IconButton-${variant}`, className)}
            color={color}
            disableFocusRipple={false}
            disableRipple={false}
            disableTouchRipple={false}
            disabled={disabled}
            focusRipple={false}
            ref={ref}
            type={type}
            {...otherProps}>
            {children}
        </MUIIconButton>
    ),
)

export default IconButton
