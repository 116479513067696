import cn from 'classnames'

import {SVGProps} from './props'

export const Check = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        aria-label="icon-check"
        className={cn('icon icon-check', className)}
        fill={fill}
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8 0a8 8 0 100 16A8 8 0 008 0zm-.6 12.6l-4-3L4.6 8 7 9.8l4.2-5.6 1.6 1.2-5.4 7.2z"
            fillRule="evenodd"
        />
    </svg>
)
