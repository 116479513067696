import MUIListItem, {ListItemProps as MUIListItemProps} from '@mui/material/ListItem'
import cn from 'classnames'

export type ListItemProps = MUIListItemProps

/**
 * ...
 */
export const ListItem = ({className, ...props}: ListItemProps) => (
    <MUIListItem className={cn('ListItem', className)} {...props} />
)

export default ListItem
