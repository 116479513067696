import {ConveyanceVehicleType} from '@/__generated__/types'

import {Conveyance} from './Conveyance'
import {Forklift} from './Forklift'
import {Onsite} from './Onsite'
import {ConveyanceSVGProps} from './props'
import {Rail} from './Rail'
import {Truck} from './Truck'
import {Vessel} from './Vessel'
import {Warehouse} from './Warehouse'

type Props = ConveyanceSVGProps & {
    type?: ConveyanceVehicleType
    isScheduling?: boolean
}

export const ConveyanceType = ({type, isScheduling = false, ...rest}: Props) => {
    switch (type) {
        case ConveyanceVehicleType.OnSite:
            // When is an icon in scheduling, the type returned for onsite changes
            return isScheduling ? <Forklift {...rest} /> : <Onsite {...rest} />
        case ConveyanceVehicleType.Rail:
            return <Rail {...rest} />
        case ConveyanceVehicleType.Truck:
            return <Truck {...rest} />
        case ConveyanceVehicleType.Vessel:
            return <Vessel {...rest} />
        case ConveyanceVehicleType.WarehouseTransfer:
            return <Warehouse {...rest} />
        default:
            return <Conveyance {...rest} />
    }
}
