import {forwardRef} from 'react'
import MUITableCell, {TableCellProps as MUITableCellProps} from '@mui/material/TableCell'
import cn from 'classnames'

export type TableCellProps = MUITableCellProps

/**
 * ...
 */
// eslint-disable-next-line react/display-name -- FIXME
export const TableCell = forwardRef(({className, ...props}: TableCellProps, ref) => (
    <MUITableCell className={cn('TableCell', className)} ref={ref} {...props} />
))

export default TableCell
