import cn from 'classnames'

import {SVGProps} from './props'

export const ErrorCircle = ({className}: SVGProps) => (
    <svg className={cn('icon icon-error-circle', className)} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.2 10.4h1.6V12H7.2v-1.6zm0-6.4h1.6v4.8H7.2V4zm.796-4C3.576 0 0 3.58 0 8s3.576 8 7.996 8S16 12.42 16 8s-3.584-8-8.004-8zM8 14.4c-3.536 0-6.4-2.864-6.4-6.4 0-3.536 2.864-6.4 6.4-6.4 3.536 0 6.4 2.864 6.4 6.4 0 3.536-2.864 6.4-6.4 6.4z"
            fillRule="evenodd"
        />
    </svg>
)
