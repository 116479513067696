import {useEffect, useRef} from 'react'
import {atom, SetStateAction, useAtom, WritableAtom} from 'jotai'
import {atomWithStorage} from 'jotai/utils'
import {isArray, isEmpty, isEqual, isNil, pickBy, reduce} from 'lodash-es'
import {NavigateOptions} from 'react-router'
import {URLSearchParamsInit, useSearchParams} from 'react-router-dom'

/**
 * This atom is used for page-level settings.
 *
 * Usage example:
 * ```
 * export const settingsAtom = atomWithSettings<Settings>('TM3', {
 *   unit: 'original',
 *   tradeType: 'buy',
 *   startDate: DateTime.local().startOf('month').toISODate(),
 *   endDate: DateTime.local().startOf('month').plus({months: 2}).toISODate(),
 * })
 * ```
 *
 * Caveat: if you are going to be changing anything about the settings type
 * (e.g. key names, value types), you will need to migrate older values.
 *
 * @param key the storageLocation key
 * @param initialValue any default values you want to use
 * @returns an atom that contains the page settings
 */
export const atomWithSettings = <T extends Record<string, any>>(key: string, initialValue: T) => {
    const baseAtom = atomWithStorage<T>(key, initialValue)

    return atom<T, SetStateAction<T>>(
        (get) => ({
            ...initialValue,
            ...get(baseAtom),
        }),
        (get, set, update) => {
            const nextValue = typeof update === 'function' ? update(get(baseAtom)) : update

            // remove initial values
            const diff = reduce(
                nextValue,
                (acc, value, key) => {
                    if (!isEqual(value, initialValue[key])) {
                        acc[key] = value
                    }
                    return acc
                },
                {} as Record<string, any>,
            )

            set(baseAtom, diff as T)
        },
    )
}

/**
 * This is a generic utility function that will take an object, remove any null or undefined
 * values, and splats that into the searchParams.
 *
 * @param values any `Record<string,any>` value
 * @param searchParams URLSearchParameters (probably form `useSearchParams()`)
 * @param setSearchParams A way to set search params (probably from `useSearchParams()`)
 */
function updateSearchParams<T extends Record<string, any>>(
    values: T,
    searchParams: URLSearchParams,
    setSearchParams: (p: URLSearchParamsInit, navigateOpts?: NavigateOptions) => void,
    keys: string[] = [],
) {
    const params = Object.fromEntries(searchParams.entries())

    const nextParams = reduce(
        {...params, ...values},
        (acc, value, key) => {
            if (keys.includes(key)) {
                if (isArray(value) && value.length > 0) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call -- FIXME
                    acc[key] = value.join(',')
                } else if (!isNil(value)) {
                    acc[key] = value
                }
            } else if (params[key]) {
                // unknown param is preserved
                acc[key] = params[key]
            }
            return acc
        },
        {} as Record<string, any>,
    )

    setSearchParams(nextParams)
}

/**
 * The first time the page is mounted this function runs to determine what to do.
 *
 * @param values the current settings
 * @param setSettings a function to set the settings
 * @param searchParams the current URL searchParams
 * @param setSearchParams a function to set the searchParams
 * @param keys a static list of keys to scan for
 * @param resetSettings an optional function that will reset the settings based on
 *                      the URL searchParams
 * @returns void
 */
function handlePageMount<T extends Record<string, any>>(
    values: T,
    setSettings: (p: SetStateAction<T>) => void,
    searchParams: URLSearchParams,
    setSearchParams: (p: URLSearchParamsInit, navigateOpts?: NavigateOptions) => void,
    keys: string[] = [],
    resetSettings?: ResetFunctionType<T>,
) {
    const settingKeys = Object.keys(values)
    const params = Object.fromEntries(searchParams.entries())

    // these should be params not related to settings
    const otherParams = pickBy(params, (_value, key) => !settingKeys.includes(key))

    // if the page is mounted and there with searchParams that
    // match the settings... then we should discard all the settings
    // and insert the searchParams
    const paramsThatShouldGoIntoSettings = pickBy(params, (_value, key) => settingKeys.includes(key))

    if (isEmpty(paramsThatShouldGoIntoSettings)) {
        // there were no params that matched the settings so just splat the settings...
        const settingsParams = reduce(
            values,
            (acc, value: string, key) => {
                if (keys.includes(key)) {
                    if (isArray(value) && value.length > 0) {
                        acc[key] = value.join(',')
                    } else if (!isNil(value)) {
                        acc[key] = value
                    }
                }

                return acc
            },
            {} as Record<string, string>,
        )

        const nextParams = {...otherParams, ...settingsParams}

        setSearchParams(nextParams, {replace: true})

        return
    }

    // otherwise... we need to clear the settings and put the searchParams in there
    if (resetSettings) {
        const nextSettings = resetSettings(values, params)

        if (!isEqual(nextSettings, values)) {
            setSettings((prev) => ({...prev, ...nextSettings}))
        }
    }
}

type ResetFunctionType<T> = (values: T, params: Record<string, string>) => Partial<T>

/**
 * This is an example of how you could rebuild the settings based on the searchParams.
 * This is not automatically used, because it could mess up the settings if you / the
 * developer didn't explicitly know about this.
 *
 * @param values the current settings
 * @param params the current searchParams
 * @returns a new settings object
 */
export const defaultResetSettingsBasedOnParams = <T extends Record<string, any>>(
    values: T,
    params: Record<string, string>,
) => {
    const omitKeys = ['whatever', 'you', 'want']

    const nextSettings = reduce(
        {...values},
        (acc, value, key) => {
            if (omitKeys.indexOf(key) === -1) {
                const newValue = params[key]
                if (newValue) {
                    // put this value into settings
                    if (Array.isArray(value)) {
                        acc[key] = newValue.split(',')
                    } else if (typeof value === 'string') {
                        acc[key] = newValue
                    } else if (typeof value === 'number') {
                        acc[key] = Number(newValue)
                    }
                    // what can we do about object?
                } else {
                    // clear old values
                    if (Array.isArray(value)) {
                        acc[key] = []
                    } else if (typeof value === 'string') {
                        acc[key] = ''
                    } else if (typeof value === 'number') {
                        acc[key] = 0 // ??
                    }
                    // what can we do about object?
                }
            }

            return acc
        },
        {} as Record<string, any>,
    )

    return {...values, ...nextSettings}
}

const defaultKeys: string[] = []

/**
 * When an atom changes value, splat the values into the searchParams.
 *
 * @param atom atom to get values from
 * @param keys list of keys to that we should scan for in the `searchParams`
 * @param resetSettings a function that will rebuild the settings based on the URL `searchParams`. See `defaultResetSettingsBasedOnParams` for an example.
 * @param onInitialized a function called when after the the hook has initialized
 */
export function useSettingsIntoSearchParams<T extends Record<string, any>>(
    atom: WritableAtom<T, SetStateAction<T>, void>,
    keys: string[] = defaultKeys,
    resetSettings?: ResetFunctionType<T>,
    onInitialized?: () => void,
    transformSettings?: (val: Awaited<T>) => Awaited<T>,
) {
    const firstTimeRef = useRef(true)
    const [searchParams, setSearchParams] = useSearchParams()
    const [settings, setSettings] = useAtom(atom)

    useEffect(() => {
        const currentSettings = transformSettings?.(settings) ?? settings
        if (firstTimeRef.current) {
            handlePageMount(currentSettings as T, setSettings, searchParams, setSearchParams, keys, resetSettings)
            firstTimeRef.current = false
            onInitialized?.()
        } else {
            updateSearchParams(currentSettings, searchParams, setSearchParams, keys)
        }

        // eslint-disable-next-line
    }, [settings])
}

/**
 * This is a generic utility function that will take a camel case string a convert it to snake case
 *
 * @param str camel case string
 */
export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/gu, (letter) => `_${letter.toLowerCase()}`)
