import {useMemo} from 'react'
import {Typography} from '@mui/material'
import {useIntl} from 'react-intl'

import {Button} from '@waybridge/wui/Button'
import CustomizeColumnButton from '@waybridge/wui/CustomizeColumnButton/CustomizeColumnButton'
import {ColumnState} from '@waybridge/wui/CustomizeTableColumnsDialog/types'
import {Skeleton} from '@waybridge/wui/Skeleton'
import {Stack} from '@waybridge/wui/Stack'
import TableFallback from '@waybridge/wui/TableFallback'
import TablePageContainer from '@waybridge/wui/TablePageContainer/TablePageContainer'
import WeightSelector from '@waybridge/wui/WeightSelector/WeightSelector'
import Table from '@waybridge/wui/WTable/Table'
import TableHead from '@waybridge/wui/WTable/TableHead'
import {TableStateColumn} from '@waybridge/wui/WTable/useTableState'

import {WeightQuantityUnit} from '@/hooks/useQuantityUnit'

export type TableSkeletonProps = {
    title?: {
        width?: number
        label?: string | JSX.Element
    }
    buttons?: (
        | {
              width?: number
              label: string | JSX.Element
              id?: number
          }
        | {width?: number; label?: string; id: number}
    )[]
    weightUnits?: WeightQuantityUnit[]
    search?: {
        width?: number
        label?: string
    }
    quickFilters?: (
        | {
              width?: number
              label: string | JSX.Element
              id?: number
          }
        | {width?: number; label?: string | JSX.Element; id: number}
    )[]
    headerLabels?: {
        [index: string]: string | JSX.Element
    }
    headers?: TableStateColumn[]
    defaultColumnOrder?: ColumnState[]
}

function labelAsKey(val?: string | JSX.Element): string {
    if (typeof val === 'string') {
        return val
    }

    if (val?.props && 'id' in val.props) {
        const {id} = val.props as {id: string}
        if (typeof id === 'string') {
            return id
        }
    }

    return ''
}

export const TableSkeleton = ({
    title = {width: 150},
    buttons = [
        {id: 1, width: 150},
        {id: 2, width: 150},
    ],
    quickFilters = [],
    search,
    weightUnits = [],
    headerLabels = {},
    headers,
    defaultColumnOrder = [],
}: TableSkeletonProps) => {
    const intl = useIntl()

    const columns = useMemo(
        () => [
            {
                field: 'settings',
                header: intl.formatMessage({defaultMessage: 'Settings'}),
                Header: () => <CustomizeColumnButton disabled />,
            },

            ...(defaultColumnOrder
                .filter((column) => column.enabled && column.field !== 'settings' && column.field !== 'mrt-row-expand')
                .map((column) =>
                    headers
                        ? headers.find((header) => header.field === column.field)
                        : {field: column.field, label: headerLabels[column.field]},
                )
                .filter((column) => column !== undefined) as typeof defaultColumnOrder),
        ],
        [defaultColumnOrder, headerLabels, intl, headers],
    )

    return (
        <TablePageContainer scrollBarWidth={0} sx={{pt: 4}}>
            <Stack gap={3} pb={2.5}>
                <Stack alignItems="center" flexDirection="row" justifyContent="space-between" width="100%">
                    <Stack alignItems="center" flexDirection="row" gap={4}>
                        <Skeleton sx={{width: title.width ? `${title.width}px` : undefined}} variant="text">
                            <Typography variant="h4">{title.label}</Typography>
                        </Skeleton>
                        {search ? (
                            <Skeleton sx={{width: `${title.width ?? 500}px`}} variant="rectangular" />
                        ) : undefined}
                    </Stack>

                    <Stack alignItems="center" flexDirection="row">
                        <WeightSelector allowedMeasurements={weightUnits} disabled />
                        <Stack alignItems="center" flexDirection="row" gap={2}>
                            {buttons.map((button) => (
                                <Skeleton
                                    key={`${button.id ?? ''}${labelAsKey(button.label)}`}
                                    sx={{width: button.width ? `${button.width}px` : undefined, height: '32px'}}
                                    variant="rounded">
                                    <Button>{button.label}</Button>
                                </Skeleton>
                            ))}
                        </Stack>
                    </Stack>
                </Stack>
                {quickFilters?.length ? (
                    <Stack alignItems="center" flexDirection="row" gap={2}>
                        <Typography sx={{width: '75px'}} variant="body1">
                            <Skeleton variant="text" />
                        </Typography>
                        <Stack alignItems="center" flexDirection="row" gap={2}>
                            {quickFilters.map((filter) => (
                                <Skeleton
                                    key={(filter.id ?? '') + labelAsKey(filter.label)}
                                    sx={{width: filter.width ? `${filter.width}px` : undefined, height: '32px'}}
                                    variant="rounded"
                                    width={filter.width}>
                                    <Button>{filter.label}</Button>
                                </Skeleton>
                            ))}
                        </Stack>
                    </Stack>
                ) : undefined}
                <Typography sx={{width: '100px'}} variant="body1">
                    <Skeleton variant="text" />
                </Typography>
            </Stack>

            {columns.length ? (
                <Table isZebra>
                    <TableHead columns={columns} />
                    <TableFallback columns={columns} />
                </Table>
            ) : undefined}
        </TablePageContainer>
    )
}

export default TableSkeleton
