import cn from 'classnames'

import {SVGProps} from './props'

export const Flag = ({fill, height, width, className}: SVGProps) => (
    <svg
        className={cn('icon icon-flag', className)}
        fill={fill}
        height={height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.269 4.047l-11.2-4c-.245-.088-.518-.051-.73.1C2.126.296 2 .54 2 .8V16h1.6V9.364l10.669-3.81c.318-.114.531-.416.531-.753 0-.338-.213-.64-.531-.754z"
            fillRule="evenodd"
        />
    </svg>
)
