import {createContext, useContext, useEffect, useState} from 'react'
import * as React from 'react'

export interface WindowDimensionState {
    width?: number
    height?: number
}

const WindowDimensionsCtx = createContext<Optional<WindowDimensionState>>(null)

type Props = {
    width?: number
    height?: number
    children: React.ReactNode
}

const WindowDimensionsProvider = ({children, width, height}: Props) => {
    const [dimensions, setDimensions] = useState<WindowDimensionState>({
        width: width || window.innerWidth,
        height: height || window.innerHeight,
    })

    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return <WindowDimensionsCtx.Provider value={dimensions}>{children}</WindowDimensionsCtx.Provider>
}

export default WindowDimensionsProvider
export const useWindowDimensions = () => useContext(WindowDimensionsCtx)
