import cn from 'classnames'

import {SVGProps} from './props'

export const ArrowDown = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-arrow-down', className)}
        fill={fill}
        height={height}
        viewBox="0 0 12 12"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path d="M10.198 4.198L7.275 7.12 7.275 0 4.725 0 4.725 7.12 1.802 4.198 0 6 6 12 12 6z" fillRule="evenodd" />
    </svg>
)

export default ArrowDown
