import {ReactNode, useRef} from 'react'
import {RelayEnvironmentProvider} from 'react-relay/hooks'
import {
    authMiddleware,
    errorMiddleware,
    loggerMiddleware,
    perfMiddleware,
    RelayNetworkLayer,
    RelayRequestAny,
    retryMiddleware,
    urlMiddleware,
} from 'react-relay-network-modern'
import {Environment, RecordSource, Store} from 'relay-runtime'

import {useAuth} from '@/providers/AuthProvider'
import {configValueNumber} from '@/utils/config'

const debugRelay = process.env.DEBUG_RELAY

const fetchTimeout = configValueNumber('API_TIMEOUT', 300000)

export const RelayProvider = ({children}: {children?: ReactNode}) => {
    const auth = useAuth()

    const env = useRef(
        new Environment({
            network: new RelayNetworkLayer(
                [
                    urlMiddleware({
                        url: () => Promise.resolve(`${auth.rootURL}/api/v1/graphql`),
                        headers: () => auth.getHeaders(),
                    }),
                    retryMiddleware({
                        fetchTimeout,
                        // Don't retry on 403 errors -- we handle that separately
                        statusCodes: (statusCode) => (statusCode < 200 || statusCode > 300) && statusCode !== 403,
                    }),
                    authMiddleware({
                        allowEmptyToken: true,
                        token: () => auth.getTokens().access,
                        tokenRefreshPromise: () => auth.refreshToken(),
                    }),
                    (next) => (req: RelayRequestAny) => {
                        req.fetchOpts.credentials = 'include'
                        return next(req)
                    },
                    debugRelay ? loggerMiddleware() : null,
                    debugRelay ? errorMiddleware() : null,
                    debugRelay ? perfMiddleware() : null,
                ],
                {noThrow: true},
            ),
            store: new Store(new RecordSource({}), {
                queryCacheExpirationTime: 1000 * 60 * 10, // 10 minutes,
            }),
        }),
    )

    return <RelayEnvironmentProvider environment={env.current}>{children}</RelayEnvironmentProvider>
}

export default RelayProvider
