import {atom} from 'jotai'
import {atomWithStorage} from 'jotai/utils'

export const showFlagsPanelAtom = atom<boolean>(false)

// This file contains a list of user flags that are used to determine which
// features should be displayed to a user. The flags are stored in localStorage
// and are read from there when the page is loaded. The used flags are stored
// in a separate list, which is used to track which flags are being used and
// which are not.
export const flagsAtom = atomWithStorage<Record<string, any>>('userFlags', {})
export const usedFlagsAtom = atom<string[]>([])
export const updateUsedFlagsAtom = atom(null, (get, set, flag: string) => {
    if (!get(usedFlagsAtom).includes(flag)) {
        set(usedFlagsAtom, [...get(usedFlagsAtom), flag])
    }
})

// flagName -> ['api', 'ui']
export const flagLdStatistics = atom<Record<string, string[]>>({})
