import Fallback from '@waybridge/wui/Fallback'
import Stack from '@waybridge/wui/Stack'
import ThemeProvider from '@waybridge/wui/ThemeProvider'

const Loading = () => (
    <ThemeProvider>
        <Stack justifyContent="center" sx={{minHeight: '100vh'}}>
            <Fallback />
        </Stack>
    </ThemeProvider>
)

export default Loading
