import {FormattedMessage} from 'react-intl'

import Button from '@waybridge/wui/Button'
import FullPage from '@waybridge/wui/FullPage'
import {WarningIcon} from '@waybridge/wui/Icons'
import Stack from '@waybridge/wui/Stack'
import Typography from '@waybridge/wui/Typography'

export const ErrorMessage = () => (
    <Stack direction="row" gap={3}>
        <div>
            <WarningIcon fontSize="extra-large" sx={{color: 'yellow50'}} />
        </div>
        <div style={{maxWidth: '450px'}}>
            <Stack direction="column" gap={3}>
                <Typography display="block" fontWeight="bold" variant="h4">
                    <FormattedMessage defaultMessage="An error occurred." />
                </Typography>
                <Typography display="block" variant="body1">
                    <FormattedMessage defaultMessage="Refresh this page to try again." />
                </Typography>
                <Typography display="block" variant="body1">
                    <FormattedMessage defaultMessage="Reach out to support if you keep experiencing the same error." />
                </Typography>
                <Stack direction="row" gap={1}>
                    <Button href="mailto:support@minehub.com" variant="outlined">
                        <FormattedMessage defaultMessage="Contact support" />
                    </Button>
                    <Button onClick={() => window.location.reload()} variant="contained">
                        <FormattedMessage defaultMessage="Refresh" />
                    </Button>
                </Stack>
            </Stack>
        </div>
    </Stack>
)

const ErrorPage = () => (
    <div style={{display: 'flex', height: 'calc(100vh - 56px)', background: 'white'}}>
        <div
            style={{
                flex: 1,
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <FullPage>
                <ErrorMessage />
            </FullPage>
        </div>
    </div>
)

export default ErrorPage
