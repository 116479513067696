import {Components, Theme} from '@mui/material'
import {ComponentsOverrides} from '@mui/material/styles'

// See also MuiPickersDay for the Calendar's content
export const MuiCalendarOrClockPicker: Components<Theme>['MuiCalendarOrClockPicker'] = {
    defaultProps: {},
    styleOverrides: {
        root: () => ({
            ['> div']: {
                maxHeight: '360px',
            },
            maxHeight: '360px',
        }),
    } as ComponentsOverrides<Theme>['MuiCalendarOrClockPicker'],
}

export default MuiCalendarOrClockPicker
