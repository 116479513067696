import {isNil} from 'lodash-es'

import Box from '@waybridge/wui/Box'
import RouterLink from '@waybridge/wui/RouterLink'
import Suspense from '@waybridge/wui/Suspense'

import {useAuth} from '@/providers/AuthProvider'
import {usePermissions} from '@/providers/PermissionAndNavProvider'

import {Nav_data$data} from '@/__generated__/Nav_data.graphql'

import CompanyName from './CompanyName'
import Menu from './Menu'
import MobileDrawer from './MobileDrawer'
import Logo from './ThemeLogo'

type MobileNavProps = {
    data: Nav_data$data
}

const MobileNav = ({data}: MobileNavProps) => {
    const auth = useAuth()
    const {orgName} = usePermissions()
    // eslint-disable-next-line no-implicit-coercion -- FIXME
    const isSwitched = !!auth.effectiveOrganization

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment -- FIXME
        <>
            <nav>
                <Box
                    className="Nav Nav-Mobile"
                    sx={(theme) => ({
                        backgroundColor: theme.palette.black,
                        ...(isSwitched && {
                            backgroundColor: theme.palette.primary[800],
                        }),
                    })}>
                    <div className="Nav-left">
                        <Suspense>
                            <MobileDrawer />
                        </Suspense>
                    </div>
                    <div className="Nav-center">
                        <RouterLink className="logo" to="/">
                            <Logo />
                        </RouterLink>
                        <CompanyName>{orgName}</CompanyName>
                    </div>
                    {/* eslint-disable-next-line react/jsx-no-leaked-render -- FIXME */}
                    <div className="Nav-right">{data && <Menu data={data} />}</div>
                </Box>
            </nav>
        </>
    )
}

type MobileNavDeciderProps = {
    data?: Nav_data$data | null
}

const MobileNavDecider = ({data}: MobileNavDeciderProps) => {
    if (isNil(data)) {
        return (
            <nav>
                <Box
                    className="Nav Nav-Mobile"
                    sx={{
                        backgroundColor: (theme) => theme.palette.black,
                    }}>
                    {/* eslint-disable-next-line react/self-closing-comp -- FIXME */}
                    <div className="Nav-left"></div>
                    <div className="Nav-center">
                        <RouterLink className="logo" to="/">
                            <Logo />
                        </RouterLink>
                    </div>
                    {/* eslint-disable-next-line react/self-closing-comp -- FIXME */}
                    <div className="Nav-right"></div>
                </Box>
            </nav>
        )
    }

    return <MobileNav data={data} />
}

export default MobileNavDecider
