import cn from 'classnames'

import {SVGProps} from './props'

export const Search = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-search', className)}
        fill={fill}
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16 14.178l-4.185-4.185a6.397 6.397 0 001.074-3.549A6.452 6.452 0 006.444 0 6.452 6.452 0 000 6.444a6.452 6.452 0 006.444 6.445c1.312 0 2.53-.399 3.549-1.074L14.178 16 16 14.178zM2.578 6.444a3.871 3.871 0 013.866-3.866 3.871 3.871 0 013.867 3.866 3.871 3.871 0 01-3.867 3.867 3.871 3.871 0 01-3.866-3.867z"
            fillRule="evenodd"
        />
    </svg>
)
