import React from 'react'
import type {} from '@mui/lab/themeAugmentation'
import {ThemeProvider as MUIThemeProvider} from '@mui/material/styles'
import {LocalizationProvider} from '@mui/x-date-pickers'
import type {} from '@mui/x-date-pickers/themeAugmentation'

import {CustomLuxonUtils} from '@waybridge/wui/ThemeProvider/CustomLuxonUtils'
import theme from '@waybridge/wui/ThemeProvider/minehub-theme/theme'

export const ThemeProvider = ({children}: {children: React.ReactNode}) => (
    <MUIThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={CustomLuxonUtils}>{children}</LocalizationProvider>
    </MUIThemeProvider>
)

export default ThemeProvider
