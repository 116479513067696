import {ComponentsOverrides, Theme} from '@mui/material/styles'

export const MuiTab = {
    styleOverrides: {
        root: ({theme}) => ({
            color: theme.palette.grey70,
            textTransform: 'none',
            transition: 'all 100ms ease',
            '&.Mui-selected': {
                color: theme.palette.black,
                fontWeight: 'bold',
            },
        }),
    } as ComponentsOverrides<Theme>['MuiTab'],
}

export default MuiTab
