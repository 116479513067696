import {Suspense} from 'react'

import TableSkeleton, {TableSkeletonProps} from '@waybridge/wui/TableSkeleton/TableSkeleton'

type TablePageSuspenseProps = {
    children: JSX.Element
} & TableSkeletonProps

export const TablePageSuspense = ({children, ...props}: TablePageSuspenseProps) => (
    <Suspense fallback={<TableSkeleton {...props} />}>{children}</Suspense>
)

export default TablePageSuspense
