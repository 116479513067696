import {lazy} from 'react'
import {FormattedMessage} from 'react-intl'

import TablePageSuspense from '@waybridge/wui/TablePageSuspense/TablePageSuspense'

import definedMessagesToHeaderLabels from '@/hooks/definedMessagesToHeaderLabels'
import {WeightQuantityUnit} from '@/hooks/useQuantityUnit'

import messages from './messages'
import {defaultColumnOrder} from './types'

const Shipments = lazy(() => import('./Shipments'))

export const ShipmentsSuspenseProps = {
    buttons: [
        {label: <FormattedMessage defaultMessage="Export" />},
        {label: <FormattedMessage defaultMessage="Add shipments" />},
    ],
    defaultColumnOrder: defaultColumnOrder,
    headerLabels: definedMessagesToHeaderLabels(messages),
    quickFilters: [
        {label: <FormattedMessage defaultMessage="In transit shipments" />},
        {label: <FormattedMessage defaultMessage="Late aggainst ETA" />},
    ],
    search: {width: 400},
    title: {label: <FormattedMessage defaultMessage="Shipments" />},
    weightUnits: [WeightQuantityUnit.Lb, WeightQuantityUnit.Mt, WeightQuantityUnit.Original],
}

export const LazyShipments = () => (
    <TablePageSuspense {...ShipmentsSuspenseProps}>
        <Shipments />
    </TablePageSuspense>
)
