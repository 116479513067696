import cn from 'classnames'

import {SVGProps} from './props'

export const Eye = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-eye', className)}
        fill={fill}
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8 3C4.364 3 1.258 5.262 0 8.455c1.258 3.192 4.364 5.454 8 5.454s6.742-2.262 8-5.454C14.742 5.262 11.636 3 8 3zm0 9.09c-2.007 0-3.636-1.628-3.636-3.635 0-2.008 1.629-3.637 3.636-3.637s3.636 1.63 3.636 3.637S10.007 12.09 8 12.09zm0-5.817c-1.207 0-2.182.974-2.182 2.182 0 1.207.975 2.181 2.182 2.181 1.207 0 2.182-.974 2.182-2.181 0-1.208-.975-2.182-2.182-2.182z"
            fillRule="evenodd"
        />
    </svg>
)
