import {forwardRef} from 'react'
import MUIFade, {FadeProps as MUIFadeProps} from '@mui/material/Fade'

export type FadeProps = MUIFadeProps

/**
 * Fade in from transparent to opaque.
 */
const Fade = ({...props}: FadeProps, ref?: React.Ref<unknown>) => (
    <MUIFade aria-hidden={!props.in} ref={ref} {...props} />
)

export default forwardRef(Fade)
