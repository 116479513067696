import {Components} from '@mui/material'
import {ComponentsOverrides} from '@mui/material/styles'

export const MuiSwitch: Components['MuiSwitch'] = {
    defaultProps: {
        color: 'secondary',
    },
    styleOverrides: {
        root: {
            /**
             * The input switch wasn't clickable because the clickable area is misplaced
             * outside of the actual element
             */
            '& .MuiSwitch-switchBase input': {
                left: 0,
                height: '100%',
            },
        },
    } as ComponentsOverrides['MuiSwitch'],
}

export default MuiSwitch
