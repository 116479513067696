import {FormattedMessage} from 'react-intl'

import {InventorySearchTableIdEnum} from './types'

export const inventorySearchHeaderLabels = {
    [InventorySearchTableIdEnum.bol]: <FormattedMessage defaultMessage="BOL" />,
    [InventorySearchTableIdEnum.inboundId]: <FormattedMessage defaultMessage="Inbound ID" />,
    [InventorySearchTableIdEnum.outboundId]: <FormattedMessage defaultMessage="Outbound ID" />,
    [InventorySearchTableIdEnum.batchId]: <FormattedMessage defaultMessage="Batch ID" />,
    [InventorySearchTableIdEnum.material]: <FormattedMessage defaultMessage="Material" />,
    [InventorySearchTableIdEnum.quantity]: <FormattedMessage defaultMessage="Quantity" />,
    [InventorySearchTableIdEnum.account]: <FormattedMessage defaultMessage="Account" />,
    [InventorySearchTableIdEnum.location]: <FormattedMessage defaultMessage="Location" />,
    [InventorySearchTableIdEnum.materialOwner]: <FormattedMessage defaultMessage="Material Owner" />,
    [InventorySearchTableIdEnum.poNumber]: <FormattedMessage defaultMessage="PO #" />,
}
