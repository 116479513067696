import * as Sentry from '@sentry/browser'
import {Integrations} from '@sentry/tracing'

import {configValue, configValueBool} from '@/utils/config'

const isSentryEnabled = configValueBool('SENTRY')
export function startSentry() {
    if (!isSentryEnabled) {
        return
    }

    Sentry.init({
        dsn: 'https://af2aed0b1ced4ef5844ad9be151fd8cd@o305045.ingest.sentry.io/5218394',
        release: `ui@${process.env.GIT_COMMIT}`, // Don't pull from window.  Build time object
        environment: configValue('ENVIRONMENT'),
        integrations: [new Integrations.BrowserTracing()],
        // 1.0 would send 100% of transactions
        tracesSampleRate: 0.2,
        ignoreErrors: [
            // this is a benign message that is issued when a render
            // cycle took longer than the duration of single animation frame.
            'ResizeObserver loop limit exceeded',
            // this does not seem to be real and works moments later, so
            // the user never actually sees any issues
            'LaunchDarklyFlagFetchError',
            // this error happens when the web app tries to load a chunk that is no longer available.
            // typically this means we deployed a new version of the UI and the user needs to refresh.
            'ChunkLoadError',
            // this error happens when the user's session has expired but they tried to fetch some
            // data. after this error, our auth middleware will get a new token and try again.
            'RRNLRetryMiddlewareError',
            // LaunchDarkly (or another external library) is adding a handler to a button, but
            // when the event runs the button is no longer in the DOM.
            "Cannot read properties of null (reading 'tagName')",
        ],
    })
}
