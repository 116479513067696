import {SxProps, Theme} from '@mui/system'
import {first, toUpper} from 'lodash-es'
import {useFragment} from 'react-relay/hooks'

import {useTheme} from '@waybridge/wui'
import Avatar from '@waybridge/wui/Avatar'

import type {UserAvatar_user$key} from '@/__generated__/UserAvatar_user.graphql'

import {userAvatarFragment} from './UserAvatar.queries'

type Props = {
    /** fragment key */
    user: UserAvatar_user$key
    /** The size of the photo. Small = 24x24, Medium = 32x32 */
    size?: 'small' | 'medium' | 'large'

    sx?: SxProps<Theme>
    style?: React.CSSProperties
}

/**
 * Avatar for a user in the Waybridge system.
 */
const UserAvatar = ({user, size = 'large', sx: _sx, style}: Props) => {
    const data = useFragment<UserAvatar_user$key>(userAvatarFragment, user)
    const theme = useTheme()
    const ourSx = {
        width: theme.typography[`${size}-icons`].fontSize,
        height: theme.typography[`${size}-icons`].fontSize,
    }

    const sx = {..._sx, ...ourSx}

    // just blank if data isn't ready
    if (!data) {
        return <Avatar alt="U" style={style} sx={sx} />
    }

    const name = data.firstName ? toUpper(first(data.firstName)) : 'U'

    return <Avatar alt={name} src={data.photo ?? undefined} style={style} sx={sx} />
}

export default UserAvatar
