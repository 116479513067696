import {isEmpty, isNil, map, range} from 'lodash-es'
import {DateTime, Interval} from 'luxon'
import {IntlShape} from 'react-intl'

import {formatDate} from '@/utils/formatting'

// Luxon DateTime formats
export const DateTimeFormats = Object.freeze({
    TIME_SIMPLE: 'h:mm a',
    TIME_SIMPLE_24_HOUR: 'h:mm',
    TIME_SIMPLE_WITH_TZ: 'h:mm a ZZZZ',
    DATE_FULL: 'MMMM dd, yyyy',
    MONTH_DAY_SHORT: 'MMM d',
    MONTH_DAY2_SHORT: 'MMM dd',
    MONTH_DAY2_YEAR_SHORT: 'MMM dd, yyyy',
    MONTH_YEAR_SHORT: 'MMM yyyy',
    MONTH_YEAR_FULL: 'MMMM yyyy',
    MONTH_SHORT: 'MMM',
    MONTH_FULL: 'MMMM',
    MONTH_DAY_WEEKDAY_SHORT: 'ccc, MMM d',
})

/**
 * @param intl IntlShape
 * @param date the date object to use
 */
export const buildDayOption = (intl: IntlShape, d: DateTime) => ({
    key: d.toISODate(),
    value: d.toISODate(),
    label: formatDate(intl, d.toJSDate(), true, true),
})

/**
 *
 * @param intl IntlShape
 * @param date start of first month, in ISO format (e.g. 2020-10-12)
 * @param start range start
 * @param end range end
 */
export const buildDayOptions = (intl: IntlShape, date: string, start = 1, end = 7) => {
    const first = DateTime.fromISO(date)

    return map(range(start, end), (i) => {
        const d = first.plus({day: i})
        return buildDayOption(intl, d)
    })
}

/**
 * Is this a weekend or in the holidays list?
 * @param day javascript date object
 * @param holidays array of strings in ISO format
 */
export const isBusinessDay = (day: Date, holidays: readonly string[]) => {
    const date = DateTime.fromJSDate(day)
    if ((date.weekday + 6) % 7 >= 5) {
        return false
    }
    if (!holidays) {
        return true
    }
    const strDate = date.toISODate()
    return holidays.find((holiday) => holiday === strDate) === undefined
}

/**
 * Checks whether or not a given date is in the future (or past)
 * This considers the current day as a future date.
 */
export const isInThePast = (a: DateTime): boolean => a < DateTime.local().startOf('day')

export const IntervalUtil = Object.freeze({
    splitByDay: (interval: Interval): Array<DateTime> => {
        const out: Array<DateTime> = []

        for (let cur: DateTime = interval.start; interval.end.diff(cur, 'days').days >= 0; cur = cur.plus({days: 1})) {
            out.push(cur)
        }

        return out
    },
    splitByMonth: (interval: Interval): Array<Interval> => {
        const splits: Array<DateTime> = []

        for (let cur: DateTime = interval.start; cur < interval.end; cur = cur.plus({months: 1})) {
            splits.push(cur.endOf('month'))
        }

        return interval.splitAt(...splits).map((i: Interval, idx: number) => {
            if (idx === 0) {
                return i
            }

            return Interval.fromDateTimes(i.start.plus({days: 1}).startOf('day'), i.end)
        })
    },
})

export const asInterval = ({startDate, endDate}: {startDate: string; endDate: string}): Interval =>
    Interval.fromDateTimes(DateTime.fromISO(startDate), DateTime.fromISO(endDate))

export const formatInterval = (interval: Interval, long?: boolean): string | null => {
    if (!interval.start || !interval.end || !interval.start.isValid || !interval.end.isValid) {
        return null
    }

    const spansEntireMonths: boolean = interval.start?.day === 1 && interval.end?.day === interval.end.daysInMonth
    const isEntireSingleMonth: boolean = interval.start?.hasSame(interval.end, 'month') && spansEntireMonths
    const isSingleDay: boolean = interval.count('days') === 1

    const dayFormat = long ? DateTimeFormats.DATE_FULL : DateTimeFormats.MONTH_DAY_SHORT
    const monthFormat = long ? DateTimeFormats.MONTH_YEAR_FULL : DateTimeFormats.MONTH_YEAR_SHORT

    if (isSingleDay) {
        return interval.start.toFormat(dayFormat)
    } else if (isEntireSingleMonth) {
        return interval.start.toFormat(monthFormat)
    } else if (spansEntireMonths) {
        return interval.toFormat(monthFormat)
    }

    return interval.toFormat(dayFormat)
}

export type DateInfo = {
    isRange: boolean
    isIncludeDate: boolean
    sameMonth: boolean
}

/**
 * Determine whether a date range spans different months
 * @param startDate start date in DateTime format
 * @param endDate end date in DateTime format
 * @return boolean
 */
export const isMultiMonthRange = (startDate?: DateTime, endDate?: DateTime): boolean => {
    const sameMonth = endDate?.month === startDate?.month && endDate?.year === startDate?.year

    return !sameMonth
}

/**
 * Determine whether two dates are a range and if they are non-month (aka include date)
 * @param startDate start date in ISO format
 * @param endDate end date in ISO format
 * @param singleMonthIsRange override and treat a single month as a range
 * @return DateInfo
 */
export const compareDates = (startDate?: string, endDate?: string, singleMonthIsRange = false): DateInfo => {
    if (!startDate && !endDate) {
        return {isRange: false, isIncludeDate: false, sameMonth: false}
    }
    const start: DateTime | undefined = startDate ? DateTime.fromISO(startDate) : undefined
    const end: DateTime | undefined = endDate ? DateTime.fromISO(endDate) : undefined

    const startsOnTheFirst: boolean = start ? start.day === 1 : true
    const endsOnTheLast: boolean = end ? end.day === end.daysInMonth : true
    const sameMonth: boolean = !isMultiMonthRange(start, end)
    const startsOnEnd: boolean = start?.toMillis() === end?.toMillis()
    const isSingleMonth: boolean = sameMonth && startsOnTheFirst && endsOnTheLast

    return {
        isRange: (!isSingleMonth || singleMonthIsRange) && !startsOnEnd,
        isIncludeDate: !startsOnTheFirst || !endsOnTheLast,
        sameMonth: sameMonth,
    }
}

const weekdays: Readonly<Record<string, number>> = Object.freeze({
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
})

export const getWeekdayIndex = (name: string): number => {
    const index: number | undefined = weekdays[name.toLowerCase()]
    if (isNil(index)) {
        throw new Error(`invalid weekday ${name}`)
    }

    return index
}

export enum DatePickerType {
    Month = 'MONTH',
    MonthRange = 'MONTH_RANGE',
    Date = 'DATE',
    DateRange = 'DATE_RANGE',
}

export const getDatePickerTypeFromDates = (filterDates?: string[]): DatePickerType => {
    let isMonthRange = false
    let isSingleMonth = false
    let isSingleDay = false
    if (filterDates && !isEmpty(filterDates)) {
        const isoStart = DateTime.fromISO(filterDates[0])
        const isoEnd = DateTime.fromISO(filterDates[1])
        isSingleMonth =
            isoStart.startOf('month').toFormat('yyyy-MM-dd') === isoStart.toFormat('yyyy-MM-dd') &&
            isoStart.endOf('month').toFormat('yyyy-MM-dd') === isoEnd.toFormat('yyyy-MM-dd')
        isMonthRange =
            isoStart.startOf('month').toFormat('yyyy-MM-dd') === isoStart.toFormat('yyyy-MM-dd') &&
            isoEnd.endOf('month').toFormat('yyyy-MM-dd') === isoEnd.toFormat('yyyy-MM-dd')
        isSingleDay = filterDates && filterDates[0] === filterDates[1]
    }
    // default to whichever filter type best displays the existing filter data
    // if there's no existing filter data default to date range because it is the most specific
    return isSingleDay
        ? DatePickerType.Date
        : isSingleMonth
        ? DatePickerType.Month
        : isMonthRange
        ? DatePickerType.MonthRange
        : DatePickerType.DateRange
}
