import {forwardRef, RefObject, useEffect, useState} from 'react'

import {styled} from '@waybridge/wui'
import {NavLink, NavLinkProps} from '@waybridge/wui/RouterLink'
import Stack from '@waybridge/wui/Stack'

import {SubNavItemType} from '@/components/Nav/types'

const SubNavLink = styled(({...props}: NavLinkProps) => (
    <NavLink {...props} role="tab" underline="none" variant="header" />
))(() => ({
    lineHeight: '1',
    display: 'flex',
    alignItems: 'center',
    borderBottom: `3px solid transparent`,
}))

type SubNavListProps = {
    items: SubNavItemType[]
    navRef: RefObject<HTMLAnchorElement | HTMLDivElement>
    style?: React.CSSProperties
}

// eslint-disable-next-line react/display-name -- FIXME
const SubNavList = forwardRef<HTMLDivElement, SubNavListProps>(({items, navRef, style}: SubNavListProps, ref) => {
    const [marginLeft, setMarginLeft] = useState(0)

    useEffect(() => {
        if (navRef.current) {
            const rect = navRef.current.getBoundingClientRect()
            if (rect) {
                // find the left side of the nav item & remove the 40px of padding
                // and place our items there.
                // if we ever want to add subItems to the last few Nav items,
                // we'll have to revisit what to do.
                // (no one really liked centering the sub items under the nav item,
                // but maybe we'll reevaluate that)
                setMarginLeft(rect.left - 56)
            }
        }
    }, [navRef, items])

    return (
        <Stack
            display="flex"
            flexDirection={{xs: 'column', lg: 'row'}}
            gap={{xs: 0, lg: 2}}
            ref={ref}
            style={{marginLeft, ...style, height: '100%'}}>
            {items.map((item) => (
                <SubNavLink key={item.to} mx={{sm: 2, md: 2}} py={{sm: 1.5, md: 1}} to={item.to}>
                    {item.label}
                </SubNavLink>
            ))}
        </Stack>
    )
})
export default SubNavList
