import {useMemo} from 'react'
import {Theme} from '@mui/material'
import {SxProps} from '@mui/system'

import {FormControlLabel} from '@waybridge/wui/FormControlLabel'
import {Radio} from '@waybridge/wui/Radio'
import {RadioGroup} from '@waybridge/wui/RadioGroup'

import {useQuantityUnitEditable, WeightQuantityUnit} from '@/hooks/useQuantityUnit'

const WeightSelector = ({
    sx,
    allowedMeasurements = [WeightQuantityUnit.Lb, WeightQuantityUnit.Mt],
    disabled,
}: {
    sx?: SxProps<Theme>
    allowedMeasurements?: WeightQuantityUnit[]
    disabled?: boolean
}) => {
    const {quantityUnit, setQuantityUnit} = useQuantityUnitEditable({
        allowedMeasurements,
    })

    const sortedMeasurements = useMemo(() => {
        const sorted = allowedMeasurements.filter((val) => val !== WeightQuantityUnit.Original).sort()

        if (allowedMeasurements.includes(WeightQuantityUnit.Original)) {
            sorted.push(WeightQuantityUnit.Original)
        }

        return sorted
    }, [allowedMeasurements])

    return (
        <RadioGroup
            aria-label="position"
            defaultValue="top"
            name="position"
            onChange={(event) => setQuantityUnit(event.target.value)}
            row
            sx={{width: 75 * sortedMeasurements.length + 'px', ...sx}}>
            {sortedMeasurements.map((val) => (
                <FormControlLabel
                    control={<Radio checked={val === quantityUnit} sx={{pl: 2}} value={val} />}
                    disabled={disabled}
                    key={val}
                    label={val.toLowerCase()}
                />
            ))}
        </RadioGroup>
    )
}

export default WeightSelector
