import {useMemo} from 'react'
import {range} from 'lodash-es'

import Skeleton from '@waybridge/wui/Skeleton'
import Stack from '@waybridge/wui/Stack'
import TableBody from '@waybridge/wui/WTable/TableBody'
import TableCell from '@waybridge/wui/WTable/TableCell'
import TableRow from '@waybridge/wui/WTable/TableRow'

type Column = {
    field: string
    colSpan?: number
}

type TableFallbackProps = {
    columns: Column[]
}

export const TableFallback = ({columns}: TableFallbackProps) => {
    const processedColumns = useMemo(
        () =>
            columns.reduce<Column[]>((acc, column, index) => {
                if ('enabled' in column && !column.enabled) {
                    return acc
                }
                if (column.field === 'settings') {
                    if (index + 1 < columns.length) {
                        acc.push({...columns[index + 1], colSpan: 2})
                    }
                    return acc
                }
                if (index > 0 && columns[index - 1].field === 'settings') {
                    return acc
                }
                acc.push(column)
                return acc
            }, []),
        [columns],
    )

    const renderCell = (column: Column, index: number) => {
        const props = {
            key: column.field,
            colSpan: column.colSpan || 1,
            style: index === 0 ? {paddingLeft: '7px'} : {},
        }

        switch (column.field) {
            case 'counterparty':
                return (
                    <TableCell {...props}>
                        <Stack direction="row" gap={1}>
                            <Skeleton height={24} variant="circular" width={24} />
                            <Skeleton style={{flex: '1'}} />
                        </Stack>
                    </TableCell>
                )
            default:
                return (
                    <TableCell {...props}>
                        <Skeleton />
                    </TableCell>
                )
        }
    }

    return (
        <TableBody>
            {range(5).map((number) => (
                <TableRow isZebra key={number}>
                    {processedColumns.map((column, index) => renderCell(column, index))}
                </TableRow>
            ))}
        </TableBody>
    )
}

export default TableFallback
