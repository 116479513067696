import {defineMessages} from 'react-intl'

import {Field} from './types'

export const columnTitles = defineMessages({
    [Field.counterparty]: {
        defaultMessage: 'Counterparty',
    },
    [Field.tradePk]: {
        defaultMessage: 'Trade ID',
    },
    [Field.documentId]: {
        defaultMessage: 'ID',
    },
    [Field.documentType]: {
        defaultMessage: 'Type',
    },
    [Field.currency]: {
        defaultMessage: 'Currency',
    },
    [Field.balance]: {
        defaultMessage: 'Balance',
    },
    [Field.invoiceValue]: {
        defaultMessage: 'Original Value',
    },
    [Field.quotaMonth]: {
        defaultMessage: 'Quota Month',
    },
    [Field.issueDate]: {
        defaultMessage: 'Issue Date',
    },
    [Field.dueDate]: {
        defaultMessage: 'Due Date',
    },
    [Field.po]: {
        defaultMessage: 'PO ref.',
    },
    [Field.shipmentRef]: {
        defaultMessage: 'Shipment ref.',
    },
    buyerName: {
        defaultMessage: 'Buyer',
    },
    sellerName: {
        defaultMessage: 'Seller',
    },
})
