import {SortDirection} from '@waybridge/wui/WTable/TableHeader'

import {ShipmentsList$data} from '@/__generated__/ShipmentsList.graphql'
import type {ShipmentsListFiltersQuery$data} from '@/__generated__/ShipmentsListFiltersQuery.graphql'
import {ShipmentsListGetProductsQuery$data} from '@/__generated__/ShipmentsListGetProductsQuery.graphql'

export type {ERPPurchaseOrderType} from '@/__generated__/ShipmentsList.graphql'
export type {ReceivingStatus} from '@/__generated__/ShipmentsListFiltersQuery.graphql'
export type {ConveyanceVehicleType} from '@/__generated__/ShipmentsListPaginatedQuery.graphql'
export {OrderingDirection} from '@/__generated__/types'

type ShipmentsListList = ShipmentsList$data['shipmentsList']
export type Shipment = NonNullable<NonNullable<ShipmentsListList>['edges'][0]>['node']

export type LocationOption = {
    name: string
    value: string
}

export type Option = {
    readonly pk: number
    readonly name: string
}

export type ProductSpecificationOptionType = NonNullable<
    NonNullable<
        NonNullable<ShipmentsListFiltersQuery$data['shipmentsList']>['summary']
    >['productSpecificationOptions'][0]
>

export interface ColumnState {
    field: Field
    enabled: boolean
}

// Hey! Psssst! Be very careful changing anything about this enum.
// These strings are persisted to localStorage and if you change them
// or remove fields or add fields... we need to successfully migrate
// any stored settings
export enum Field {
    actions = 'actions',
    appointment = 'appointment',
    arrivalDate = 'arrivalDate',
    bol = 'bol',
    bolWeight = 'bolWeight',
    bundles = 'bundles',
    carrier = 'carrier',
    co2e = 'co2e',
    conveyance = 'conveyance',
    conveyanceDetails = 'conveyanceDetails',
    createdAt = 'createdAt',
    currentLocation = 'currentLocation',
    destination = 'destination',
    documents = 'documents',
    hasExceptions = 'hasExceptions',
    id = 'id',
    inboundBatchId = 'inboundBatchId',
    inboundDeliveryId = 'inboundDeliveryId',
    incoterm = 'incoterm',
    material = 'material',
    order = 'order',
    orderType = 'orderType',
    origin = 'origin',
    outboundBatchId = 'outboundBatchId',
    outboundDeliveryId = 'outboundDeliveryId',
    trackingEta = 'trackingEta',
    productName = 'productName',
    productSpecs = 'productSpecs',
    quotaMonth = 'quotaMonth',
    receiveDate = 'receiveDate',
    releaseDate = 'releaseDate',
    releaseNumbers = 'releaseNumbers',
    scaleNet = 'scaleNet',
    seal = 'seal',
    seller = 'seller',
    shipDate = 'shipDate',
    shipper = 'shipper',
    status = 'status',
    storageLocation = 'storageLocation',
    submittedAtDate = 'submittedAtDate',
    toConsignee = 'toConsignee',
    tradeId = 'tradeId',
    updatedAt = 'updatedAt',
    waybridgeEta = 'waybridgeEta',
    predictiveETA = 'predictiveETA',
    weightDiff = 'weightDiff',
    weightDiffPercent = 'weightDiffPercent',
    internalProductReference = 'internalProductReference',
}

export type Unit = 'mt' | 'lb' | 'original'
export type NumberRange = [number, number, string | null]

// Hey! Psssst! Be very careful changing anything about this enum.
// If you add or remove something here, make sure to update `deriveSettingsFromParams`
// and `settingsKeys`
export interface Settings {
    // settings modal
    unit: Unit

    // customized column order and visibility
    columns: ColumnState[]

    // table info
    sortField: Field
    sortDirection: SortDirection
}

export const defaultColumnOrder = [
    {field: Field.actions, enabled: true},
    {field: Field.bol, enabled: true},
    {field: Field.status, enabled: true},
    {field: Field.conveyance, enabled: true},
    {field: Field.conveyanceDetails, enabled: true},
    {field: Field.origin, enabled: true},
    {field: Field.destination, enabled: true},
    {field: Field.shipDate, enabled: true},
    {field: Field.trackingEta, enabled: true},
    {field: Field.waybridgeEta, enabled: true},
    {field: Field.arrivalDate, enabled: true},
    {field: Field.productName, enabled: true},
    {field: Field.bolWeight, enabled: true},
    {field: Field.co2e, enabled: true},
    {field: Field.shipper, enabled: true},
    {field: Field.seller, enabled: true},
    {field: Field.toConsignee, enabled: true},
    {field: Field.id, enabled: false},
    {field: Field.internalProductReference, enabled: false},
    {field: Field.releaseDate, enabled: false},
    {field: Field.releaseNumbers, enabled: false},
    {field: Field.productSpecs, enabled: false},
    {field: Field.currentLocation, enabled: false},
    {field: Field.receiveDate, enabled: false},
    {field: Field.appointment, enabled: false},
    {field: Field.inboundDeliveryId, enabled: false},
    {field: Field.outboundDeliveryId, enabled: false},
    {field: Field.carrier, enabled: false},
    {field: Field.seal, enabled: false},
    {field: Field.material, enabled: false},
    {field: Field.order, enabled: false},
    {field: Field.orderType, enabled: false},
    {field: Field.scaleNet, enabled: false},
    {field: Field.bundles, enabled: false},
    {field: Field.storageLocation, enabled: false},
    {field: Field.inboundBatchId, enabled: false},
    {field: Field.outboundBatchId, enabled: false},
    {field: Field.weightDiff, enabled: false},
    {field: Field.weightDiffPercent, enabled: false},
    {field: Field.hasExceptions, enabled: false},
    {field: Field.submittedAtDate, enabled: false},
    {field: Field.documents, enabled: false},
    {field: Field.quotaMonth, enabled: false},
    {field: Field.tradeId, enabled: false},
    {field: Field.incoterm, enabled: false},
    {field: Field.updatedAt, enabled: false},
    {field: Field.createdAt, enabled: false},
]

export type Product = NonNullable<NonNullable<ShipmentsListGetProductsQuery$data['allProducts']>['edges'][0]>['node']

export const StatusMappings = {
    ARRIVED: 'Delivered',
}
