import React from 'react'

import Container from '@waybridge/wui/Container'
import Stack from '@waybridge/wui/Stack'
import Typography from '@waybridge/wui/Typography'

export type FullPageMessageProps = {
    title?: React.ReactNode
    children?: React.ReactNode
    actions?: React.ReactNode
}
export const FullPageMessage = ({children, title, actions}: FullPageMessageProps) => (
    <Container maxWidth="lg" sx={{mt: 3, mb: 12}}>
        <Stack alignItems="center" direction="column" mt={38}>
            <Stack alignItems="center" gap={3} maxWidth={420}>
                <Typography fontWeight="bold" variant="h3">
                    {title}
                </Typography>
                <Typography align="center">{children}</Typography>
                {/* eslint-disable-next-line react/jsx-no-leaked-render -- FIXME */}
                {actions && <div>{actions}</div>}
            </Stack>
        </Stack>
    </Container>
)
