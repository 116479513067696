import MUIBox from '@mui/material/Box'

export type {BoxProps} from '@mui/material/Box'

/**
 * The Box component serves as a wrapper component for most of the CSS utility needs.
 */
export const Box = MUIBox

export default Box
