import MUITableHead, {TableHeadProps as MUITableHeadProps} from '@mui/material/TableHead'
import cn from 'classnames'

export type TableHeadProps = MUITableHeadProps

/**
 * ...
 */
export const TableHead = ({className, ...props}: TableHeadProps) => (
    <MUITableHead className={cn('TableHead', className)} {...props} />
)

export default TableHead
