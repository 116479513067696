import {useMemo} from 'react'
import {useAtomValue, useSetAtom} from 'jotai'

import {atomWithSettings} from '@/utils/settings'

import {QuantityUnit} from '@/__generated__/types'

export enum WeightQuantityUnit {
    Lb = QuantityUnit.Lb,
    Mt = QuantityUnit.Mt,
    Original = 'Original',
}

const weightSettings = atomWithSettings('weight', {
    weight: QuantityUnit.Mt,
    useOriginal: false,
})

function isValidWeight(value: string): value is QuantityUnit {
    return Object.values(QuantityUnit).includes(value as QuantityUnit)
}

export function useQuantityUnit() {
    const {weight: quantityUnit} = useAtomValue(weightSettings)

    return quantityUnit
}

export function useQuantityUnitWithOriginalOption() {
    const {weight, useOriginal} = useAtomValue(weightSettings)

    return useMemo(() => {
        if (useOriginal) {
            return WeightQuantityUnit.Original
        }

        switch (weight) {
            case QuantityUnit.Lb:
                return WeightQuantityUnit.Lb
            case QuantityUnit.Mt:
                return WeightQuantityUnit.Mt
        }

        throw new Error('Unexpected weight unit provided')
    }, [weight, useOriginal])
}

/**
 * Use the {wui/WeightSelector/WeightSelector} component when possible to set the current weight setting, use the set
 * method only for cases where said component does not make sense to use
 */
export function useQuantityUnitEditable({allowedMeasurements}: {allowedMeasurements: WeightQuantityUnit[]}) {
    const quantityUnitWithOriginal = useQuantityUnitWithOriginalOption()
    const quantityUnit = useQuantityUnit()
    const setWeight = useSetAtom(weightSettings)

    function setQuantityUnit(weight: string | QuantityUnit) {
        if (!allowedMeasurements.includes(weight as WeightQuantityUnit)) {
            throw new Error(
                `Tried to set a weight quantity that is not allowed, the allowed measurements for this input are: ${allowedMeasurements.toString()}`,
            )
        }

        if (weight === WeightQuantityUnit.Original) {
            setWeight((state) => ({...state, useOriginal: true}))
            return
        }
        if (!isValidWeight(weight)) {
            throw new Error('Input value must belong to QuantityUnit enum')
        }

        setWeight({
            useOriginal: !allowedMeasurements.includes(WeightQuantityUnit.Original),
            weight: weight,
        })
    }

    return {
        quantityUnit: allowedMeasurements.includes(WeightQuantityUnit.Original)
            ? quantityUnitWithOriginal
            : quantityUnit,
        setQuantityUnit,
    }
}
