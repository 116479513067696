import {forwardRef} from 'react'
import MUIRadioGroup, {RadioGroupProps as MUIRadioGroupProps} from '@mui/material/RadioGroup'
import cn from 'classnames'

export type RadioGroupProps = MUIRadioGroupProps

/**
 * Radio buttons allow the user to select one option from a set.
 */
// eslint-disable-next-line react/display-name -- FIXME
export const RadioGroup = forwardRef(({className, ...props}: RadioGroupProps, ref) => (
    <MUIRadioGroup className={cn('RadioGroup', className)} ref={ref} {...props} />
))

export default RadioGroup
