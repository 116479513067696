import {MessageDescriptor, useIntl} from 'react-intl'

// Can't use FormattedMessage directly because we want to use the messages file
// so that the translations are consistent with our data export column labels
const IntlFormatMessage = ({msg}: {msg: MessageDescriptor}) => {
    const intl = useIntl()
    return <>{intl.formatMessage(msg)}</>
}

export default IntlFormatMessage
