import cn from 'classnames'

import {SVGProps} from './props'

export const Trash = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-trash', className)}
        fill={fill}
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path d="M13.474 4.21v10.106c0 .928-.755 1.684-1.684 1.684H3.37a1.687 1.687 0 01-1.685-1.684V4.21h11.79zM6.737 7.58H5.053v5.052h1.684V7.58zm3.369 0H8.42v5.052h1.685V7.58zm0-7.58v1.683h5.053v1.685H0V1.683h5.053V0h5.053z" />
    </svg>
)
