import {forwardRef} from 'react'
import MUIDialogContent, {DialogContentProps as MUIDialogContentProps} from '@mui/material/DialogContent'
import cn from 'classnames'

export type DialogContentProps = MUIDialogContentProps

/**
 * ...
 */
// eslint-disable-next-line react/display-name -- FIXME
export const DialogContent = forwardRef(({className, ...props}: DialogContentProps, ref) => (
    <MUIDialogContent className={cn('DialogContent', className)} ref={ref} {...props} />
))

export default DialogContent
