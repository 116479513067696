export const configValue = (name: string, defaultValue?: string): string | undefined => {
    if ('__env__' in window && name in window.__env__) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access -- FIXME
        return window.__env__[name] || defaultValue
    }
    return process.env[name] || defaultValue
}

export const configValueBool = (name: string, defaultValue: boolean = false): boolean => {
    const val = configValue(name)
    if (val === undefined || val === null) {
        return defaultValue
    }

    if (typeof val === 'boolean') {
        return val
    }

    if (typeof val === 'string') {
        const lval = val.toLowerCase()
        // eslint-disable-next-line eqeqeq -- FIXME
        if (lval == 'true' || lval == '1') {
            return true
            // eslint-disable-next-line eqeqeq -- FIXME
        } else if (lval == 'false' || lval == '0') {
            return false
        }
    }

    return defaultValue
}

export const configValueNumber = (name: string, defaultValue: number): number => {
    const val = configValue(name)

    const num = Number(val)
    if (isNaN(num)) {
        return defaultValue
    }

    return num
}

export default configValue
