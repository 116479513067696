import {ComponentsOverrides} from '@mui/material/styles'

export const MuiDialogContent = {
    styleOverrides: {
        root: {
            overflow: 'auto',
        },
    } as ComponentsOverrides['MuiDialogContent'],
}

export default MuiDialogContent
