import {graphql} from 'react-relay/hooks'

export const userAvatarFragment = graphql`
    fragment UserAvatar_user on User {
        id
        firstName
        lastName
        photo
    }
`
