import {lazy} from 'react'
import {FormattedMessage} from 'react-intl'

import TablePageSuspense from '@waybridge/wui/TablePageSuspense/TablePageSuspense'

import {WeightQuantityUnit} from '@/hooks/useQuantityUnit'

import {allTradesColumnTitles} from './labels'
import {defaultColumnOrder} from './types'

const InventoryManager = lazy(() => import('@/pages/InventoryManager'))

export const InventoryManagerSuspenseProps = {
    buttons: [
        {label: <FormattedMessage defaultMessage="Export" />},
        {label: <FormattedMessage defaultMessage="Add account" />},
    ],
    defaultColumnOrder: defaultColumnOrder,
    headerLabels: allTradesColumnTitles,
    title: {label: 'Trade Manager'},
    weightUnits: [WeightQuantityUnit.Lb, WeightQuantityUnit.Mt],
}

export const LazyInventoryManager = () => (
    <TablePageSuspense {...InventoryManagerSuspenseProps}>
        <InventoryManager />
    </TablePageSuspense>
)

export default LazyInventoryManager
