import cn from 'classnames'

import {SVGProps} from './props'

export const UploadSquare = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-upload-square', className)}
        fill={fill}
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        {/* eslint-disable-next-line react/self-closing-comp -- FIXME */}
        <path d="M1.77777778,13.3333333 L1.77777778,14.2222222 L2.66666667,14.2222222 L2.66666667,16 L1.77777778,16 C0.797333333,16 0,15.2017778 0,14.2222222 L0,14.2222222 L0,13.3333333 L1.77777778,13.3333333 Z M7.11111111,14.2222222 L7.11111111,16 L4.44444444,16 L4.44444444,14.2222222 L7.11111111,14.2222222 Z M11.5555556,14.2222222 L11.5555556,16 L8.88888889,16 L8.88888889,14.2222222 L11.5555556,14.2222222 Z M16,13.3333333 L16,14.2222222 C16,15.2017778 15.2035556,16 14.2222222,16 L14.2222222,16 L13.3333333,16 L13.3333333,14.2222222 L14.2222222,14.2222222 L14.2222222,13.3333333 L16,13.3333333 Z M8,3.18755556 L12.184,7.37155556 L10.9271111,8.62844444 L8.88888889,6.59022222 L8.88888889,12.4444444 L7.11111111,12.4444444 L7.11111111,6.59022222 L5.07288889,8.62844444 L3.816,7.37155556 L8,3.18755556 Z M1.77777778,8.88888889 L1.77777778,11.5555556 L0,11.5555556 L0,8.88888889 L1.77777778,8.88888889 Z M16,8.88888889 L16,11.5555556 L14.2222222,11.5555556 L14.2222222,8.88888889 L16,8.88888889 Z M1.77777778,4.44444444 L1.77777778,7.11111111 L0,7.11111111 L0,4.44444444 L1.77777778,4.44444444 Z M16,4.44444444 L16,7.11111111 L14.2222222,7.11111111 L14.2222222,4.44444444 L16,4.44444444 Z M2.66666667,0 L2.66666667,1.77777778 L1.77777778,1.77777778 L1.77777778,2.66666667 L0,2.66666667 L0,1.77777778 C0,0.796444444 0.797333333,0 1.77777778,0 L1.77777778,0 L2.66666667,0 Z M14.2222222,0 C15.2035556,0 16,0.796444444 16,1.77777778 L16,1.77777778 L16,2.66666667 L14.2222222,2.66666667 L14.2222222,1.77777778 L13.3333333,1.77777778 L13.3333333,0 Z M7.11111111,0 L7.11111111,1.77777778 L4.44444444,1.77777778 L4.44444444,0 L7.11111111,0 Z M11.5555556,0 L11.5555556,1.77777778 L8.88888889,1.77777778 L8.88888889,0 L11.5555556,0 Z"></path>
    </svg>
)
