import {Components} from '@mui/material'
import {ComponentsOverrides} from '@mui/material/styles'

export const MuiMenu: Components['MuiMenu'] = {
    defaultProps: {
        PaperProps: {
            square: true,
            elevation: 12,
        },
    },
    styleOverrides: {} as ComponentsOverrides['MuiMenu'],
}

export default MuiMenu
