import {lazy} from 'react'
import {FormattedMessage} from 'react-intl'

import TablePageSuspense from '@waybridge/wui/TablePageSuspense/TablePageSuspense'

import {WeightQuantityUnit} from '@/hooks/useQuantityUnit'

import {allTradesColumnTitles} from './messages'
import {allTradesDefaultColumnOrder} from './types'

const Trades = lazy(() => import('.'))

export const TradesSuspenseProps = {
    buttons: [
        {label: <FormattedMessage defaultMessage="Export" />},
        {label: <FormattedMessage defaultMessage="Create trade" />},
    ],
    defaultColumnOrder: allTradesDefaultColumnOrder,
    headerLabels: allTradesColumnTitles,
    quickFilters: [
        {label: <FormattedMessage defaultMessage="remaining declarations" />},
        {label: <FormattedMessage defaultMessage="Open Orders" />},
        {label: <FormattedMessage defaultMessage="missing schedules" />},
    ],
    title: {label: <FormattedMessage defaultMessage="Trade Manager" />},
    weightUnits: [WeightQuantityUnit.Lb, WeightQuantityUnit.Mt, WeightQuantityUnit.Original],
}

export const LazyTrades = () => (
    <TablePageSuspense {...TradesSuspenseProps}>
        <Trades />
    </TablePageSuspense>
)

export default LazyTrades
