import cn from 'classnames'

import {SVGProps} from './props'

export const Lock = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-lock', className)}
        fill={fill}
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.6 7.2V4c0-2.206-1.795-4-4-4-2.206 0-4 1.794-4 4v3.2c-.882 0-1.6.717-1.6 1.6v5.6c0 .882.718 1.6 1.6 1.6h8c.882 0 1.6-.718 1.6-1.6V8.8c0-.883-.718-1.6-1.6-1.6zm-4 5.6a1.2 1.2 0 110-2.4 1.2 1.2 0 010 2.4zM10 7.2H5.2V4c0-1.323 1.077-2.4 2.4-2.4S10 2.677 10 4v3.2z"
            fillRule="evenodd"
        />
    </svg>
)
