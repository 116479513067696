import MUITableRow, {TableRowProps as MUITableRowProps} from '@mui/material/TableRow'
import cn from 'classnames'

export type TableRowProps = MUITableRowProps

/**
 * TableRows ...
 */
export const TableRow = ({className, ...props}: TableRowProps) => (
    <MUITableRow className={cn('TableRow', className)} {...props} />
)

export default TableRow
