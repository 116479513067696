import {ReactElement} from 'react'

import IntlFormatMessage from '@/components/IntlFormatMessage'

export const definedMessagesToHeaderLabels = (columnTitles: {[index: string]: {defaultMessage: string}}) =>
    Object.keys(columnTitles).reduce(
        (prev, next) => {
            if (!(next in columnTitles)) {
                return prev
            }

            const key = next as keyof typeof columnTitles
            return {
                ...prev,
                [next]: <IntlFormatMessage msg={columnTitles[key]} />,
            }
        },

        {} as {[index in keyof typeof columnTitles]: ReactElement},
    )

export default definedMessagesToHeaderLabels
