import MUIChip, {ChipProps as MUIChipProps} from '@mui/material/Chip'
import cn from 'classnames'

export type ChipProps = MUIChipProps

/**
 * Chipes toggle the state of a single setting on or off.
 */
export const Chip = ({className, ...props}: ChipProps) => <MUIChip className={cn('Chip', className)} {...props} />

export default Chip
