import {Components} from '@mui/material'
import {ComponentsOverrides} from '@mui/material/styles'

export const MuiList: Components['MuiList'] = {
    styleOverrides: {
        root: {
            padding: 0,
        },
    } as ComponentsOverrides['MuiList'],
}

export default MuiList
