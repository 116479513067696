//-------------------------------------
// THIS FILE IS AUTOMATICALLY GENERATED
// Please, do not add types here manually
// Instead: update the scripts/colors.sh file
// RUN `npm run colors`
//-------------------------------------

export const blue10 = '#d2dbfd'
export const blue20 = '#a5b7fb'
export const blue30 = '#7792fa'
export const blue40 = '#4a6ef8'
export const blue50 = '#1d4af6'
export const blue60 = '#5262BE'
export const blue70 = '#112c94'
export const blue80 = '#0c1e62'
export const blue90 = '#060f31'
export const green10 = '#d7f5e7'
export const green20 = '#afebd0'
export const green30 = '#88e1b8'
export const green40 = '#60d7a1'
export const green50 = '#38cd89'
export const green60 = '#2da46e'
export const green70 = '#227b52'
export const green80 = '#165237'
export const green90 = '#0b291b'
export const red10 = '#f9d4d7'
export const red20 = '#f2a9ae'
export const red30 = '#ec7f86'
export const red40 = '#e5545d'
export const red50 = '#df2935'
export const red60 = '#b2212a'
export const red70 = '#a62f2f'
export const red80 = '#591015'
export const red90 = '#2d080b'
export const yellow5 = '#fffaeb'
export const yellow10 = '#fcefc7'
export const yellow20 = '#f8e3a3'
export const yellow30 = '#f9da8b'
export const yellow40 = '#f7d070'
export const yellow50 = '#e9b949'
export const yellow60 = '#c99a2e'
export const yellow70 = '#a27c1a'
export const yellow80 = '#7c5e10'
export const yellow90 = '#513c06'
export const orange50 = '#f97316'
export const purple10 = '#e7dbfc'
export const purple20 = '#cfb8f8'
export const purple30 = '#b794f5'
export const purple40 = '#9f70f1'
export const purple50 = '#874cee'
export const purple60 = '#6c3dbe'
export const purple70 = '#512e8f'
export const purple80 = '#361f5f'
export const purple90 = '#1b0f30'
export const grey10 = '#f8f8f8'
export const grey20 = '#f5f5f5'
export const grey30 = '#eeeeee'
export const grey40 = '#d9d9d9'
export const grey50 = '#b0b0b0'
export const grey60 = '#808080'
export const grey70 = '#5b5959'
export const grey80 = '#29292b'
export const grey90 = '#121212'
export const black = '#000000'
export const white = '#ffffff'
export const cyan = '#06b6d4'

export interface DesignColors {
    blue10: string
    blue20: string
    blue30: string
    blue40: string
    blue50: string
    blue60: string
    blue70: string
    blue80: string
    blue90: string
    green10: string
    green20: string
    green30: string
    green40: string
    green50: string
    green60: string
    green70: string
    green80: string
    green90: string
    red10: string
    red20: string
    red30: string
    red40: string
    red50: string
    red60: string
    red70: string
    red80: string
    red90: string
    yellow5: string
    yellow10: string
    yellow20: string
    yellow30: string
    yellow40: string
    yellow50: string
    yellow60: string
    yellow70: string
    yellow80: string
    yellow90: string
    orange50: string
    purple10: string
    purple20: string
    purple30: string
    purple40: string
    purple50: string
    purple60: string
    purple70: string
    purple80: string
    purple90: string
    grey10: string
    grey20: string
    grey30: string
    grey40: string
    grey50: string
    grey60: string
    grey70: string
    grey80: string
    grey90: string
    black: string
    white: string
    cyan: string
}

export type Color = keyof DesignColors

//-------------------------------------
// Are you adding a new type manually?
// Please, modify scripts/colors.sh instead
//-------------------------------------
