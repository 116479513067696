import {styled} from '@waybridge/wui'

const TopBar = styled('div')(({theme}) => ({
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar + 1,
    '@media print': {
        display: 'none',
    },
}))

export default TopBar
