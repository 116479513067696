import {forwardRef} from 'react'

import {NavLink, NavLinkProps} from '@waybridge/wui/RouterLink'

import {NavItemType} from './types'

type NavItemProps = NavLinkProps & {
    item: NavItemType
}

// eslint-disable-next-line react/display-name -- FIXME
const NavItem = forwardRef<HTMLAnchorElement, NavItemProps>(({item, ...props}: NavItemProps, ref) => (
    <NavLink {...props} ref={ref} role="tab" to={item.to} variant="header">
        {item.label}
    </NavLink>
))

export default NavItem
