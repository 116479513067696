import {inputBaseClasses} from '@mui/material/InputBase'
import {ComponentsOverrides, Theme} from '@mui/material/styles'

export const MuiTextField = {
    styleOverrides: {
        root: ({theme}) => ({
            '& .MuiOutlinedInput-root': {
                backgroundColor: theme.palette.white,
            },
            '& .MuiInputBase-root.Mui-disabled': {
                backgroundColor: 'transparent',
                '& .MuiInputAdornment-root': {
                    color: theme.palette.action.disabled,
                },
            },
            // need the specific class to change the padding between the icon and the edge of the input.
            [`& .${inputBaseClasses.adornedStart}`]: {
                paddingLeft: theme.spacing(1.5),
                '.MuiIconButton-root': {
                    // Remove spacing between IconButton and the edge
                    marginLeft: theme.spacing(-1.5),
                },
            },
            [`& .${inputBaseClasses.adornedEnd}`]: {
                paddingRight: theme.spacing(1.5),
                '.MuiIconButton-root': {
                    // Remove spacing between IconButton and the edge
                    marginRight: theme.spacing(-1.5),
                },
            },
        }),
    } as ComponentsOverrides<Theme>['MuiTextField'],
}

export default MuiTextField
