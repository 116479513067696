import {MouseEvent, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {Link as RouterLink} from 'react-router-dom'

import Divider from '@waybridge/wui/Divider'
import IconButton from '@waybridge/wui/IconButton'
import Link from '@waybridge/wui/Link'
import ListItem from '@waybridge/wui/ListItem'
import ListItemText from '@waybridge/wui/ListItemText'
import Menu from '@waybridge/wui/Menu'
import MenuItem from '@waybridge/wui/MenuItem'
import UserAvatar from '@waybridge/wui/UserAvatar'

import {PermissionAnyLocation} from '@/components/Permission'
import * as paths from '@/pages/routes/paths'
import {useAuth} from '@/providers/AuthProvider'
import {PermissionRoute} from '@/providers/PermissionAndNavProvider'

import {Nav_data$data} from '@/__generated__/Nav_data.graphql'

import msgs from './messages'
import SwitchOrgDialog from './SwitchOrgDialog'

type UserMenuProps = {
    data: Nav_data$data
}

type User = Nav_data$data['user']
function getUserName(user: User) {
    if (user?.firstName && user?.lastName) {
        return `${user?.firstName} ${user?.lastName}`
    }
    if (user?.firstName) {
        return user?.firstName
    }
    if (user?.email) {
        return user?.email
    }

    return 'Unknown'
}

const UserMenu = ({data}: UserMenuProps) => {
    const intl = useIntl()
    const auth = useAuth()

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleClearOrg = () => {
        auth.clearEffectiveOrganization()
        window.location.reload()
    }

    const handleOpenModal = () => {
        handleClose()
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    const {user, isSuperuser, isStaff} = data
    const name = getUserName(user)
    const orgPk = user?.organization?.pk
    const canOrgSwitch = isSuperuser || isStaff
    // eslint-disable-next-line no-implicit-coercion -- FIXME
    const isSwitched = !!auth.effectiveOrganization

    return (
        <>
            <IconButton aria-label={intl.formatMessage(msgs.OpenUserMenu)} onClick={handleClick} variant="white">
                <UserAvatar size="large" user={user} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                onClose={handleClose}
                open={open}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}>
                <ListItem>
                    <ListItemText secondary={user?.organization?.name}>{name}</ListItemText>
                </ListItem>
                {/* eslint-disable-next-line react/jsx-no-leaked-render -- FIXME */}
                {canOrgSwitch && (
                    <MenuItem onClick={handleOpenModal}>
                        <ListItemText>
                            <FormattedMessage defaultMessage="Switch Orgs" />
                        </ListItemText>
                    </MenuItem>
                )}
                {/* eslint-disable-next-line react/jsx-no-leaked-render -- FIXME */}
                {canOrgSwitch && isSwitched && (
                    <MenuItem onClick={handleClearOrg}>
                        <ListItemText>
                            <FormattedMessage defaultMessage="Clear Effective Org" />
                        </ListItemText>
                    </MenuItem>
                )}
                <Divider color="grey40" />
                <MenuItem component={RouterLink} to={`/${paths.profilePath}`}>
                    <ListItemText>
                        <FormattedMessage defaultMessage="My Profile Settings" />
                    </ListItemText>
                </MenuItem>
                <PermissionAnyLocation route={PermissionRoute.Admin}>
                    <MenuItem component={RouterLink} to={`/${paths.orgSettingsPath}`}>
                        <ListItemText>
                            <FormattedMessage defaultMessage="Organization Settings" />
                        </ListItemText>
                    </MenuItem>
                </PermissionAnyLocation>
                <Divider color="grey40" />
                <MenuItem component={Link} href="https://docs.minehub.app" rel="noreferrer" target="_blank">
                    <ListItemText>
                        <FormattedMessage defaultMessage="Help" />
                    </ListItemText>
                </MenuItem>
                <Divider color="grey40" />
                <MenuItem onClick={auth.logout}>
                    <ListItemText>
                        <FormattedMessage defaultMessage="Logout" />
                    </ListItemText>
                </MenuItem>
            </Menu>
            {/* eslint-disable-next-line react/jsx-no-leaked-render -- FIXME */}
            {isModalOpen && <SwitchOrgDialog onClose={handleCloseModal} orgPk={orgPk} />}
        </>
    )
}

export default UserMenu
