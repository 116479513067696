import cn from 'classnames'
import {FormattedMessage} from 'react-intl'

import {Tooltip} from '@/components/Tooltip'

import './style.less'

type PartySellerProps = {
    selected?: boolean
    showTooltip?: boolean
    className?: string
}

export const PartySeller = ({selected = true, showTooltip = true, className}: PartySellerProps) => (
    <Tooltip
        content={<FormattedMessage defaultMessage="Seller's option" />}
        disabled={!showTooltip}
        innerDivAriaLabel="party-seller-icon"
        innerDivClassName={cn('party', 'seller', className, {selected, 'tooltip-trigger': showTooltip})}>
        {/* eslint-disable-next-line react/jsx-no-useless-fragment -- FIXME */}
        <>S</>
    </Tooltip>
)
