import {lazy} from 'react'
import {FormattedMessage} from 'react-intl'

import TablePageSuspense from '@waybridge/wui/TablePageSuspense/TablePageSuspense'

import {WeightQuantityUnit} from '@/hooks/useQuantityUnit'

import {tradeManagerHeaderLabels} from './TradeManagerV2/LoaderV2/headerLabels'
import {tradeManagerDefaultColumnOrder} from './TradeManagerV2/types'

const TradeManager = lazy(() => import('@/pages/TradeManager'))

export const TradeManagerSuspenseProps = {
    buttons: [
        {label: <FormattedMessage defaultMessage="Export" />},
        {label: <FormattedMessage defaultMessage="Create trade" />},
    ],
    defaultColumnOrder: tradeManagerDefaultColumnOrder,
    headerLabels: tradeManagerHeaderLabels,
    quickFilters: [
        {label: <FormattedMessage defaultMessage="remaining declarations" />},
        {label: <FormattedMessage defaultMessage="Open Orders" />},
        {label: <FormattedMessage defaultMessage="missing schedules" />},
    ],
    search: {},
    title: {label: 'Trade Manager'},
    weightUnits: [WeightQuantityUnit.Lb, WeightQuantityUnit.Mt],
}

export const LazyTradeManager = () => (
    <TablePageSuspense {...TradeManagerSuspenseProps}>
        <TradeManager />
    </TablePageSuspense>
)

export default LazyTradeManager
