import {FormattedMessage} from 'react-intl'

import {Field} from './types'

export const allTradesColumnTitles = {
    [Field.counterparty]: <FormattedMessage defaultMessage="Counterparty" />,
    [Field.pk]: <FormattedMessage defaultMessage="ID" />,
    [Field.tradeStatus]: <FormattedMessage defaultMessage="Status" />,
    [Field.tradeType]: <FormattedMessage defaultMessage="Buy/Sell" />,
    [Field.tradeFulfillmentType]: <FormattedMessage defaultMessage="Trade type" />,
    [Field.deliveryPeriod]: <FormattedMessage defaultMessage="Trade Period" />,
    [Field.material]: <FormattedMessage defaultMessage="Material" />,
    [Field.specification]: <FormattedMessage defaultMessage="Specification" />,
    [Field.destination]: <FormattedMessage defaultMessage="Destination" />,
    [Field.quantity]: <FormattedMessage defaultMessage="Quantity" />,
    [Field.region]: <FormattedMessage defaultMessage="Region" />,
    [Field.actions]: '',
}
