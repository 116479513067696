import {ForwardedRef, forwardRef} from 'react'
import MUIAlert, {AlertColor, AlertProps as MUIAlertProps} from '@mui/material/Alert'
import cn from 'classnames'

import {CancelIcon, CheckCircleOutlineIcon, WarningIcon} from '@waybridge/wui/Icons/'

export type AlertProps = Omit<MUIAlertProps, 'elevation'> & {
    showIcon?: boolean
}

const iconMapping: Partial<Record<AlertColor, React.ReactNode>> = {
    success: <CheckCircleOutlineIcon fontSize="large" />,
    warning: <WarningIcon fontSize="large" />,
    error: <CancelIcon fontSize="large" />,
}

/**
 * An alert displays a short, important message in a way that attracts the user's attention without interrupting the user's task.
 */
// eslint-disable-next-line react/display-name -- FIXME
export const Alert = forwardRef(
    ({className, variant = 'filled', showIcon = true, ...props}: AlertProps, ref?: ForwardedRef<HTMLDivElement>) => {
        const extraProps = {} as MUIAlertProps
        if (!showIcon) {
            extraProps.icon = false
        }

        return (
            <MUIAlert
                className={cn('Alert', className)}
                elevation={8}
                iconMapping={iconMapping}
                ref={ref}
                variant={variant}
                {...extraProps}
                {...props}
            />
        )
    },
)

export default Alert
