import {defineMessages} from 'react-intl'

import {ReceivingStatusExtended, ReceivingStatusExtension} from '@waybridge/wui/StatusPill/utils'

import {AccountStatusType} from '@/pages/InventoryManager/types'

import {
    BalanceInventoryStatus,
    ReceivingStatus,
    TradeInvoiceType,
    TradeStatusNew,
    TransferStatus as TransferStatusType,
} from '@/__generated__/types'

export const statusName = defineMessages<ReceivingStatusExtended>({
    [ReceivingStatus.Booked]: {
        defaultMessage: 'Booked',
    },
    [ReceivingStatus.Arrived]: {
        defaultMessage: 'Delivered',
    },
    [ReceivingStatus.Incomplete]: {
        defaultMessage: 'Incomplete',
    },
    [ReceivingStatus.InProcess]: {
        defaultMessage: 'In Process',
    },
    [ReceivingStatus.InTransit]: {
        defaultMessage: 'In-Transit',
    },
    [ReceivingStatus.NotCalculated]: {
        defaultMessage: 'Not Calculated',
    },
    [ReceivingStatus.PendingIdentifier]: {
        defaultMessage: 'Pending Identifier',
    },
    [ReceivingStatus.Received]: {
        defaultMessage: 'Received',
    },
    [ReceivingStatus.Rejected]: {
        defaultMessage: 'Rejected',
    },
    [ReceivingStatusExtension.Allocated]: {
        defaultMessage: 'Allocated',
    },
    [ReceivingStatusExtension.Remaining]: {
        defaultMessage: 'Remaining',
    },
    [TradeStatusNew.Closed]: {
        defaultMessage: 'Closed',
    },
    [TradeStatusNew.Confirmed]: {
        defaultMessage: 'Confirmed',
    },
    [TradeStatusNew.Draft]: {
        defaultMessage: 'Draft',
    },
    [TradeStatusNew.Live]: {
        defaultMessage: 'Live',
    },
    [TradeInvoiceType.Invoice]: {
        defaultMessage: 'Invoice',
    },
    [TradeInvoiceType.Debit]: {
        defaultMessage: 'Debit',
    },
    [TradeInvoiceType.Credit]: {
        defaultMessage: 'Credit',
    },
    [TradeInvoiceType.Payment]: {
        defaultMessage: 'Payment',
    },
    [TransferStatusType.Open]: {
        defaultMessage: 'Open',
    },
    [TransferStatusType.InProgress]: {
        defaultMessage: 'In Progress',
    },
    [TransferStatusType.Late]: {
        defaultMessage: 'Late',
    },
    [TransferStatusType.Completed]: {
        defaultMessage: 'Completed',
    },
    [BalanceInventoryStatus.ReadyForRelease]: {
        defaultMessage: 'Ready for release',
    },
    [AccountStatusType.Open]: {
        defaultMessage: 'Open',
    },
    [BalanceInventoryStatus.Released]: {
        defaultMessage: 'Released',
    },
})

export const statusTooltip = defineMessages<
    Exclude<
        ReceivingStatus | ReceivingStatusExtension,
        ReceivingStatus.NotCalculated | ReceivingStatus.PendingIdentifier
    >
>({
    [ReceivingStatus.Booked]: {
        defaultMessage: 'The shipment has been booked, but has not yet left the point of origin.',
    },
    [ReceivingStatus.Arrived]: {
        defaultMessage: 'The shipment has arrived at the destination.',
    },
    [ReceivingStatus.Incomplete]: {
        defaultMessage: 'The receiving process has encountered issues and is not fully completed.',
    },
    [ReceivingStatus.InProcess]: {
        defaultMessage: 'The receiving process has begun, but not yet been completed.',
    },
    [ReceivingStatus.InTransit]: {
        defaultMessage: 'The shipment is currently on its way to the destination.',
    },
    [ReceivingStatus.Received]: {
        defaultMessage: 'The recipient has accepted and taken possession of the shipment.',
    },
    [ReceivingStatus.Rejected]: {
        defaultMessage: 'The recipient has refused to accept the shipment.',
    },
    [ReceivingStatusExtension.Allocated]: {
        defaultMessage: 'The amount of material the seller has allocated from upstream shipments.',
    },
    [ReceivingStatusExtension.Remaining]: {
        defaultMessage: 'The amount of material not yet shipped/booked',
    },
})
