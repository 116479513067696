import {styled} from '@waybridge/wui'
import BaseContainer, {type ContainerProps} from '@waybridge/wui/Container'

export type PageLayoutProps = ContainerProps

const WrapperContainer = styled(BaseContainer)({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
})

export const Container = ({children, ...props}: PageLayoutProps) => (
    <WrapperContainer disableGutters maxWidth={false} {...props}>
        {children}
    </WrapperContainer>
)

export default Container
