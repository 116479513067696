import React from 'react'

import ThemeProvider from '@waybridge/wui/ThemeProvider'

import WindowDimensions from '@/providers/WindowDimensions'

import {getLDApp} from './launchdarkly'
import {LocaleApp} from './locale'

// eslint-disable-next-line react/function-component-definition -- FIXME
export default function Layout({
    children,
    launchdarkly: LaunchDarklyApp,
}: {
    children?: React.ReactNode
    launchdarkly: Awaited<ReturnType<typeof getLDApp>>
}) {
    return (
        <LaunchDarklyApp>
            <ThemeProvider>
                <LocaleApp>
                    <WindowDimensions>{children}</WindowDimensions>
                </LocaleApp>
            </ThemeProvider>
        </LaunchDarklyApp>
    )
}
