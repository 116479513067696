import {ElementType, ForwardedRef, forwardRef} from 'react'
import MUILink, {LinkTypeMap} from '@mui/material/Link'
import {OverrideProps} from '@mui/material/OverridableComponent'
import cn from 'classnames'

type _LinkProps<D extends ElementType = LinkTypeMap['defaultComponent'], P = Record<string, any>> = OverrideProps<
    LinkTypeMap<P, D>,
    D
>

export type LinkProps = _LinkProps

/**
 * The Link component allows you to easily customize anchor elements with your theme colors and typography styles.
 */
export const Link = forwardRef(
    (
        {className, underline = 'always', variant = 'body1', ...props}: LinkProps,
        ref: ForwardedRef<HTMLAnchorElement>,
    ) => <MUILink className={cn('Link', className)} ref={ref} underline={underline} variant={variant} {...props} />,
)
Link.displayName = 'Link'

export default Link
