import {Components, ComponentsOverrides, Theme} from '@mui/material/styles'

export const MuiTooltip = {
    defaultProps: {
        placement: 'top',
    },
    styleOverrides: {
        popper: ({theme}) => ({
            '& a': {
                color: theme.palette.white,
            },
        }),
        tooltip: ({theme}) => ({
            ...theme.typography.body2,
            backgroundColor: 'black',
            boxShadow: theme.shadows[1],
            padding: theme.spacing(1),
        }),
        arrow: {
            color: 'black',
        },
    } as ComponentsOverrides<Theme>['MuiTooltip'],
} as Components['MuiTooltip']

export default MuiTooltip
