import {ComponentsOverrides, Theme} from '@mui/material/styles'

export const MuiDialogActions = {
    styleOverrides: {
        root: ({theme}) => ({
            padding: theme.spacing(3),
        }),
    } as ComponentsOverrides<Theme>['MuiDialogActions'],
}

export default MuiDialogActions
