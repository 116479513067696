import {Components, Theme} from '@mui/material'
import {ComponentsOverrides} from '@mui/material/styles'

// Related to MuiCalendarPicker
export const MuiPickersDay: Components<Theme>['MuiPickersDay'] = {
    defaultProps: {},
    styleOverrides: {
        root: ({theme}) => ({
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            borderRadius: '0',
            '&.Mui-selected': {
                color: theme.palette.black,
                backgroundColor: theme.palette.blue10,
                '&:focus': {
                    color: theme.palette.black,
                    backgroundColor: theme.palette.blue10,
                },
                '&:hover': {
                    backgroundColor: theme.palette.grey30,
                },
            },
            '&:not(.Mui-selected)': {
                border: 'none',
            },
            '&:hover': {
                backgroundColor: theme.palette.grey30,
            },
        }),
    } as ComponentsOverrides<Theme>['MuiPickersDay'],
}

export default MuiPickersDay
