import {ColumnConstraints, ColumnState} from '@waybridge/wui/CustomizeTableColumnsDialog/types'

import {InventorySearch$data} from '@/__generated__/InventorySearch.graphql'
import {InventorySearchFiltersQuery$data} from '@/__generated__/InventorySearchFiltersQuery.graphql'

export type {InventoryBalanceExportInput} from '@/__generated__/InventorySearchDataExportQuery.graphql'
export type {InventoryBalanceInput} from '@/__generated__/InventorySearchQuery.graphql'

type InventoryBalancesSummary = NonNullable<
    NonNullable<InventorySearchFiltersQuery$data['inventoryBalances']>['summary']
>

type InventoryBalance = NonNullable<NonNullable<InventorySearch$data['inventoryBalances']>['edges'][0]['node']>

export type FilterData = {
    locations?: Readonly<InventoryBalancesSummary['locations']>
    materialOwners?: Readonly<InventoryBalancesSummary['materialOwners']>
    accountTypes: {pk: string; name: string}[]
    material?: Readonly<InventoryBalancesSummary['commodities']>
}

export interface RowData {
    bol: InventoryBalance['bolNumber']
    batchId: InventoryBalance['batchId']
    quantity: {
        balanceQuantityLb: InventoryBalance['balanceQuantityLb']
        balanceQuantityMt: InventoryBalance['balanceQuantityMt']
    }
    toPoNumbers: InventoryBalance['toPoNumbers']
    inboundId: InventoryBalance['buyerReferenceId']
    outboundId: InventoryBalance['sellerReferenceId']
    material: {
        productPk: InventoryBalance['productPk']
        productName: InventoryBalance['productName']
        commodityPk: InventoryBalance['commodityPk']
        commodityName: InventoryBalance['commodityName']
    }
    account: {
        accountPk: InventoryBalance['accountPk']
        accountType: InventoryBalance['accountType']
        accountId: InventoryBalance['accountId']
    }
    location: {
        locationPk: InventoryBalance['locationPk']
        locationName: InventoryBalance['locationName']
    }
    materialOwner: {
        materialOwnerPk: InventoryBalance['accountHolderPk']
        materialOwnerName: InventoryBalance['accountHolderName']
    }
}

export enum InventorySearchTableIdEnum {
    settings = 'settings',
    bol = 'bol',
    batchId = 'batchId',
    material = 'material',
    quantity = 'quantity',
    account = 'account',
    location = 'location',
    materialOwner = 'materialOwner',
    poNumber = 'poNumber',
    inboundId = 'inboundId',
    outboundId = 'outboundId',
}

export const defaultColumnOrder: ColumnState[] = [
    {field: InventorySearchTableIdEnum.settings, enabled: true},
    {field: InventorySearchTableIdEnum.bol, enabled: true},
    {field: InventorySearchTableIdEnum.inboundId, enabled: false},
    {field: InventorySearchTableIdEnum.outboundId, enabled: false},
    {field: InventorySearchTableIdEnum.batchId, enabled: true},
    {field: InventorySearchTableIdEnum.material, enabled: true},
    {field: InventorySearchTableIdEnum.quantity, enabled: true},
    {field: InventorySearchTableIdEnum.account, enabled: true},
    {field: InventorySearchTableIdEnum.location, enabled: true},
    {field: InventorySearchTableIdEnum.materialOwner, enabled: true},
    {field: InventorySearchTableIdEnum.poNumber, enabled: true},
]

export const columnContraints: Partial<Record<InventorySearchTableIdEnum, ColumnConstraints>> = {
    [InventorySearchTableIdEnum.bol]: {required: true, reorderable: false},
}

export interface Settings extends Partial<Record<InventorySearchTableIdEnum, string | string[] | null>> {
    columns: ColumnState[]
    sortField?: string
    search?: string[] | null
    sortDirection?: string
}
