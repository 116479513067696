import {MouseEvent, useEffect} from 'react'
import {isString, mapValues} from 'lodash-es'
import {DateTime} from 'luxon'
import {FormProvider, useForm} from 'react-hook-form'
import {FormattedMessage} from 'react-intl'

import Button from '@waybridge/wui/Button'
import Stack from '@waybridge/wui/Stack'

type FilterProps = {
    onFilter: (data: Record<string, any>, clear?: boolean) => void
    values?: Record<string, any>
    children?: React.ReactNode
}

const Filter = ({onFilter, values, children}: FilterProps) => {
    const methods = useForm({defaultValues: values, shouldUnregister: false})
    const onSubmit = (data: Record<string, any>) => {
        onFilter(data)
    }
    const {reset} = methods

    useEffect(() => {
        reset(values)
    }, [values, reset])

    const handleFilterClear = (e: MouseEvent) => {
        const newValues = mapValues({...values}, (value: unknown) => {
            if (value === true) {
                return false
            }
            if (isString(value)) {
                return ''
            }
            if (value instanceof DateTime) {
                return null
            }
            // what else? numbers, arrays?
            return value
        })
        methods.reset(newValues)
        onFilter(newValues, true)
        e.stopPropagation()
    }

    return (
        <Stack onClick={(e) => e.stopPropagation()}>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div style={{maxHeight: '300px', overflow: 'auto', padding: '16px', paddingBottom: '0'}}>
                        {children}
                    </div>
                    <Stack direction="row" gap={2} justifyContent="space-between" margin={2}>
                        <Button onClick={handleFilterClear} size="small" variant="text">
                            <FormattedMessage defaultMessage="Reset" />
                        </Button>
                        <Button size="small" type="submit" variant="contained">
                            <FormattedMessage defaultMessage="Apply" />
                        </Button>
                    </Stack>
                </form>
            </FormProvider>
        </Stack>
    )
}

export default Filter
