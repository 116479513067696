import {ForwardedRef, forwardRef} from 'react'
import MUIPopover, {PopoverProps as MUIPopoverProps} from '@mui/material/Popover'
import cn from 'classnames'

export type PopoverProps = MUIPopoverProps

/**
 * A Popover can be used to display some content on top of another.
 */
// eslint-disable-next-line react/display-name -- FIXME
export const Popover = forwardRef(({className, ...props}: PopoverProps, ref: ForwardedRef<HTMLDivElement>) => (
    <MUIPopover className={cn('Popover', className)} ref={ref} {...props} />
))

export default Popover
