import {ComponentsOverrides, Theme} from '@mui/material/styles'

export const MuiInputAdornment = {
    styleOverrides: {
        root: ({theme}) => ({
            color: theme.palette.grey70,
            marginLeft: 0,
            '.MuiSvgIcon-root': {
                fontSize: 20,
            },
        }),
        positionEnd: {
            marginLeft: 4,
        },
    } as ComponentsOverrides<Theme>['MuiInputAdornment'],
}

export default MuiInputAdornment
