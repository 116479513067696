import {isNil} from 'lodash-es'
import {Helmet} from 'react-helmet'

/**
 * Adds a - Minehub to the end of a head title for consistency across screens
 */
export const getHeadTitle = (platformName: string, title?: string): string =>
    isNil(title) || title === '' ? platformName : `${title} - ${platformName}`

/**
 * Sets the current document / tab title
 *
 * Wrapper for `react-helmet`.
 */
const Title = ({
    title,
}: {
    /** sets the website head title */
    title?: string
}) => (
    <Helmet>
        <title>{getHeadTitle('MineHub', title)}</title>
    </Helmet>
)
export default Title
