import {Components, ComponentsOverrides, Theme} from '@mui/material/styles'

export const MuiAvatarGroup: Components<Theme>['MuiAvatarGroup'] = {
    styleOverrides: {
        root: ({theme}) => ({
            '& .MuiAvatar-root': {
                // this border is mostly for OrgAvatar.
                // if we are using this for users, maybe we should use a className
                // to selectively apply it.
                border: `1px solid ${theme.palette.grey40}`,
                marginLeft: theme.spacing(-1.5),
            },
        }),
    } as ComponentsOverrides<Theme>['MuiAvatarGroup'],
}

export default MuiAvatarGroup
