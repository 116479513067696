import {graphql} from 'react-relay'

export const navFragment = graphql`
    fragment Nav_data on UserAdmin {
        isSuperuser
        isStaff
        user {
            id
            pk
            firstName
            lastName
            fullName
            email
            photo
            ...UserAvatar_user
            organization {
                id
                pk
                name
                isInternal
            }
        }
    }
`
