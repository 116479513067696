import {Components, Theme} from '@mui/material'

export const MuiMonthPicker: Components<Theme>['MuiMonthPicker'] = {
    styleOverrides: {
        root: ({theme}) => ({
            margin: 0,
            padding: theme.spacing(3, 2),
            width: 'auto',
            rowGap: theme.spacing(0.5),
            '.MuiTypography-root': {
                margin: 0,
                height: 40,
                borderRadius: 0,
                '&.Mui-selected': {
                    backgroundColor: theme.palette.blue10,
                    color: theme.palette.black,
                },
            },
        }),
    },
}

export default MuiMonthPicker
