import MUIInputAdornment, {InputAdornmentProps as MUIInputAdornmentProps} from '@mui/material/InputAdornment'
import cn from 'classnames'

export type InputAdornmentProps = MUIInputAdornmentProps

/**
 * ...
 */
export const InputAdornment = ({className, ...props}: InputAdornmentProps) => (
    <MUIInputAdornment className={cn('InputAdornment', className)} {...props} />
)

export default InputAdornment
