import cn from 'classnames'

import {SVGProps} from './props'

export const Download = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-download', className)}
        fill={fill}
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path d="M14.588 5.647h-3.764V0H5.176v5.647H1.412L8 12.235l6.588-6.588zm-13.176 8.47V16h13.176v-1.882H1.412z" />
    </svg>
)
