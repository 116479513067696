import {StatusColorDark, StatusColorLight, StatusColorLightest} from '@waybridge/wui/ThemeProvider/minehub-theme/theme'

import {AccountStatusType} from '@/pages/InventoryManager/types'

import {
    BalanceInventoryStatus,
    ReceivingStatus,
    TradeInvoiceType,
    TradeStatusNew,
    TransferStatus as TransferStatusType,
} from '@/__generated__/types'

export enum ReceivingStatusExtension {
    Allocated = 'ALLOCATED',
    Remaining = 'REMAINING',
}

export type ReceivingStatusExtended =
    | BalanceInventoryStatus
    | ReceivingStatus
    | ReceivingStatusExtension
    | TradeStatusNew
    | TradeInvoiceType
    | TransferStatusType
    | AccountStatusType

export function getBackgroundColorGeneric<EnumType extends string | number | symbol>(
    colorMap: Record<EnumType, StatusColorLightest | StatusColorLight>,
    instance: EnumType | null,
): StatusColorLightest | StatusColorLight {
    if (instance && instance in colorMap) {
        return colorMap[instance]
    }
    return 'grey30'
}

export function getTextColorGeneric<EnumType extends string | number | symbol>(
    colorMap: Record<EnumType, StatusColorDark>,
    instance: EnumType | null,
): StatusColorDark {
    if (instance && instance in colorMap) {
        return colorMap[instance]
    }
    return 'grey80'
}

/**
 * Note: The API doesn't return shipments with `PendingIdentifier`
 */
export const receivingStatusBackgroundColorMap: Record<ReceivingStatusExtended, StatusColorLightest> = {
    [ReceivingStatus.Received]: 'green20',
    [ReceivingStatus.Rejected]: 'red20',
    [ReceivingStatusExtension.Allocated]: 'red20',
    [ReceivingStatusExtension.Remaining]: 'grey40',
    [ReceivingStatus.Arrived]: 'blue10',
    [ReceivingStatus.InTransit]: 'yellow20',
    [ReceivingStatus.Booked]: 'purple20',
    [ReceivingStatus.Incomplete]: 'grey40',
    [ReceivingStatus.InProcess]: 'grey40',
    [ReceivingStatus.NotCalculated]: 'grey40',
    [ReceivingStatus.PendingIdentifier]: 'grey40',
    [TradeStatusNew.Closed]: 'red20',
    [TradeStatusNew.Confirmed]: 'grey40',
    [TradeStatusNew.Draft]: 'grey40',
    [TradeStatusNew.Live]: 'blue10',
    [TradeInvoiceType.Invoice]: 'red20',
    [TradeInvoiceType.Debit]: 'red20',
    [TradeInvoiceType.Credit]: 'green20',
    [TradeInvoiceType.Payment]: 'green20',
    [TransferStatusType.Open]: 'yellow20',
    [TransferStatusType.InProgress]: 'yellow20',
    [TransferStatusType.Late]: 'red20',
    [TransferStatusType.Completed]: 'green20',
    [BalanceInventoryStatus.ReadyForRelease]: 'green20',
    [AccountStatusType.Open]: 'blue10',
    [BalanceInventoryStatus.Released]: 'green20',
}

export const receivingStatusProgressBackgroundColorMap: Record<ReceivingStatusExtended, StatusColorLight> = {
    [ReceivingStatus.Received]: 'green30',
    [ReceivingStatus.Rejected]: 'red30',
    [ReceivingStatusExtension.Allocated]: 'red30',
    [ReceivingStatusExtension.Remaining]: 'grey40',
    [ReceivingStatus.Arrived]: 'blue30',
    [ReceivingStatus.InTransit]: 'yellow30',
    [ReceivingStatus.Booked]: 'purple30',
    [ReceivingStatus.Incomplete]: 'grey30',
    [ReceivingStatus.InProcess]: 'grey30',
    [ReceivingStatus.NotCalculated]: 'grey30',
    [ReceivingStatus.PendingIdentifier]: 'grey30',
    [TradeStatusNew.Closed]: 'red30',
    [TradeStatusNew.Confirmed]: 'grey30',
    [TradeStatusNew.Draft]: 'grey30',
    [TradeStatusNew.Live]: 'blue30',
    [TradeInvoiceType.Invoice]: 'red30',
    [TradeInvoiceType.Debit]: 'red30',
    [TradeInvoiceType.Credit]: 'green30',
    [TradeInvoiceType.Payment]: 'green30',
    [TransferStatusType.Open]: 'yellow30',
    [TransferStatusType.InProgress]: 'yellow30',
    [TransferStatusType.Late]: 'red30',
    [TransferStatusType.Completed]: 'green30',
    [BalanceInventoryStatus.ReadyForRelease]: 'green30',
    [AccountStatusType.Open]: 'blue30',
    [BalanceInventoryStatus.Released]: 'green30',
}

export const receivingStatusTextColorMap: Record<ReceivingStatusExtended, StatusColorDark> = {
    [ReceivingStatus.Received]: 'green80',
    [ReceivingStatus.Rejected]: 'red80',
    [ReceivingStatus.Arrived]: 'blue80',
    [ReceivingStatus.InTransit]: 'yellow80',
    [ReceivingStatus.Booked]: 'purple80',
    [ReceivingStatus.Incomplete]: 'grey80',
    [ReceivingStatus.InProcess]: 'grey80',
    [ReceivingStatus.NotCalculated]: 'grey80',
    [ReceivingStatus.PendingIdentifier]: 'grey80',
    [ReceivingStatusExtension.Allocated]: 'grey80',
    [ReceivingStatusExtension.Remaining]: 'grey80',
    [TradeStatusNew.Closed]: 'red80',
    [TradeStatusNew.Confirmed]: 'grey80',
    [TradeStatusNew.Draft]: 'grey80',
    [TradeStatusNew.Live]: 'blue80',
    [TradeInvoiceType.Invoice]: 'red80',
    [TradeInvoiceType.Debit]: 'red80',
    [TradeInvoiceType.Credit]: 'green80',
    [TradeInvoiceType.Payment]: 'green80',
    [TransferStatusType.Open]: 'yellow80',
    [TransferStatusType.InProgress]: 'yellow80',
    [TransferStatusType.Late]: 'red80',
    [TransferStatusType.Completed]: 'green80',
    [BalanceInventoryStatus.ReadyForRelease]: 'green80',
    [AccountStatusType.Open]: 'blue80',
    [BalanceInventoryStatus.Released]: 'green80',
}

// utils for release flow
export enum ReleaseFlowDesignation {
    Selected = 'SELECTED',
    Progress = 'PROGRESS',
    Remaining = 'REMAINING',
}

export const releaseFlowDesignationColorMap: Record<ReleaseFlowDesignation, StatusColorLightest> = {
    [ReleaseFlowDesignation.Selected]: 'green20',
    [ReleaseFlowDesignation.Progress]: 'blue10',
    [ReleaseFlowDesignation.Remaining]: 'grey40',
}

export function getReceivingStatusBackgroundColor(
    status: ReceivingStatusExtended | null,
): StatusColorLightest | StatusColorLight {
    return getBackgroundColorGeneric(receivingStatusBackgroundColorMap, status)
}

export function getReceivingStatusProgressBackgroundColor(
    status: ReceivingStatusExtended | null,
): StatusColorLightest | StatusColorLight {
    return getBackgroundColorGeneric(receivingStatusProgressBackgroundColorMap, status)
}

export function getReceivingStatusTextColor(status: ReceivingStatus | null): StatusColorDark {
    return getTextColorGeneric(receivingStatusTextColorMap, status)
}

export function getReleaseFlowDesignationColor(status: ReleaseFlowDesignation): StatusColorLightest | StatusColorLight {
    return getBackgroundColorGeneric(releaseFlowDesignationColorMap, status)
}
