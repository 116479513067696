import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import {ComponentsOverrides, Theme} from '@mui/material/styles'

export const MuiSelect = {
    defaultProps: {
        IconComponent: KeyboardArrowDown,
    },
    styleOverrides: {
        root: ({theme}) => ({
            '& .MuiPopover-paper': {
                boxShadow: theme.shadows[12],
            },
        }),
        select: ({theme}) => ({
            '&.Mui-disabled': {
                WebkitTextFillColor: theme.palette.grey60,
                color: theme.palette.grey60,
            },
            '&.Mui-disabled:not(.MuiSelect-standard)': {
                backgroundColor: theme.palette.action.disabledBackground,
            },
            '&.MuiSelect-outlined.MuiInputBase-inputSizeSmall': {
                minHeight: '1rem',
            },
            '&.MuiSelect-standard.MuiInputBase-inputSizeSmall': {
                minHeight: '1rem',
            },
        }),
        icon: ({theme}) => ({
            color: theme.palette.grey70,
            transition: 'transform ease 500ms',
        }),
        iconOpen: {
            transform: 'rotate(180deg)',
        },
        paper: ({theme}: {theme: Theme}) => ({
            boxShadow: theme.shadows[12],
        }),
    } as ComponentsOverrides<Theme>['MuiSelect'],
}

export default MuiSelect
