import cn from 'classnames'

import {SVGProps} from './props'

export const Error = ({fill, height = 16, width = 16, className}: SVGProps) => (
    <svg
        className={cn('icon icon-error', className)}
        fill={fill}
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.926 13.956l-6.3-12.6c-.237-.474-1.015-.474-1.252 0l-6.3 12.6a.702.702 0 00.626 1.013h12.6a.702.702 0 00.626-1.013zM7.3 6.569h1.4v4.2H7.3v-4.2zm.7 6.475a.875.875 0 110-1.75.875.875 0 010 1.75z"
            fillRule="evenodd"
        />
    </svg>
)
