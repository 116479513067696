import {forwardRef} from 'react'
import MUISwitch, {SwitchProps as MUISwitchProps} from '@mui/material/Switch'
import cn from 'classnames'

export type SwitchProps = MUISwitchProps

//
// FYI: This component relies on an invisible checkbox to clickable.
//      We have to make sure that checkbox is 100% the size of the switch.
//

/**
 * Switches toggle the state of a single setting on or off.
 *
 */
// eslint-disable-next-line react/display-name -- FIXME
export const Switch = forwardRef(({className, ...props}: SwitchProps, ref: React.ForwardedRef<HTMLButtonElement>) => (
    <MUISwitch className={cn('Switch', className)} ref={ref} {...props} />
))

export default Switch
