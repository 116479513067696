import {FormattedMessage} from 'react-intl'

import Link from '@waybridge/wui/Link'
import Stack from '@waybridge/wui/Stack'

const FooterLinks = () => (
    <Stack alignItems="center" direction="row" gap={4}>
        <span>
            <Link color="grey70" href="https://docs.minehub.app" rel="noopener noreferrer" target="_blank">
                <FormattedMessage defaultMessage="Help" />
            </Link>
        </span>
        <span>
            <Link color="grey70" href="mailto:support@minehub.com">
                <FormattedMessage defaultMessage="Contact Us" />
            </Link>
        </span>
        <span>
            <Link
                color="grey70"
                href="https://minehub.com/waybridge-technologies-inc-privacy-policy/"
                rel="noopener noreferrer"
                target="_blank">
                <FormattedMessage defaultMessage="Privacy & Terms" />
            </Link>
        </span>
    </Stack>
)

export default FooterLinks
