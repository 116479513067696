import MUIDialogActions, {DialogActionsProps as MUIDialogActionsProps} from '@mui/material/DialogActions'
import cn from 'classnames'

export type DialogActionsProps = {border?: boolean} & MUIDialogActionsProps

/**
 * Footer section of a Dialog, used to show action buttons.
 */
export const DialogActions = ({className, ...props}: DialogActionsProps) => (
    <MUIDialogActions className={cn('DialogActions', className)} {...props} />
)

export default DialogActions
