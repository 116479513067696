import {styled} from '@waybridge/wui'

const SubNavContainer = styled('div')(({theme}) => ({
    display: 'flex',
    height: '40px',
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative',
    margin: '0',
    padding: '0 40px',
    boxShadow: theme.shadows[12],
    borderRadius: '0',
    alignItems: 'center',
    marginTop: '0',
    backgroundColor: theme.palette.grey80,
}))

export default SubNavContainer
