import cn from 'classnames'
import {FormattedMessage} from 'react-intl'

import {Tooltip} from '@/components/Tooltip'

import './style.less'

type PartyBuyerProps = {
    selected?: boolean
    className?: string
    showTooltip?: boolean
}
export const PartyBuyer = ({selected = true, showTooltip = true, className}: PartyBuyerProps) => (
    <Tooltip
        content={<FormattedMessage defaultMessage="Buyer's option" />}
        disabled={!showTooltip}
        innerDivAriaLabel="party-buyer-icon"
        innerDivClassName={cn('party', 'buyer', className, {selected, 'tooltip-trigger': showTooltip})}>
        {/* eslint-disable-next-line react/jsx-no-useless-fragment -- FIXME */}
        <>B</>
    </Tooltip>
)
