import {ForwardedRef, forwardRef} from 'react'
import {TextFieldProps as MUITextFieldProps} from '@mui/material/TextField'
import cn from 'classnames'

import {SearchIcon} from '@waybridge/wui/Icons'
import InputAdornment from '@waybridge/wui/InputAdornment'
import TextField from '@waybridge/wui/TextField'

const SearchField = forwardRef(
    ({className, InputProps, ...props}: MUITextFieldProps, ref: ForwardedRef<HTMLInputElement | HTMLDivElement>) => (
        <TextField
            {...props}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>
                ),
                ...InputProps,
            }}
            className={cn('SearchField', className)}
            ref={ref}
        />
    ),
)
SearchField.displayName = 'SearchField'

export default SearchField
