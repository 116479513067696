import MUIRadio, {RadioProps as MUIRadioProps} from '@mui/material/Radio'
import cn from 'classnames'

export type RadioProps = MUIRadioProps

/**
 * Radio buttons allow the user to select one option from a set.
 */
export const Radio = ({className, ...props}: RadioProps) => <MUIRadio className={cn('Radio', className)} {...props} />

export default Radio
