import {forwardRef} from 'react'
import {Stack as MUIStack, StackProps as MUIStackProps} from '@mui/material'
import cn from 'classnames'

export type StackProps = MUIStackProps

/**
 * The Stack component manages layout of immediate children along the vertical or horizontal axis with optional spacing and/or dividers between each child.
 */
// eslint-disable-next-line react/display-name -- FIXME
export const Stack = forwardRef(({className, ...props}: StackProps, ref: React.ForwardedRef<HTMLDivElement>) => (
    <MUIStack className={cn('Stack', className)} ref={ref} {...props} />
))

export default Stack
