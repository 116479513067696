import {Components} from '@mui/material'
import {ComponentsOverrides} from '@mui/material/styles'

export const MuiSnackbar = {
    defaultProps: {
        autoHideDuration: 30000,
    },
    styleOverrides: {
        root: {
            borderRadius: 0,

            '&.MuiSnackbar-anchorOriginBottomCenter': {
                right: 0,
            },
            '&.MuiSnackbar-anchorOriginTopCenter': {
                top: '56px',
                right: 0,
                width: '100vw',
                // there is no left, because it slides in from the left
            },
        },
    } as ComponentsOverrides['MuiSnackbar'],
} as Components['MuiSnackbar']

export default MuiSnackbar
