import {createRef, MutableRefObject, RefObject, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {SetStateAction, useAtom, WritableAtom} from 'jotai'
import {isEqual} from 'lodash-es'
import {useIntl} from 'react-intl'

import {SxProps, Theme} from '@waybridge/wui'
import CustomizeColumnButton from '@waybridge/wui/CustomizeColumnButton/CustomizeColumnButton'
import {ColumnState} from '@waybridge/wui/CustomizeTableColumnsDialog/types'
import {TableStateColumn} from '@waybridge/wui/WTable/useTableState'

export const REZISE_LINE_WIDTH = 2

const getLeftPositionBased = (
    isResizing: MutableRefObject<string | undefined>,
    columnRefs: Record<string, RefObject<HTMLDivElement>>,
    headers: TableStateColumn[],
    e: MouseEvent,
    decrement: number,
) => {
    if (!isResizing.current) {
        return undefined
    }

    const columnRef = columnRefs[isResizing.current]?.current
    if (!columnRef) {
        return undefined
    }

    const parent = columnRef.closest('th')
    if (!parent) {
        return undefined
    }

    const currentColumn = headers.find(
        (col: {field: string; minSize?: number; size?: number}) => col.field === isResizing.current,
    )

    if (!currentColumn) {
        return undefined
    }

    const eventLeft = e.pageX - decrement
    const columnLeft = parent.getBoundingClientRect().left

    let newLeft = eventLeft - columnLeft

    if (currentColumn.minSize && newLeft < currentColumn.minSize - decrement) {
        newLeft = currentColumn.minSize - decrement
    }
    if (currentColumn.size && Math.abs(currentColumn.size - newLeft) < 2) {
        return undefined
    }

    return {ref: columnRef, left: newLeft}
}

const createThrottledHandler = (handler: (e: MouseEvent) => void, wait: number) => {
    let lastCall = 0
    let timeoutId: number | null = null

    return (e: MouseEvent) => {
        const now = Date.now()

        if (timeoutId) {
            cancelAnimationFrame(timeoutId)
            timeoutId = null
        }

        if (now - lastCall >= wait) {
            lastCall = now
            handler(e)
        } else {
            timeoutId = requestAnimationFrame(() => {
                lastCall = Date.now()
                handler(e)
                timeoutId = null
            })
        }
    }
}

export const areColumnsCustomized = (defaultColumnOrder: ColumnState[], columns: ColumnState[]): boolean =>
    !isEqual(
        defaultColumnOrder.map(({field, enabled}) => ({field, enabled})),
        columns.map(({field, enabled}) => ({field, enabled})),
    )

/**
 * When user lands on a page with a table, show the columns.
 *
 * @param headers list of table headers
 * @param atom atom to get values from
 * @param defaultColumnOrder header default order and settings that define if the column is enabled/disabled, if it can be unchecked or reordered
 */
const useTableSettings = <T extends Record<string, any>>(
    headers: {
        field: string
        label?: React.ReactNode
        header?: React.ReactNode
        minSize?: number
        size?: number
    }[],
    atom: WritableAtom<T, SetStateAction<T>, void>,
    defaultColumnOrder: ColumnState[],
    ATOM_KEY?: string,
    settingsHeaderOptions?: {
        key: string
        sx?: SxProps<Theme>
    },
) => {
    const intl = useIntl()
    const [settings, setSettings] = useAtom(atom)
    const columns = settings.columns as ColumnState[]
    const [isShowingCustomizationModal, setIsShowingCustomizationModal] = useState(false)
    const initialSettings = JSON.parse(localStorage.getItem(ATOM_KEY || '') || '{}') as Record<
        string,
        string | string[] | ColumnState[]
    >

    const isResizing = useRef<string | undefined>(undefined)
    const columnRefs = useMemo(
        () =>
            columns.reduce(
                (acc, col) => ({...acc, [col.field]: createRef<HTMLDivElement>()}),
                {} as Record<string, RefObject<HTMLDivElement>>,
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const setCursorDocument = (isResizing: boolean) => {
        if (isResizing) {
            document.body.style.userSelect = 'none'
            document.body.style.pointerEvents = 'none'
            document.body.style.cursor = 'col-resize'
        } else {
            document.body.style.userSelect = ''
            document.body.style.cursor = 'auto'
            document.body.style.pointerEvents = 'unset'
        }
    }

    const handleOnMouseMove = (e: MouseEvent) => {
        const refAndPosition = getLeftPositionBased(isResizing, columnRefs, headers, e, REZISE_LINE_WIDTH)
        if (!refAndPosition) {
            return
        }
        const {ref, left} = refAndPosition
        ref.style.left = `${left}px`
    }

    const handleOnMouseUp = (e: MouseEvent) => {
        const refAndPosition = getLeftPositionBased(isResizing, columnRefs, headers, e, 0)
        if (!refAndPosition) {
            return
        }

        const {ref, left} = refAndPosition

        setSettings((prev) => ({
            ...prev,
            columns: ((prev.columns || defaultColumnOrder) as ColumnState[]).map((col: ColumnState) =>
                col.field === isResizing.current ? {...col, size: left} : col,
            ),
        }))

        ref.style.left = `calc(100% - ${REZISE_LINE_WIDTH * 3}px)`
        isResizing.current = undefined
        setCursorDocument(false)
    }

    const handleMouseDown = (field: string) => {
        isResizing.current = field
        setCursorDocument(true)
    }

    /**
     * This checks the localStorage settings to make sure they are up-to-date with our new defaults
     */
    useEffect(() => {
        const intialColumns: ColumnState[] = (initialSettings as {columns: ColumnState[]}).columns || columns

        //get columns from localstorage that are still being used
        const storedColumns = intialColumns.filter(({field}) =>
            defaultColumnOrder.some(({field: dField}) => dField === field),
        )

        //get new columns that might have ben added
        const newColumns = defaultColumnOrder.filter(
            (defaultCol) => !storedColumns.some(({field}) => field === defaultCol.field),
        )

        const currentColumns = [...storedColumns]

        //add new columns on the specific position or at the end
        newColumns.forEach((newCol) => {
            const index = defaultColumnOrder.findIndex(({field}) => field === newCol.field)
            if (index >= currentColumns.length) {
                currentColumns.push(newCol)
            } else {
                currentColumns.splice(index, 0, newCol)
            }
        })

        if (areColumnsCustomized(defaultColumnOrder, currentColumns) || newColumns.length) {
            setSettings((prev) => ({
                ...prev,
                columns: currentColumns,
            }))
        }

        //SET INITIAL SIZES
        const sizes: Record<string, number> = {}
        Object.entries(columnRefs).forEach(([field, {current: element}]) => {
            if (element) {
                const targetHeader = headers.find((h) => h.field === field)
                const targetColumn = (storedColumns || defaultColumnOrder).find((c) => c.field === field)

                if (!targetColumn || !targetHeader) {
                    return
                }

                const size = targetColumn.size || targetHeader.size

                if (size) {
                    sizes[field] = size
                }
            }
        })

        if (Object.entries(sizes)?.length) {
            setSettings((prev) => ({
                ...prev,
                columns: ((prev.columns || defaultColumnOrder) as ColumnState[]).map((col: ColumnState) => ({
                    ...col,
                    ...(col.field in sizes ? {size: sizes[col.field]} : {}),
                })),
            }))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /*
        Handle column resizing
    */
    useEffect(() => {
        const throttledMouseMove = createThrottledHandler(handleOnMouseMove, 12)

        const options: AddEventListenerOptions = {
            passive: true,
        }

        Object.entries(columnRefs).forEach(([field, {current: element}]) => {
            element?.addEventListener('mousedown', () => handleMouseDown(field))
        })

        document.addEventListener('mousemove', throttledMouseMove, options)
        document.addEventListener('mouseup', handleOnMouseUp, options)

        return () => {
            Object.entries(columnRefs).forEach(([field, {current: element}]) => {
                if (element) {
                    element.removeEventListener('mousedown', () => handleMouseDown(field))
                }
            })
            document.removeEventListener('mousemove', throttledMouseMove)
            document.removeEventListener('mouseup', handleOnMouseUp)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings.columns])

    const isCustomized = useMemo(() => areColumnsCustomized(defaultColumnOrder, columns), [columns, defaultColumnOrder])

    const visibleColumnsWithSettings = useMemo(() => {
        const visibleColumns = columns
            .filter(({enabled, field}) => enabled && headers.some((header) => header.field === field))
            .map(({field, size}) => ({
                ...(headers.find((header) => header.field === field) || {}),
                ...(size ? {size} : {}),
            }))
            .filter((column): column is TableStateColumn => column !== undefined)

        return [
            {
                field: settingsHeaderOptions?.key || 'settings',
                header: intl.formatMessage({defaultMessage: 'Settings'}),
                Header: () => (
                    <CustomizeColumnButton
                        isCustomized={isCustomized}
                        onClick={() => setIsShowingCustomizationModal(true)}
                        sx={settingsHeaderOptions?.sx}
                    />
                ),
            },
            ...visibleColumns,
        ]
    }, [columns, headers, isCustomized, intl, settingsHeaderOptions])

    const handleDialogSave = useCallback(
        (fields?: ColumnState[]) => {
            if (fields) {
                setSettings((prev) => ({
                    ...prev,
                    columns: [
                        {field: settingsHeaderOptions?.key || 'settings', enabled: true},
                        ...fields.map(({field, enabled, size}) => {
                            const targetColumn = ((prev.columns || []) as ColumnState[]).find((c) => c.field === field)
                            const targetSize = targetColumn?.size || size
                            return {
                                size: targetSize,
                                field,
                                enabled,
                            }
                        }),
                    ] as ColumnState[],
                }))
            }
            setIsShowingCustomizationModal(false)
        },
        [settingsHeaderOptions?.key, setSettings],
    )

    const handleClose = useCallback(() => {
        setIsShowingCustomizationModal(false)
    }, [])

    return useMemo(
        () => ({
            settings,
            setSettings,
            visibleColumnsWithSettings,
            isShowingCustomizationModal,
            handleDialogSave,
            handleClose,
            columnRefs,
            initialSettings,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [settings, setSettings, visibleColumnsWithSettings, isShowingCustomizationModal, handleDialogSave, handleClose],
    )
}

export default useTableSettings
