import {Components, ComponentsOverrides, Theme} from '@mui/material/styles'

export const MuiAvatar: Components<Theme>['MuiAvatar'] = {
    styleOverrides: {
        colorDefault: ({theme}) => ({
            color: theme.palette.secondary[700],
            backgroundColor: theme.palette.secondary[200],
            fontSize: '20px',
        }),
    } as ComponentsOverrides<Theme>['MuiAvatar'],
}

export default MuiAvatar
