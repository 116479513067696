import {ComponentsOverrides, Theme} from '@mui/material/styles'

export const MuiStepLabel = {
    styleOverrides: {
        label: ({theme}) => ({
            ...theme.typography.body1,
            '&.Mui-active': {
                fontWeight: 'normal',
            },
            '&.Mui-completed': {
                fontWeight: 'normal',
            },
        }),
    } as ComponentsOverrides<Theme>['MuiStepLabel'],
}

export default MuiStepLabel
