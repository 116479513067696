import {SvgIcon} from '@mui/material'
import cn from 'classnames'

import {ConveyanceSVGProps} from './props'

export const Conveyance = ({fill, fontSize = 'medium', className}: ConveyanceSVGProps) => (
    <SvgIcon
        className={cn('icon icon-conveyance', className)}
        fill={fill}
        fontSize={fontSize}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.667 5.333C5.69 5.336 7.33 6.976 7.333 9c0 2.374-3.031 5.949-3.16 6.1-.126.148-.311.233-.506.233s-.38-.085-.507-.233C3.03 14.948 0 11.37 0 9c.002-2.024 1.643-3.664 3.667-3.667zm7.792 4.096c.178-.166.434-.221.665-.144.231.078.402.275.444.515.043.24-.048.485-.239.637l-4.8 4.148c-.278.24-.7.21-.94-.07-.24-.278-.21-.7.069-.94zM3.667 7.333C2.747 7.333 2 8.08 2 9s.746 1.667 1.667 1.667c.92 0 1.666-.747 1.666-1.667s-.746-1.667-1.666-1.667zM13 0c1.656.002 2.998 1.344 3 3 0 1.982-2.33 4.458-2.715 4.852l-.049.05c-.063.063-.147.099-.236.099s-.173-.036-.236-.098C12.652 7.79 10 5.114 10 3c.002-1.656 1.344-2.998 3-3zm0 2c-.552 0-1 .448-1 1s.448 1 1 1c.265 0 .52-.105.707-.293C13.895 3.52 14 3.265 14 3c0-.552-.448-1-1-1z"
            fillRule="evenodd"
        />
    </SvgIcon>
)
