import {CSSProperties, Fragment} from 'react'
import {get, isEmpty} from 'lodash-es'

/**
 * Example:
 * ```
 * filterOptions={filterOptionsByMultipleFields(['name', 'pk', 'referenceNumber', 'counterparty.name'])}
 * ```
 */
export const filterOptionsByMultipleFields =
    <T extends Record<string, any>>(keys: string[] = []) =>
    (options: T[], {inputValue}: {inputValue: string}) => {
        if (isEmpty(inputValue)) {
            return options
        }

        const opts = [...options].filter((opt) => {
            for (let i = 0; i < keys.length; i++) {
                if (`${get(opt, keys[i])}`.includes(inputValue)) {
                    return true
                }
            }

            return false
        })

        return opts
    }

function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/gu, '\\$&')
}

export const splitLabelIntoTokens = (
    label: string,
    inputValue: string,
    isSelected: boolean,
    cssProps: CSSProperties = {},
) => {
    const isExactMatch = inputValue && label === inputValue
    const includesLabel = inputValue && label.toLowerCase().includes(inputValue.toLowerCase())

    const tokens =
        !isSelected && (isExactMatch || includesLabel)
            ? label.split(new RegExp(`(${escapeRegExp(inputValue)})`, 'iu'))
            : [label]

    return (
        <div
            style={{
                ...cssProps,
                ...(isSelected ? {fontWeight: 500} : {}),
            }}>
            {tokens.map((part, index) => (
                <Fragment key={index}>
                    {part.toLowerCase() === inputValue.toLowerCase() ? <b style={{fontWeight: 500}}>{part}</b> : part}
                </Fragment>
            ))}
        </div>
    )
}
