import {FormattedMessage} from 'react-intl'

import {ItemStatus} from '@waybridge/wui/ItemStatus'
import {ReceivingStatusExtension} from '@waybridge/wui/StatusPill/utils'

import {ReceivingStatus} from '@/__generated__/types'

import {Field} from '../types'

const darkHeadersStyles = {
    sx: {span: {fontWeight: 'bold', color: 'text.dark'}},
}

export const tradeManagerHeaderLabels = {
    [Field.counterparties]: <FormattedMessage defaultMessage="Counterparties" />,
    [Field.tradePk]: <FormattedMessage defaultMessage="ID" />,
    [Field.tradeName]: <FormattedMessage defaultMessage="Trade Name" />,
    [Field.tradeBuyType]: <FormattedMessage defaultMessage="Buy/Sell" />,
    [Field.tradeFulfillmentType]: <FormattedMessage defaultMessage="Trade type" />,
    [Field.regionName]: <FormattedMessage defaultMessage="Region" />,
    [Field.buyerReferenceId]: <FormattedMessage defaultMessage="Buyer Reference ID" />,
    [Field.sellerReferenceId]: <FormattedMessage defaultMessage="Seller Reference ID" />,
    [Field.schedule]: <FormattedMessage defaultMessage="Schedule" />,
    [Field.material]: <FormattedMessage defaultMessage="Material" />,
    [Field.specification]: <FormattedMessage defaultMessage="Specification" />,
    [Field.destinations]: <FormattedMessage defaultMessage="Destination" />,
    [Field.orderNumber]: <FormattedMessage defaultMessage="PO" />,
    [Field.quota]: <FormattedMessage defaultMessage="Quota" />,
    [Field.remaining]: <ItemStatus status={ReceivingStatusExtension.Remaining} type="dot" {...darkHeadersStyles} />,
    [Field.booked]: <ItemStatus status={ReceivingStatus.Booked} type="dot" {...darkHeadersStyles} />,
    [Field.inTransit]: <ItemStatus status={ReceivingStatus.InTransit} type="dot" {...darkHeadersStyles} />,
    [Field.delivered]: <ItemStatus status={ReceivingStatus.Arrived} type="dot" {...darkHeadersStyles} />,
    [Field.received]: <ItemStatus status={ReceivingStatus.Received} type="dot" {...darkHeadersStyles} />,
    [Field.progress]: <FormattedMessage defaultMessage="Progress" />,
}
